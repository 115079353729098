import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PATH_DASHBOARD } from '../../../routes/routesPaths';
import Logo from '../Logo';

import Menu from './Menu/Menu';
import WalletWithProfile from './WalletWithProfile/WalletWithProfile';

const Topbar: FC = () => (
  <Container>
    <LogoLink to={PATH_DASHBOARD}>
      <Logo />
    </LogoLink>
    <Menu />
    <WalletWithProfile />
  </Container>
);

const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.topbar.height};
  background: ${({ theme }) => theme.colorTokens.surface.background};
  padding: 0 ${({ theme }) => theme.spacing.extraLarge};
  z-index: 998;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colorTokens.surface.medium};
`;

const LogoLink = styled(Link)`
  z-index: 2;
`;

export default Topbar;
