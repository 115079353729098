import { useCallback } from 'react';

import { GuideModalIds, checkShouldShowGuideModal, updateGuideModalState } from '../util/guideModals';

type UseGuideModalReturn = {
  markAsSeen: () => void;
  markAsShouldSee: () => void;
  resetModal: () => void;
  shouldBeSeen: () => boolean;
};

const useGuideModal = (modalId: GuideModalIds): UseGuideModalReturn => {
  const markAsSeen = useCallback(() => updateGuideModalState(modalId, 'seen'), [modalId]);
  const markAsShouldSee = useCallback(() => updateGuideModalState(modalId, 'shouldSee'), [modalId]);
  const resetModal = useCallback(() => updateGuideModalState(modalId, 'unseen'), [modalId]);
  const shouldBeSeen = useCallback(() => checkShouldShowGuideModal(modalId), [modalId]);

  return { markAsSeen, markAsShouldSee, resetModal, shouldBeSeen };
};

export default useGuideModal;
