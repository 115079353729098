import { useQuery, UseQueryResult } from 'react-query';

import { getUserLevels, UserLevelsConfigDTO } from '../users';
import { QueryKeys } from '../enums';
import { queryStaleTimes, refetchIntervals } from '../reactQueryConfig';

export const useUserLevels = ({
  staleTime,
  enabled,
}: {
  staleTime?: number;
  refetchInterval?: number;
  enabled?: boolean;
}): UseQueryResult<UserLevelsConfigDTO, unknown> =>
  useQuery(QueryKeys.UserLevels, getUserLevels, {
    staleTime: staleTime ?? queryStaleTimes.thirtyMinutes,
    refetchInterval: refetchIntervals.thirtyMinutes,
    enabled: enabled ?? true,
  });
