import React, { FC } from 'react';

import Icon, { IconProps } from '..';

import { ReactComponent as IconSVG } from 'assets/images/icons/home-icon.svg';

const Home: FC<IconProps> = (props) => {
  const { color, fillColor, className, width } = props;

  return (
    <Icon color={color} fillColor={fillColor} className={className} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default Home;
