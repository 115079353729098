// eslint-disable-next-line @nx/enforce-module-boundaries
import { getAccessToken } from '../../../../common-utils/src/lib/auth';
import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { VerifyPhoneNumberRequest } from './model';

export async function verifyPhoneNumber({ smsCode }: { smsCode: string }): Promise<void> {
  const accessToken = await getAccessToken();
  await apiPost<unknown, VerifyPhoneNumberRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UsersVerifyPhoneNumber,
    { accessToken, smsCode },
    true
  );
}
