/** Color primitives from Figma file */
const primitives = {
  investownBrand: {
    25: '#F0F4FF',
    50: '#EBF1FF',
    100: '#D1DFFF',
    200: '#B2CAFF',
    300: '#6696FF',
    400: '#3F7BFF',
    500: '#1961FF',
    600: '#1551D5',
    700: '#1141AA',
    800: '#1E3057',
    900: '#0C1936',
    950: '#04102A',
  },
  investownGreen: {
    25: '#ECFAF5',
    50: '#DFF7EE',
    100: '#C0EFDD',
    200: '#A0E7CC',
    300: '#80DEBB',
    400: '#61D6AA',
    500: '#41CE99',
    600: '#36AC80',
    700: '#2B8966',
    800: '#21674D',
    900: '#164533',
    950: '#0B2219',
  },
  grayModern: {
    25: '#FCFCFD',
    50: '#F8FAFC',
    100: '#F2F5F7',
    200: '#E3E8EF',
    300: '#CDD5DF',
    400: '#A5AEBB',
    500: '#697586',
    600: '#4B5565',
    700: '#364152',
    800: '#202939',
    900: '#121926',
    950: '#0D121C',
  },
  rose: {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31554',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E',
    950: '#510B24',
  },
  yellow: {
    25: '#FEFDF0',
    50: '#FEFBE8',
    100: '#FEF7C3',
    200: '#FEEE95',
    300: '#FDE272',
    400: '#FAC515',
    500: '#EAAA08',
    600: '#CA8504',
    700: '#A15C07',
    800: '#854A0E',
    900: '#713B12',
    950: '#542C0D',
  },
  orangeBasic: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEFCF7',
    200: '#FEDF89',
    300: '#FECB48',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
    950: '#4E1D09',
  },
  orangeDark: {
    100: '#FFE6D5',
  },
  orange: {
    700: '#B93815',
  },
  base: {
    black: '#000000',
    white: '#FFFFFF',
    blackTransparent: '#0000004d',
    whiteTransparent: '#ffffff33',
  },
  transparent: 'transparent',
  seedStarterBlue: '#2870ED',
};

/**
 * Represents named color tokens from Figma color palette
 * SHOULD point to a specific color in the primitives palette
 */
export const colorTokens = {
  text: {
    brand: primitives.investownBrand['500'],
    brandFaded: primitives.investownBrand['25'],
    brandDisabled: primitives.investownBrand['200'],
    invertedMedium: primitives.grayModern['300'],
    invertedSubtle: primitives.grayModern['400'],
    invertedStrong: primitives.base.white,
    subtle: primitives.grayModern['500'],
    medium: primitives.grayModern['600'],
    strong: primitives.investownBrand['950'],
    disabled: primitives.grayModern['400'],
    success: primitives.investownGreen['700'],
    positive: primitives.investownGreen['600'],
    warning: primitives.yellow['700'],
    error: primitives.rose['700'],
    errorDisabled: primitives.rose['200'],
    black: primitives.base.black,
    warningStrong: primitives.orangeBasic['600'],
    warningSubtle: primitives.orangeBasic['200'],
    successMedium: primitives.investownGreen['500'],
  },
  surface: {
    brand: primitives.investownBrand['500'],
    brandFaded200: primitives.investownBrand['200'],
    brandFaded100: primitives.investownBrand['100'],
    brandFaded50: primitives.investownBrand['50'],
    brandFaded25: primitives.investownBrand['25'],
    inverted800: primitives.investownBrand['800'],
    inverted900: primitives.investownBrand['900'],
    inverted950: primitives.investownBrand['950'],
    subtle: primitives.grayModern['100'],
    medium: primitives.grayModern['200'],
    strong: primitives.grayModern['300'],
    card: primitives.base.white,
    cardHover: primitives.grayModern['50'],
    background: primitives.base.white,
    transparent: primitives.transparent,
    overlayBlack: primitives.base.blackTransparent,
    overlayWhite: primitives.base.whiteTransparent,
    seedStarterBlue: primitives.seedStarterBlue,
  },
  stroke: {
    inverted: primitives.investownBrand['950'],
    white: primitives.base.white,
    subtle: primitives.grayModern['100'],
    medium: primitives.grayModern['200'],
    strong: primitives.grayModern['400'],
    focus: primitives.investownBrand['100'],
  },
  button: {
    inverted: {
      hoverAndPressed: primitives.base.white,
      default: primitives.base.white,
    },
    secondary: {
      hoverAndPressed: primitives.investownBrand['50'],
      default: primitives.investownBrand['25'],
      disabled: primitives.investownBrand['25'],
    },
    primary: {
      hoverAndPressed: primitives.investownBrand['600'],
      default: primitives.investownBrand['500'],
      disabled: primitives.investownBrand['200'],
    },
    danger: {
      hoverAndPressed: primitives.rose['100'],
      default: primitives.rose['50'],
      disabled: primitives.rose['50'],
    },
  },
  icon: {
    brand: primitives.investownBrand['500'],
    subtle: primitives.grayModern['400'],
    medium: primitives.grayModern['500'],
    strong: primitives.investownBrand['950'],
  },
  utility: {
    error: {
      subtle: primitives.rose['50'],
      medium: primitives.rose['600'],
      strong: primitives.rose['700'],
    },
    warning: {
      subtle: primitives.orangeBasic['50'],
      medium: primitives.orangeBasic['500'],
      strong: primitives.yellow['700'],
    },
    semiWarning: {
      subtle: primitives.orangeDark['100'],
      strong: primitives.orange['700'],
    },
    success: {
      subtle: primitives.investownGreen['50'],
      medium: primitives.investownGreen['500'],
      strong: primitives.investownGreen['700'],
    },
    inactive: {
      subtle: primitives.grayModern['50'],
      medium: primitives.grayModern['200'],
      strong: primitives.grayModern['400'],
    },
  },
  gradient: {
    marketing: {
      start: '#2468FF',
      end: '#0045DB',
    },
    gold: {
      start: '#FADE83',
      middle: '#F8CB38',
      end: '#D2A205',
    },
    progress: {
      start: '#2DAE7E',
      end: '#89E1C0',
    },
    seedStarter: {
      start: primitives.investownBrand['500'],
      end: primitives.seedStarterBlue,
    },
  },
  scrollbar: {
    thumb: '#777777',
  },
};

export type SurfaceColorName = keyof typeof colorTokens.surface;
export type TextColorName = keyof typeof colorTokens.text;
export type IconColorName = keyof typeof colorTokens.icon;
export type UtilityColorName = keyof typeof colorTokens.utility;
export type StrokeColorName = keyof typeof colorTokens.stroke;
