import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { FrameworkAgreementDTO, VersionPreviewDTO } from './model';

export async function getFrameworkAgreementPreview(body: FrameworkAgreementDTO): Promise<VersionPreviewDTO> {
  const response = await apiPost<VersionPreviewDTO>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.GetFrameworkAgreementPreview,
    body,
    true
  );

  if (response === undefined) {
    throw new Error('Invalid response');
  }

  return response;
}
