import { differenceInYears, subMonths } from 'date-fns';

export function isUserAdult(usersBirthDate: Date): boolean {
  return calculateAgeFromDate(usersBirthDate) >= 18;
}

export function calculateAgeFromDate(date: Date, currentDate = new Date()): number {
  return differenceInYears(currentDate, date);
}

export function getBirthDateFromBirthNumber(czechBirthNumber: string): Date {
  const year = resolveBirthYear(czechBirthNumber);
  const month = resolveBirthMonth(czechBirthNumber);
  const day = resolveBirthDayInMonth(czechBirthNumber);

  return new Date(year, month - 1, day);
}

export function resolveBirthYear(czechBirthNumber: string): number {
  const yearPart = parseInt(czechBirthNumber.substr(0, 2), 10);
  const bNSuffixLength = czechBirthNumber.length === 9 ? 3 : 4;
  const centuryBaseYear = resolveCenturyBaseYear(yearPart, bNSuffixLength);

  return centuryBaseYear + yearPart;
}

function resolveCenturyBaseYear(yearPart: number, bNSuffixLength: 3 | 4): number {
  /* 53 is boundary year within birth number.
     Together with length of birth number suffix it determines
     the century of the resolved birth date. */
  if (bNSuffixLength === 3) {
    return yearPart > 53 ? 1800 : 1900;
  }
  // birth number suffix is 4 characters long
  return yearPart > 53 ? 1900 : 2000;
}

export function resolveBirthMonth(czechBirthNumber: string): number {
  const femaleMonthIncrement = 50;
  const incrementWhenAllBnsWithinDayExhausted = 20;
  let month = parseInt(czechBirthNumber.substr(2, 2), 10);
  if (month > femaleMonthIncrement) {
    month -= femaleMonthIncrement;
  }
  if (month > incrementWhenAllBnsWithinDayExhausted) {
    month -= incrementWhenAllBnsWithinDayExhausted;
  }
  return month;
}

export function resolveBirthDayInMonth(czechBirthNumber: string): number {
  return parseInt(czechBirthNumber.substr(4, 2), 10);
}

export function dateToDateString(input: Date): string {
  return input.toISOString().split('T')[0];
}

export function getDayDurationInMs(): number {
  return 24 * 60 * 60 * 1000;
}

export function addMissingMonths<T>({
  data,
  requiredLength,
  getMonthNumber,
  getYear,
  createEmptyDataItem,
}: {
  data: T[];
  requiredLength: number;
  getMonthNumber: (item: T) => number;
  getYear: (item: T) => number;
  createEmptyDataItem: (params: { month: number; year: number }) => T;
}): T[] {
  const missingMonthsCount = requiredLength - data.length;
  const firstAvailableMonthData = data[0];
  const missingMonths = Array.from({ length: missingMonthsCount }, (_, i) => {
    const missingDate = subMonths(
      new Date(getYear(firstAvailableMonthData), getMonthNumber(firstAvailableMonthData), 1),
      missingMonthsCount - i
    );

    return createEmptyDataItem({ month: missingDate.getMonth(), year: missingDate.getFullYear() });
  });
  return [...missingMonths, ...data];
}
