import React, { FC } from 'react';
import styled from 'styled-components';

import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import SeedStarterLogo from 'components/Logo/SeedStarterLogo';
import FinbusLogo from 'components/Logo/FinbusLogo';
import DRFGLogo from 'components/Logo/DRFGLogo';

const Footer: FC = () => (
  <Container id="footer">
    <Typography variant="labelSMSemiBold" color="invertedStrong">
      <Lang id="global.footer.supportingInvestors" />
    </Typography>
    <Spacer width="100" />
    <SeedStarterLogo />
    <Spacer width="60" />
    <DRFGWrapper>
      <DRFGLogo />
    </DRFGWrapper>
    <Spacer width="60" />
    <FinbusLogo />
  </Container>
);

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.footer.height};
  background: ${({ theme }) => theme.colorTokens.surface.seedStarterBlue};
  padding: ${({ theme }) => theme.spacing.large} 0;
`;

const DRFGWrapper = styled.div`
  margin-top: 15px;
`;

export default Footer;
