/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { theme } from 'styles/theme';
import LoadingSpinner from 'components/V2/LoadingSpinner';

const buttonConfig = {
  height: {
    small: '32px',
    medium: '40px',
    large: '52px',
  },
  padding: {
    small: `${theme.spacing.medium} ${theme.spacing.regular}`,
    medium: `${theme.spacing.regular} ${theme.spacing.large}`,
    large: `${theme.spacing.large} 20px`,
  },
  fontSize: {
    small: theme.typography.buttonSMMedium.size,
    medium: theme.typography.buttonSMMedium.size,
    large: theme.typography.buttonBASEMedium.size,
  },
  fontWeight: {
    small: theme.typography.buttonSMMedium.weight,
    medium: theme.typography.buttonSMMedium.weight,
    large: theme.typography.buttonBASEMedium.weight,
  },
  lineHeight: {
    small: theme.typography.buttonSMMedium.lineHeight,
    medium: theme.typography.buttonSMMedium.lineHeight,
    large: theme.typography.buttonBASEMedium.lineHeight,
  },
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  outline?: boolean;
  color?: 'primary' | 'highlight' | 'inverted' | 'alert' | 'link' | 'errorLink';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  htmlType?: 'button' | 'submit';
  block?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  testID?: string;
}

const Button: React.FunctionComponent<Props> = ({
  children,
  htmlType,
  loading,
  className,
  outline,
  block,
  onClick,
  testID,
  color,
  size,
  disabled,
  ...rest
}) => {
  const computedProps = {
    type: htmlType || 'button',
    className: `${className || ''} ${disabled ? 'disabled' : ''} ${block ? 'ant-btn-block' : ''}`,
    outline,
    theme,
    size,
    buttonConfig,
    disabled,
    onClick,
    testID,
    ...rest,
  };

  const getButtonStyle = (buttonColor: Props['color']) => {
    const buttonContent = (
      <>
        {loading && <LoadingSpinner className="button-spinner" />}
        {children}
      </>
    );
    switch (buttonColor) {
      case 'primary':
        return (
          <PrimaryButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </PrimaryButton>
        );
      case 'highlight':
        return (
          <HighlightButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </HighlightButton>
        );
      case 'inverted':
        return (
          <InvertedButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </InvertedButton>
        );
      case 'alert':
        return (
          <AlertButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </AlertButton>
        );
      case 'link':
        return (
          <LinkButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </LinkButton>
        );
      case 'errorLink':
        return (
          <ErrorLinkButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </ErrorLinkButton>
        );
      default:
        return (
          <DefaultButton data-testid={testID} {...computedProps}>
            {buttonContent}
          </DefaultButton>
        );
    }
  };

  return getButtonStyle(color);
};

const DefaultButton = styled.button<Props>`
  white-space: pre-wrap;
  vertical-align: middle;
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return buttonConfig.padding.small;
      case 'medium':
        return buttonConfig.padding.medium;
      default:
        return buttonConfig.padding.large;
    }
  }};
  min-height: ${({ size }) => {
    switch (size) {
      case 'small':
        return buttonConfig.height.small;
      case 'medium':
        return buttonConfig.height.medium;
      default:
        return buttonConfig.height.large;
    }
  }};
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        font-size: ${buttonConfig.fontSize.small};
        font-weight: ${buttonConfig.fontSize.small};
        line-height: ${buttonConfig.fontSize.small};
        & > .button-spinner svg {
          width: 16px;
          height: 16px;
        }
        `;
      case 'medium':
        return `
        font-size: ${buttonConfig.fontSize.medium};
        font-weight: ${buttonConfig.fontSize.medium};
        line-height: ${buttonConfig.fontSize.medium};
        & > .button-spinner svg {
          width: 16px;
          height: 16px;
        }
        `;
      default:
        return `
        font-size: ${buttonConfig.fontSize.large};
        font-weight: ${buttonConfig.fontSize.large};
        line-height: ${buttonConfig.fontSize.large};
        & > .button-spinner svg {
          width: 24px;
          height: 24px;
        }
        `;
    }
  }};
  height: auto;
  transition: none;
  border: none;
  box-shadow: none;
  margin: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin: 0;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &,
  & > a {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: none;
    svg {
      margin-right: ${({ theme }) => theme.margins.medium};
    }
  }

  &:not(.ant-btn-block) {
    max-width: ${({ theme }) => theme.button.maxWidth};
  }
  border-radius: ${({ theme }) => theme.button.borderRadius};

  &:focus,
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colorTokens.button.primary.default};
    border-color: ${({ theme }) => theme.colorTokens.button.primary.default};

    span svg {
      color: ${({ theme }) => theme.colorTokens.button.primary.default};
      path{
        stroke: ${({ theme }) => theme.colorTokens.button.primary.default};
      }
    }

  &:focus,
  &.focus {
    outline: 0;
  }
`;

const PrimaryButton = styled(DefaultButton)`
  color: ${({ theme, outline }) => (outline ? theme.colorTokens.text.brand : theme.colorTokens.surface.background)};
  // 1A is giving 10% opacity to the color
  // https://caniuse.com/css-rrggbbaa
  background-color: ${({ theme, outline }) =>
    outline ? `${theme.colorTokens.button.primary.default}1A` : theme.colorTokens.button.primary.default};
  border-color: ${({ theme }) => theme.colorTokens.button.primary.default};
  border: ${({ outline }) => outline && 'none'};

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colorTokens.text.invertedStrong};
    background-color: ${theme.colorTokens.button.primary.disabled};
    border-color: ${theme.colorTokens.button.primary.disabled};
  `};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${({ theme, outline }) =>
      outline ? theme.colorTokens.button.primary.default : theme.colorTokens.surface.background};
    // 33 is giving 20% opacity to the color
    // https://caniuse.com/css-rrggbbaa
    background-color: ${({ theme, outline }) =>
      outline ? `${theme.colorTokens.button.primary.default}33` : theme.colorTokens.button.primary.hoverAndPressed};
    border-color: ${({ theme }) => theme.colorTokens.button.primary.hoverAndPressed};
    border: ${({ outline }) => outline && 'none'};

    span svg {
      fill: ${({ theme, outline }) =>
        outline ? theme.colorTokens.button.primary.default : theme.colorTokens.surface.background};
      color: ${({ theme, outline }) =>
        outline ? theme.colorTokens.button.primary.default : theme.colorTokens.surface.background};
    }
  }
  a {
    color: ${({ theme, outline }) =>
      outline ? theme.colorTokens.button.primary : theme.colorTokens.surface.background};
  }
`;

const HighlightButton = styled(DefaultButton)`
  color: ${({ theme, outline }) =>
    outline ? theme.colorTokens.button.danger.default : theme.colorTokens.surface.background};
  background-color: ${({ theme, outline }) => (outline ? 'transparent' : theme.colorTokens.button.danger.default)};
  border-color: ${({ theme }) => theme.colorTokens.button.danger.default};

  &:hover,
  &:focus {
    color: ${({ theme, outline }) =>
      outline ? theme.colorTokens.button.danger.hoverAndPressed : theme.colorTokens.button.danger.default};
    background-color: ${({ theme, outline }) =>
      outline ? 'transparent' : theme.colorTokens.button.danger.hoverAndPressed};
    border-color: ${({ theme }) => theme.colorTokens.button.danger.hoverAndPressed};
  }
  a {
    color: ${({ theme }) => theme.colorTokens.surface.background};
  }
`;

const InvertedButton = styled(DefaultButton)`
  color: ${({ theme }) => theme.colorTokens.button.primary.default};
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  border-color: ${({ theme }) => theme.colorTokens.surface.background};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colorTokens.button.primary.default};
    background-color: ${({ theme }) => theme.colorTokens.surface.background};
    border-color: ${({ theme }) => theme.colorTokens.surface.background};
  }
  a {
    color: ${({ theme }) => theme.colorTokens.button.primary.default};
  }
`;

const AlertButton = styled(DefaultButton)`
  color: ${({ theme }) => theme.colorTokens.text.error};
  background-color: ${({ theme }) => theme.colorTokens.button.danger.default};
  border-color: ${({ theme }) => theme.colorTokens.button.danger.default};

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colorTokens.text.errorDisabled};
    background-color: ${theme.colorTokens.button.danger.disabled};
    border-color: ${theme.colorTokens.button.danger.disabled};
  `};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${({ theme }) => theme.colorTokens.text.error};
    background-color: ${({ theme }) => theme.colorTokens.button.danger.hoverAndPressed};
    border-color: ${({ theme }) => theme.colorTokens.button.danger.hoverAndPressed};
  }
  a {
    color: ${({ theme }) => theme.colorTokens.surface.background};
  }
`;

const LinkButton = styled(DefaultButton)`
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.colorTokens.button.primary.default};
  background-color: transparent;
  border: none;
  box-shadow: none;

  & > .button-spinner svg {
    width: 16px;
    height: 16px;
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colorTokens.text.brandDisabled};
  `};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${({ theme }) => theme.colorTokens.button.primary.hoverAndPressed};
  }
  a {
    color: ${({ theme }) => theme.colorTokens.button.primary.default};
  }
`;

const ErrorLinkButton = styled(DefaultButton)`
  color: ${({ theme }) => theme.colorTokens.button.danger.default};
  background-color: transparent;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colorTokens.button.danger.hoverAndPressed};
  }
  a {
    color: ${({ theme }) => theme.colorTokens.button.danger.default};
  }
`;

export default Button;
