import { BasicUserInfo, getUserInfoFromCognitoUser } from '@investown/fe/common-utils';

import { AuthState } from 'appRedux/auth/reducer';

export const isUserSignedIn = (state: AuthState | { auth: AuthState }): boolean => {
  return 'auth' in state ? !!state.auth.cognitoUser : !!state.cognitoUser;
};
export const getBasicUserInfo = (state: AuthState): BasicUserInfo | undefined => {
  if (!state.cognitoUser) {
    return undefined;
  }
  const { givenName, familyName, email } = getUserInfoFromCognitoUser(state.cognitoUser);
  return {
    givenName,
    familyName,
    email,
  };
};
