import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { SecondaryMarketOfferBidBody } from './model';

export async function bidIntoSecondaryMarketOffer({
  projectId,
  transactionId,
  secondaryMarketOfferId,
  bidId,
  bidAmount,
}: SecondaryMarketOfferBidBody): Promise<void> {
  await apiPost<unknown, SecondaryMarketOfferBidBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.BidIntoSecondaryMarketOffer,
    { projectId, transactionId, secondaryMarketOfferId, bidId, bidAmount },
    true
  );
}
