import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as SvgLogo } from 'assets/images/logos/seedStarter.svg';

const SeedStarterLogo: FC<{ className?: string }> = ({ className }) => (
  <StyledLogo className={className} title="Seed Starter" />
);

const StyledLogo = styled(SvgLogo)`
  position: relative;
  width: auto;
`;

export default SeedStarterLogo;
