import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { OfferInvestmentBody } from './model';

export async function offerInvestment({
  transactionId,
  investmentRoundId,
  offerAmount,
}: OfferInvestmentBody): Promise<void> {
  await apiPost<unknown, OfferInvestmentBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.OfferInvestment,
    { transactionId, investmentRoundId, offerAmount },
    true
  );
}
