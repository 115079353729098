import * as Sentry from '@sentry/react';
import { History } from 'history';
import { matchPath } from 'react-router';
import stringify from 'fast-safe-stringify';
import { DefaultOptions, QueryClient } from 'react-query';

import { getUserDetails, queryClient, QueryKeys } from '@investown/fe/api-sdk';

import * as routePaths from 'routes/routesPaths';

export function initSentry({ history }: { history: History }): void {
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
  if (!sentryDsn || sentryDsn === '') {
    console.warn('Missing REACT_APP_SENTRY_DSN env variable');
    return;
  }

  Sentry.init({
    dsn: sentryDsn,
    environment: parseEnvironment(process.env.REACT_APP_ENVIRONMENT),
    release: process.env.REACT_APP_COMMIT_SHA, // REACT_APP_COMMIT_SHA is set in CI during build
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history, routes: getRoutes(), matchPath })],
    // https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#normalization-depth
    normalizeDepth: 5,
    // https://docs.sentry.io/platforms/javascript/guides/ember/configuration/options/#sample-rate
    tracesSampleRate: 1.0,
  });
}

function parseEnvironment(environment: string | undefined): string | undefined {
  if (!environment) return environment;

  return environment.replace('preview/', '');
}

export async function setCurrentUser(): Promise<void> {
  // TODO: change to externalID
  const { intercomUserId } = await queryClient.fetchQuery(QueryKeys.UserDetails, getUserDetails);
  Sentry.setUser({ id: intercomUserId });
}

export function clearCurrentUser(): void {
  Sentry.getCurrentScope().setUser(null);
}

export function captureSentryError(message: string, error: unknown): void {
  console.error('💥', message, error);
  Sentry.addBreadcrumb({ message });
  Sentry.captureException(error instanceof Error ? error : new CustomError(error ?? message));
}

class CustomError extends Error {
  constructor(anyError: unknown) {
    super(`(${typeof anyError}): ${tryStringify(anyError)}`);
  }
}

const tryStringify = (obj: unknown): string => {
  try {
    if (obj instanceof Error) return obj.message;
    return JSON.stringify(obj);
  } catch {
    try {
      return stringify(obj);
    } catch {
      return '(failed to stringify error object)';
    }
  }
};

function getRoutes(): Record<'path', (typeof routePaths)[keyof typeof routePaths]>[] {
  return Object.values(routePaths).map((path) => ({ path }));
}

export function setOnErrorCallbacks(client: QueryClient): void {
  const defaultOptions = client.getDefaultOptions();
  const {
    queries: { onError: queryOnError },
    mutations: { onError: mutationOnError },
  } = getDefaultOnErrorCallback(client.getDefaultOptions());

  client.setDefaultOptions({
    queries: {
      ...defaultOptions.queries,
      onError: (error) => {
        if (typeof queryOnError === 'function') queryOnError(error);
        captureSentryError('Query error: ', error);
      },
    },
    mutations: {
      ...defaultOptions.mutations,
      onError: (error, variables, context) => {
        if (typeof mutationOnError === 'function') mutationOnError(error, variables, context);
        captureSentryError('Mutation error: ', error);
      },
    },
  });
}

function getDefaultOnErrorCallback(options: DefaultOptions<unknown>): {
  queries: { onError: NonNullable<DefaultOptions['queries']>['onError'] };
  mutations: { onError: NonNullable<DefaultOptions['mutations']>['onError'] };
} {
  const { onError: queriesOnError } = options.queries || {};
  const { onError: mutationsOnError } = options.mutations || {};

  return { queries: { onError: queriesOnError }, mutations: { onError: mutationsOnError } };
}
