import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { AgreementGetFileUrlInput } from './model';

export async function getAgreementFileUrl(payload: AgreementGetFileUrlInput): Promise<{ url: string }> {
  return apiPost<{ url: string }, AgreementGetFileUrlInput>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.AgreementsGetFileUrl,
    payload,
    true
  );
}
