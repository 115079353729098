// eslint-disable-next-line import/no-extraneous-dependencies
import { ICognitoStorage } from 'amazon-cognito-identity-js';
import * as Cookies from 'js-cookie';

/**
 * Fork of default CookieStorage https://github.com/aws-amplify/amplify-js/blob/main/packages/amazon-cognito-identity-js/src/CookieStorage.js
 * The difference is in "domain" cookies attribute.
 * If you provide the domain "my.investown.cz" the cookies has domain set to ".my.investown.cz" (with leading dot).
 * To avoid the leading dot, we do not provide the domain which creates a cookie with domain set to "my.investown.cz" (current host).
 * Implements ICognitoStorage https://github.com/aws-amplify/amplify-js/blob/1f25f238ef5efe66e714e59042d58fd2a5334534/packages/amazon-cognito-identity-js/index.d.ts#L53
 */
export default class CustomCookieStorage implements ICognitoStorage {
  private readonly path: string;

  private readonly expires: number | undefined;

  private readonly secure: boolean;

  /**
   * Constructs a new CustomCookieStorage object
   * @param {object} data Creation options.
   * @param {string} data.path Cookies path (default: '/')
   * @param {number} data.expires Cookie expiration (in days, default: 1)
   * @param {boolean} data.secure Cookie secure flag (default: true)
   */
  constructor(data: { path?: string; expires?: number; secure: boolean }) {
    if (data.path) {
      this.path = data.path;
    } else {
      this.path = '/';
    }
    if (Object.prototype.hasOwnProperty.call(data, 'expires')) {
      this.expires = data.expires;
    } else {
      this.expires = 1;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'secure')) {
      this.secure = data.secure;
    } else {
      this.secure = true;
    }
  }

  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   */
  setItem(key: string, value: string | Record<string, unknown>): void {
    const options: Cookies.CookieAttributes = {
      path: this.path,
      expires: this.expires,
      secure: this.secure,
    };

    Cookies.set(key, value, options);
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * @returns {string} the data item
   */
  // eslint-disable-next-line class-methods-use-this
  getItem(key: string): string | null {
    return Cookies.get(key) || null;
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   */
  removeItem(key: string): void {
    const options: Cookies.CookieAttributes = {
      path: this.path,
      expires: this.expires,
      secure: this.secure,
    };

    return Cookies.remove(key, options);
  }

  /**
   * This is used to clear the storage
   */
  // eslint-disable-next-line class-methods-use-this
  clear(): void {
    const cookies = Object.keys(Cookies.get());
    cookies.forEach((cookie) => {
      Cookies.remove(cookie);
    });
  }
}
