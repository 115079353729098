import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  width: number | string;
  color?: 'normal' | 'black';
  className?: string;
}

const SkeletonRow: React.FC<Props> = ({ width, color = 'normal', className }) => {
  if (color === 'normal') {
    return <NormalSkeleton className={className} active paragraph={{ rows: 1, width }} title={false} />;
  }
  return <BlackSkeleton className={className} active paragraph={{ rows: 1, width }} title={false} />;
};

const NormalSkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
`;

const BlackSkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.color.black} 25%, ${theme.color.textFaded} 37%, ${theme.color.black} 63%)`} !important;
  }
`;

export default SkeletonRow;
