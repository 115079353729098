export enum LocalStorageKeys {
  ShowUserVerificationAfterSignup = 'showUserVerificationAfterSignup',
  SignUpEmail = 'signUpEmail',
  SignUpFirstName = 'signUpFirstName',
  SignUpLastName = 'signUpLastName',
  SignUpLegalEntityEmail = 'signUpLegalEntityEmail',
  SignUpLegalEntityIdentificationNumber = 'signUpLegalEntityIdentificationNumber',
  SignUpLegalEntityFirstName = 'signUpLegalEntityFirstName',
  SignUpLegalEntityLastName = 'signUpLegalEntityLastName',
  MobileScreenClosedTime = 'mobileScreenClosedTime',
}
