import { format } from 'date-fns';

export function getSuitabilityQuestionnaireAvailability({
  blockPeriod = 24,
  lastSuitabilityTry,
  locale,
}: {
  blockPeriod?: number;
  lastSuitabilityTry: Date | null | undefined;
  locale: string;
}): {
  canPassSuitabilityQuestionnaire: boolean;
  nextTryAvailableOn: string;
} {
  const nowTimestamp = new Date().getTime();
  let dateFormat;
  switch (locale) {
    case 'cs':
    case 'cs-CZ':
      dateFormat = 'd.M.yyyy HH:mm';
      break;
    default:
      dateFormat = 'M/d/yyyy HH:mm';
  }

  const nextTryAvailableNow = format(nowTimestamp, dateFormat);

  // User has not passed the questionnaire yet
  if (!lastSuitabilityTry) {
    return {
      canPassSuitabilityQuestionnaire: true,
      nextTryAvailableOn: nextTryAvailableNow,
    };
  }

  const lastSuitabilityTryTimestamp = lastSuitabilityTry && new Date(lastSuitabilityTry).getTime();
  const suitabilityBlockPeriod = blockPeriod * 60 * 60 * 1000;

  // User has already passed the questionnaire
  const nextTryAvailableOnTimestamp = lastSuitabilityTryTimestamp + suitabilityBlockPeriod;
  const canPassSuitabilityQuestionnaire = nowTimestamp > nextTryAvailableOnTimestamp;

  const nextTryAvailableOn = format(nextTryAvailableOnTimestamp, dateFormat);

  return {
    canPassSuitabilityQuestionnaire,
    nextTryAvailableOn,
  };
}
