import {
  InvestorQuestionnaireLanguageMutationsResponse,
  InvestorQuestionnaireNumberInputQuestionResponse,
  InvestorQuestionnaireQuestionChoiceResponse,
  InvestorQuestionnaireQuestionResponse,
  InvestorQuestionnaireQuestionType,
  InvestorQuestionnaireResponse,
  InvestorQuestionnaireTextChoiceQuestionResponse,
  InvestorQuestionnaire,
  InvestorQuestionnaireLang,
  InvestorQuestionnaireNumberInputQuestion,
  InvestorQuestionnaireTextChoiceQuestion,
  InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestionResponse,
  InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestion,
} from './model';

type QuestionConfigResponse =
  | InvestorQuestionnaireNumberInputQuestionResponse
  | InvestorQuestionnaireTextChoiceQuestionResponse
  | InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestionResponse;
type QuestionConfig =
  | InvestorQuestionnaireNumberInputQuestion
  | InvestorQuestionnaireTextChoiceQuestion
  | InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestion;

function getLangForQuestionConfig(
  questionConfig: QuestionConfigResponse,
  lang: InvestorQuestionnaireLang
): QuestionConfig {
  switch (questionConfig.type) {
    case InvestorQuestionnaireQuestionType.NumberInput:
      return {
        ...questionConfig,
        unit: questionConfig.unit[lang],
      };
    case InvestorQuestionnaireQuestionType.MultipleChoiceSubQuestions:
      return {
        type: questionConfig.type,
        subQuestions: questionConfig.subQuestions.map((subQuestion) => ({
          ...subQuestion,
          text: subQuestion.text[lang],
          choices: subQuestion.choices.map((choice) => ({
            ...choice,
            text: choice.text[lang],
          })),
        })),
      };
    default:
      return {
        type: questionConfig.type,
        choices: questionConfig.choices.map((choice: InvestorQuestionnaireQuestionChoiceResponse) => ({
          ...choice,
          text: choice.text[lang],
        })),
      };
  }
}

export function getInvestorQuestionnaireForLang(
  investorQuestionnaire: InvestorQuestionnaireResponse,
  lang: InvestorQuestionnaireLang
): InvestorQuestionnaire {
  const { version, questions } = investorQuestionnaire;

  return {
    version,
    questions: questions.map((question: InvestorQuestionnaireQuestionResponse) => ({
      id: question.id,
      title: question.title && question.title[lang],
      description: question.description
        ? question.description.map(
            (questionDescription: InvestorQuestionnaireLanguageMutationsResponse) => questionDescription[lang]
          )
        : [],
      questionText: question.questionText[lang],
      tooltipText: question.tooltipText[lang],
      questionConfig: getLangForQuestionConfig(question.questionConfig, lang),
    })),
  };
}
