import { UserDetailsDTO } from '../users';

import { UserVerificationDataDTO } from './model';

export function hasUserAllBasicAmlVerificationData(
  verificationData: UserVerificationDataDTO | undefined,
  userDetails: UserDetailsDTO | undefined
): boolean {
  if (!verificationData || !userDetails) {
    return false;
  }

  if (!userDetails.companyId) {
    return !!(
      verificationData.street &&
      verificationData.city &&
      verificationData.zip &&
      verificationData.countryIsoCode &&
      (verificationData.personalIdentifier || verificationData.dateBirth) &&
      userDetails.phoneNumber &&
      userDetails.phoneNumberVerified
    );
  }

  return !!(userDetails.phoneNumber && userDetails.phoneNumberVerified);
}
