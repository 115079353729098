import { MutateOptions, useMutation, UseMutationResult } from 'react-query';
import { useState } from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useInterval, assertUnreachable } from '@investown/fe/common-utils';

import { getOrderInfo, placeOrder } from '../orders';
import { OrderStatus, PlaceOrderInput } from '../orders/__generated__/sdk';
import { PlaceOrderMutation } from '../orders/__generated__/types';
import { ApiError } from '../ApiError';

export interface Options {
  polling?: PollingOptions;
  onInvestmentSettled?: (
    result:
      | { status: InvestmentOrderStatus.InvestingTurnedOff; order: PlaceOrderInput }
      | { status: InvestmentOrderStatus.Successful; order: PlaceOrderInput }
      | { status: InvestmentOrderStatus.Unsuccessful; order: PlaceOrderInput; unsuccessReasons: string[] }
      | { status: InvestmentOrderStatus.Failed; order: PlaceOrderInput; failReasons: string[] }
      | { status: InvestmentOrderStatus.Timeout; order: PlaceOrderInput }
  ) => void | Promise<void>;
}

export interface PollingOptions {
  intervalInMillis: number;
  maxAttempts: number;
}

export type UseInvestmentOrderResult = {
  placeOrder: (input: PlaceOrderInput, options?: MutateOptions<PlaceOrderMutation, Error, PlaceOrderInput>) => void;
  orderResult: OrderResult;
  status: UseMutationResult<PlaceOrderMutation, Error, PlaceOrderInput, unknown>['status'];
};

export type OrderResult =
  | { status: InvestmentOrderStatus.NotPlaced }
  | { status: InvestmentOrderStatus.InvestingTurnedOff; order: PlaceOrderInput }
  | { status: InvestmentOrderStatus.InProgress; order: PlaceOrderInput }
  | { status: InvestmentOrderStatus.Successful; order: PlaceOrderInput }
  | { status: InvestmentOrderStatus.Unsuccessful; order: PlaceOrderInput; unsuccessReasons: string[] }
  | { status: InvestmentOrderStatus.Failed; order: PlaceOrderInput; failReasons: string[] | ['unknown'] }
  | { status: InvestmentOrderStatus.Timeout; order: PlaceOrderInput };

export enum InvestmentOrderStatus {
  NotPlaced = 'NotPlaced',
  InvestingTurnedOff = 'InvestingTurnedOff',
  InProgress = 'InProgress',
  Successful = 'Successful',
  Unsuccessful = 'Unsuccessful',
  Failed = 'Failed',
  Timeout = 'Timeout',
}

export const useInvestmentOrder = (options: Options = {}): UseInvestmentOrderResult => {
  const [orderTransactionId, setOrderTransactionId] = useState<string | undefined>();
  const [attemptsMade, setAttemptsMade] = useState(0);
  const [inputOrder, setInputOrder] = useState<PlaceOrderInput | undefined>();
  const [orderProcessingResult, setOrderProcessingResult] = useState<OrderResult>({
    status: InvestmentOrderStatus.NotPlaced,
  });

  const placeOrderMutation = useMutation<PlaceOrderMutation, Error, PlaceOrderInput>(placeOrder);

  const updateOrderProcessingResult: (result: OrderResult) => void = (result) => {
    setOrderProcessingResult(result);
    if (options.onInvestmentSettled) {
      if (
        result.status === InvestmentOrderStatus.InvestingTurnedOff ||
        result.status === InvestmentOrderStatus.Successful ||
        result.status === InvestmentOrderStatus.Unsuccessful ||
        result.status === InvestmentOrderStatus.Failed ||
        result.status === InvestmentOrderStatus.Timeout
      ) {
        options.onInvestmentSettled(result);
      }
    }
  };

  /**
   * State must be reset for situations when component
   * tries to invest multiple times using the same hook instance.
   */
  const resetToInitialState: () => void = () => {
    setOrderTransactionId(undefined);
    setAttemptsMade(0);
    setOrderProcessingResult({
      status: InvestmentOrderStatus.NotPlaced,
    });
    setInputOrder(undefined);
  };

  const placeOrderCallback: (
    input: PlaceOrderInput,
    mutateOptions?: MutateOptions<PlaceOrderMutation, Error, PlaceOrderInput>
  ) => void = (input, mutateOptions) => {
    resetToInitialState();
    setInputOrder(input);
    updateOrderProcessingResult({ status: InvestmentOrderStatus.InProgress, order: input });
    placeOrderMutation.mutate(input, {
      ...mutateOptions,
      onError: (error, ...args) => {
        if (error instanceof ApiError) {
          if (error.code === 'FeatureTurnedOffError') {
            updateOrderProcessingResult({ status: InvestmentOrderStatus.InvestingTurnedOff, order: input });
          } else {
            updateOrderProcessingResult({
              status: InvestmentOrderStatus.Failed,
              order: input,
              failReasons: [error.code ?? 'unknown'],
            });
          }
        }
        if (mutateOptions?.onError) {
          mutateOptions.onError(error, ...args);
        }
      },
      onSuccess: (...args) => {
        setOrderTransactionId(input.transactionId);
        if (mutateOptions?.onSuccess) {
          mutateOptions.onSuccess(...args);
        }
      },
    });
  };

  useInterval(async (clearInterval) => {
    if (!options.polling) {
      clearInterval();
      return;
    }

    if (orderProcessingResult.status === InvestmentOrderStatus.InvestingTurnedOff) {
      return;
    }

    if (orderTransactionId) {
      if (attemptsMade === options.polling.maxAttempts) {
        // all attempts exhausted
        updateOrderProcessingResult({ status: InvestmentOrderStatus.Timeout, order: inputOrder! });
        return;
      }

      const terminalProcessingStatuses = [
        InvestmentOrderStatus.Successful,
        InvestmentOrderStatus.Unsuccessful,
        InvestmentOrderStatus.Failed,
      ];
      if (terminalProcessingStatuses.includes(orderProcessingResult.status)) {
        // processing already finished
        return;
      }

      if (attemptsMade < options.polling.maxAttempts) {
        setAttemptsMade((currentAttemptsMade) => currentAttemptsMade + 1);
        try {
          const orderInfo = await getOrderInfo({ transactionId: orderTransactionId });

          switch (orderInfo.Order.status) {
            case OrderStatus.InProgress:
              updateOrderProcessingResult({ status: InvestmentOrderStatus.InProgress, order: inputOrder! });
              return;
            case OrderStatus.Completed:
              updateOrderProcessingResult({ status: InvestmentOrderStatus.Successful, order: inputOrder! });
              return;
            case OrderStatus.Unsuccessful:
              updateOrderProcessingResult({
                status: InvestmentOrderStatus.Unsuccessful,
                order: inputOrder!,
                unsuccessReasons: orderInfo.Order.unsuccessOrFailReasons,
              });
              return;
            case OrderStatus.Failed:
              updateOrderProcessingResult({
                status: InvestmentOrderStatus.Failed,
                order: inputOrder!,
                failReasons: orderInfo.Order.unsuccessOrFailReasons,
              });
              return;
            default:
              assertUnreachable(orderInfo.Order.status);
          }
        } catch (err) {
          console.warn(`Error occurred during polling for order status.`, err);
        }
      }
    }
  }, options.polling?.intervalInMillis ?? 1);

  return {
    placeOrder: placeOrderCallback,
    orderResult: orderProcessingResult,
    status: placeOrderMutation.status,
  };
};
