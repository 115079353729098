import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { LegalEntityRequiredDocuments } from './model';

export async function getLegalEntityRequiredDocuments(): Promise<LegalEntityRequiredDocuments> {
  const response = await apiGet<LegalEntityRequiredDocuments>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.LegalEntityRequiredDocuments,
    true
  );

  if (response === undefined) {
    throw new Error('Invalid response');
  }

  return response;
}
