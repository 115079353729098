import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';

import { QueryKeys } from '../enums';
import { queryStaleTimes } from '../reactQueryConfig';

import { getInvestorQuestionnaireDefinition } from './getInvestorQuestionnaireDefinition';
import { submitSuitabilityInvestorQuestionnaire } from './submitSuitabilityInvestorQuestionnaire';
import { getInvestorQuestionnaireForLang } from './getInvestorQuestionnaireForLang';
import {
  InvestorQuestionnaireAnswer,
  InvestorQuestionnaireQuestion,
  InvestorQuestionnaireSubmitQuestionAnswer,
  UseInvestorQuestionnaire,
  UseInvestorQuestionnaireProps,
  InvestorQuestionnaireQuestionType,
  SubmitAnswerPayload,
  BooleanAnswer,
  NumberAnswer,
  TextAnswer,
  MultiChoiceAnswer,
  MultiChoiceSubQuestionsTextAnswer,
} from './model';

export function useInvestorQuestionnaire({
  language,
  isLegalEntity,
  suitability,
  submitGaEvent,
}: UseInvestorQuestionnaireProps): UseInvestorQuestionnaire {
  const [progress, setProgress] = useState<number>(1);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const [questionnaire, setQuestionnaire] = useState<InvestorQuestionnaireQuestion[]>([]);
  const [actualQuestion, setActualQuestion] = useState<InvestorQuestionnaireQuestion | undefined>(undefined);
  const [questionnaireVersion, setQuestionnaireVersion] = useState<number>(0);
  const [answers, setAnswers] = useState<InvestorQuestionnaireSubmitQuestionAnswer[]>([]);
  const [lastAnswer, setLastAnswer] = useState<{
    questionId: string;
    type: InvestorQuestionnaireQuestionType;
    answer: InvestorQuestionnaireAnswer;
  } | null>(null);

  const { data: definition, isLoading: isDefinitionLoading } = useQuery(
    [QueryKeys.InvestorQuestionnaire, { isLegalEntity, suitability }],
    () => getInvestorQuestionnaireDefinition({ isLegalEntity, suitability }),
    {
      staleTime: queryStaleTimes.infinite,
    }
  );

  const {
    data: submitResponseData,
    mutate: submit,
    isLoading: isSubmitLoading,
  } = useMutation(QueryKeys.InvestorQuestionnaireSubmit, submitSuitabilityInvestorQuestionnaire);

  /** parse response and handle only requested language */
  useEffect(() => {
    if (definition && questionnaireVersion !== definition.version) {
      const { version, questions } = getInvestorQuestionnaireForLang(definition, language);
      setQuestionnaire(questions);
      setQuestionnaireVersion(version);
      setProgress(1);
      setNumberOfQuestions(questions.length);
    }
  }, [
    definition,
    questionnaireVersion,
    setQuestionnaire,
    language,
    setQuestionnaireVersion,
    setProgress,
    setNumberOfQuestions,
  ]);

  /** set actual question */
  useEffect(() => {
    setActualQuestion(questionnaire[progress - 1]);
  }, [progress, setActualQuestion, questionnaire]);

  const goBack = useCallback(() => {
    if (progress > 1) {
      setProgress((oldProgress) => oldProgress - 1);
      setLastAnswer(null);
      lastAnswer &&
        submitGaEvent &&
        submitGaEvent({
          questionId: lastAnswer?.questionId,
          answer: '',
          isGoingBack: true,
        });
    }
  }, [progress, setProgress, setLastAnswer, lastAnswer, submitGaEvent]);

  const submitAnswer = useCallback(
    (payload: SubmitAnswerPayload) => {
      // isEqual condition prevents duplicate (double-click) answers to the same question
      if (!isEqual(payload, lastAnswer)) {
        setLastAnswer(payload);
        const newAnswers = [
          ...answers.filter((oldAnswer) => oldAnswer.questionId !== payload.questionId), // answer must be replaced if user moved back and fills it in again
          payload,
        ];
        setAnswers(newAnswers);

        // Get questionID and answerID to pass to GA
        const answerValue =
          (payload?.answer as BooleanAnswer)?.value ||
          (payload?.answer as NumberAnswer)?.value ||
          (payload?.answer as TextAnswer)?.answerId ||
          (payload?.answer as MultiChoiceAnswer)?.choices;
        if (typeof answerValue === 'object') {
          answerValue.forEach((choice) => {
            const { answerId } = choice;
            const { subQuestionId } = choice as MultiChoiceSubQuestionsTextAnswer;
            submitGaEvent &&
              submitGaEvent({
                questionId: subQuestionId,
                answer: answerId,
              });
          });
        } else {
          submitGaEvent &&
            submitGaEvent({
              questionId: payload.questionId,
              answer: answerValue,
            });
        }

        if (progress === numberOfQuestions) {
          submit({ version: questionnaireVersion, answers: newAnswers });
        } else {
          setProgress((oldProgress) => oldProgress + 1);
        }
      }
    },
    [lastAnswer, answers, progress, numberOfQuestions, submitGaEvent, submit, questionnaireVersion, setLastAnswer]
  );

  if (!isDefinitionLoading && !isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: false,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData,
    };
  }

  if (!isDefinitionLoading && isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: true,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData: undefined,
    };
  }

  return { isDefinitionLoading: true, isSubmitLoading: false, submitResponseData: undefined };
}
