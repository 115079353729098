import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuButton = styled(Link)<{ $active: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.large};
  background: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.surface.background};
  border-color: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.stroke.medium};
  margin-left: ${({ theme }) => theme.spacing.large};

  &:hover * {
    color: ${({ theme }) => theme.colorTokens.text.brand};

    path {
      stroke: ${({ theme }) => theme.colorTokens.text.brand};
    }
  }
`;
