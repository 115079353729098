import { useQuery, UseQueryResult } from 'react-query';

import { getUserVerificationData, UserVerificationDataResponse } from '../userVerification';
import { QueryKeys } from '../enums';

export const useUserVerificationData = (
  refetchInterval?: number | false | undefined | ((data: UserVerificationDataResponse | undefined) => number | false),
  refetchOnMount?: boolean | 'always'
): UseQueryResult<UserVerificationDataResponse> =>
  useQuery(QueryKeys.UserVerificationData, getUserVerificationData, {
    refetchInterval: refetchInterval ?? false,
    refetchOnMount: refetchOnMount ?? true,
    notifyOnChangeProps: ['data', 'isError', 'isLoading'],
  });
