import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { CancelSecondaryMarketOffers } from './model';

export async function cancelSecondaryMarketOffers({ investmentRoundId }: CancelSecondaryMarketOffers): Promise<void> {
  await apiPost<unknown, CancelSecondaryMarketOffers>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.CancelSecondaryMarketOffers,
    { investmentRoundId },
    true
  );
}
