import { useState, useCallback, useEffect } from 'react';

export interface ManagedDataColumn {
  key: string;
  isSortable: boolean;
  isSortBy: boolean;
  isSortDesc: boolean;
}

export interface SortableColumn {
  columnKey: string;
  objectPath: string;
}

export interface SortByDefaultOptions {
  sortableColumns?: SortableColumn[];
  sortBy?: SortableColumn;
  isSortDesc?: boolean;
}

export interface UseSortByProps {
  columns: ManagedDataColumn[];
  sortBy: SortableColumn | null | undefined;
  isSortDesc: boolean;
  changeSortBy: (column: string) => void;
}

function useSortBy(columns: string[], defaultOptions?: SortByDefaultOptions): UseSortByProps {
  const [sortBy, setSortBy] = useState<SortableColumn | null | undefined>(defaultOptions?.sortBy ?? null);
  const [isSortDesc, setIsSortDesc] = useState<boolean>(defaultOptions?.isSortDesc ?? false);
  const [extendedColumns, setExtendedColumns] = useState<ManagedDataColumn[]>([]);
  const sortableColumns = defaultOptions?.sortableColumns ?? [];
  const sortableColumnKeys = sortableColumns.map((sortableColumn) => sortableColumn.columnKey);

  useEffect(() => {
    const actualColumns = columns.map((item) => ({
      key: item,
      isSortable: sortableColumnKeys.includes(item),
      isSortBy: sortBy?.columnKey === item,
      isSortDesc,
    }));
    setExtendedColumns(actualColumns);
  }, [sortBy, isSortDesc]);

  const changeSortBy = useCallback(
    (column: string) => {
      if (sortableColumnKeys.includes(column)) {
        if (column === sortBy?.columnKey) {
          if (isSortDesc) {
            return setIsSortDesc(false);
          }
          return setSortBy(null);
        }
        setSortBy(sortableColumns.find((sortableColumn) => sortableColumn.columnKey === column));
        setIsSortDesc(true);
      }
    },
    [isSortDesc, sortBy, sortableColumnKeys, sortableColumns]
  );

  return {
    columns: extendedColumns,
    sortBy,
    isSortDesc,
    changeSortBy,
  };
}

export default useSortBy;
