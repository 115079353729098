// Inspired by https://github.com/devrnt/react-use-intercom/blob/4ca8658877e0acd2c94cdff86573ecf47585da87/src/initialize.ts
/* eslint-disable */
// @ts-nocheck

import { captureSentryError } from './sentry';

export type WindowWithIntercom = Window & {
  intercomSettings: {
    app_id: string;
  };
};
const intercomInitialize = () => {
  try {
    const appId = process.env.REACT_APP_INTERCOM_APP_ID;
    if (!appId) {
      throw new Error('Missing REACT_APP_INTERCOM_APP_ID env variable');
    }

    window.intercomSettings = {
      app_id: appId,
    };

    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  } catch (err) {
    captureSentryError('Failed to initialize Intercom', err);
    console.warn('Failed to initialize Intercom', err);
  }
};

export default intercomInitialize;
