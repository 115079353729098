export const hasNumber = (value: string): boolean => new RegExp(/(?=.*[0-9])/).test(value);
export const hasOneLower = (value: string): boolean => value !== value.toUpperCase();
export const hasOneUpper = (value: string): boolean => value !== value.toLowerCase();

export const hasEightChars = (value: string): boolean => value.length >= 8;

export interface PasswordStrength {
  strength: number;
  hasOneLower: boolean;
  hasOneUpper: boolean;
  hasOneNumber: boolean;
  longEnough: boolean;
}

export const strengthIndicator = (value: string): PasswordStrength | null => {
  let strength = 0;
  let hasOneNumberResult = false;
  let hasOneLowerResult = false;
  let hasOneUpperResult = false;
  let longEnough = false;

  if (value.length === 0) {
    return null;
  }

  if (value.length > 4) {
    strength += 20;
  }
  if (hasEightChars(value)) {
    strength += 20;
    longEnough = true;
  }
  if (hasNumber(value)) {
    strength += 20;
    hasOneNumberResult = true;
  }
  if (hasOneLower(value)) {
    strength += 20;
    hasOneLowerResult = true;
  }
  if (hasOneUpper(value)) {
    strength += 20;
    hasOneUpperResult = true;
  }

  return {
    strength,
    hasOneLower: hasOneLowerResult,
    hasOneUpper: hasOneUpperResult,
    hasOneNumber: hasOneNumberResult,
    longEnough,
  };
};
