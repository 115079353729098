import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from 'assets/images/icons/spinner.svg';

interface Props {
  className?: string;
}

function Spinner({ className }: Props) {
  return (
    <div className={className}>
      <StyledIcon />
    </div>
  );
}

export default Spinner;

const StyledIcon = styled(Icon)`
  animation: fxicon-spin 1s infinite linear;
`;
