import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

/** Describes a single device token with additional info about the device */
export type DeviceTokenDetail = {
  __typename?: 'DeviceTokenDetail';
  /** Unique ID of the token */
  id: Scalars['ID'];
  /** Value (device-generated) of the token */
  value: Scalars['String'];
  /** User-set or default device name sent together with token */
  deviceName: Scalars['String'];
  /** Provider of the token */
  pushProvider: PushProvider;
  /** Info about the device platform */
  platformInfo?: Maybe<PlatformInfo>;
  createdAt: Scalars['Timestamp'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDeviceToken: Scalars['Boolean'];
  deleteDeviceToken: Scalars['Boolean'];
  /** Mutation for (partial) patching of the user global notification preferences */
  patchUserGlobalPreferences: UserPreferences;
  /** Mutation for (partial) patching of the user notification category preference */
  patchUserPreference: UserPreferences;
};

export type MutationCreateDeviceTokenArgs = {
  input: NewDeviceTokenInput;
};

export type MutationDeleteDeviceTokenArgs = {
  deviceTokenValue: Scalars['String'];
};

export type MutationPatchUserGlobalPreferencesArgs = {
  input: PatchUserGlobalPreferencesInput;
};

export type MutationPatchUserPreferenceArgs = {
  input: PatchUserPreferenceInput;
};

/** Input type for creating new device token with additional info about the device */
export type NewDeviceTokenInput = {
  /** Value (device-generated) of the token */
  value: Scalars['String'];
  /** User-set or default device name sent together with token */
  deviceName: Scalars['String'];
  /** Provider of the token */
  pushProvider: PushProvider;
  /** Info about the device platform */
  platformInfo?: Maybe<PlatformInfoInput>;
};

/** Input type for (partial) patching of the user global notification preferences */
export type PatchUserGlobalPreferencesInput = {
  /** If false, the user does not want to receive any EMAIL notifications. */
  emailNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  /** If false, the user does not want to or cannot receive any PUSH notifications. */
  pushNotificationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type PatchUserPreferenceInput = {
  preferenceCategoryName: PreferenceCategoryName;
  email?: Maybe<Scalars['Boolean']>;
  push?: Maybe<Scalars['Boolean']>;
};

/** Info about the device platform */
export type PlatformInfo = {
  __typename?: 'PlatformInfo';
  /** Platform operating system (e.g. iOS) */
  platformOs?: Maybe<Scalars['String']>;
  /** Device model */
  model?: Maybe<Scalars['String']>;
  /** Version of OS */
  platformOsVersion?: Maybe<Scalars['String']>;
  /** Identifier of the application instance */
  appId?: Maybe<Scalars['String']>;
};

/** Info about the device platform */
export type PlatformInfoInput = {
  /** Platform operating system (e.g. iOS) */
  platformOs?: Maybe<Scalars['String']>;
  /** Device model */
  model?: Maybe<Scalars['String']>;
  /** Version of OS */
  platformOsVersion?: Maybe<Scalars['String']>;
  /** Identifier of the application instance */
  appId?: Maybe<Scalars['String']>;
};

export enum PreferenceCategoryName {
  InformationOnProjects = 'InformationOnProjects',
  InformationForUsers = 'InformationForUsers',
  NewProjectsMarketing = 'NewProjectsMarketing',
  MyProjectsUpdates = 'MyProjectsUpdates',
  Personalized = 'Personalized',
}

export enum PushProvider {
  OneSignal = 'OneSignal',
}

export type Query = {
  __typename?: 'Query';
  DeviceToken: DeviceTokenDetail;
  UserPreferences: UserPreferences;
};

export type QueryDeviceTokenArgs = {
  id: Scalars['ID'];
};

export type UserPreference = {
  __typename?: 'UserPreference';
  preferenceCategoryName: PreferenceCategoryName;
  email: Scalars['Boolean'];
  push: Scalars['Boolean'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  /** If false, the user does not want to receive any EMAIL notifications. */
  emailNotificationsEnabled: Scalars['Boolean'];
  /** If false, the user does not want to or cannot receive any PUSH notifications. */
  pushNotificationsEnabled: Scalars['Boolean'];
  categoryPreferences: Array<UserPreference>;
};

export type CreateDeviceTokenMutationVariables = Exact<{
  input: NewDeviceTokenInput;
}>;

export type CreateDeviceTokenMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createDeviceToken'>;

export type DeleteDeviceTokenMutationVariables = Exact<{
  deviceTokenValue: Scalars['String'];
}>;

export type DeleteDeviceTokenMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteDeviceToken'>;

export type PatchUserGlobalPreferencesMutationVariables = Exact<{
  input: PatchUserGlobalPreferencesInput;
}>;

export type PatchUserGlobalPreferencesMutation = { __typename?: 'Mutation' } & {
  patchUserGlobalPreferences: { __typename?: 'UserPreferences' } & Pick<
    UserPreferences,
    'emailNotificationsEnabled' | 'pushNotificationsEnabled'
  >;
};

export type PatchUserPreferenceMutationVariables = Exact<{
  input: PatchUserPreferenceInput;
}>;

export type PatchUserPreferenceMutation = { __typename?: 'Mutation' } & {
  patchUserPreference: { __typename?: 'UserPreferences' } & {
    categoryPreferences: Array<
      { __typename?: 'UserPreference' } & Pick<UserPreference, 'preferenceCategoryName' | 'email' | 'push'>
    >;
  };
};

export type GetUserPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPreferencesQuery = { __typename?: 'Query' } & {
  UserPreferences: { __typename?: 'UserPreferences' } & Pick<
    UserPreferences,
    'emailNotificationsEnabled' | 'pushNotificationsEnabled'
  > & {
      categoryPreferences: Array<
        { __typename?: 'UserPreference' } & Pick<UserPreference, 'preferenceCategoryName' | 'email' | 'push'>
      >;
    };
};

export const CreateDeviceTokenDocument = gql`
  mutation CreateDeviceToken($input: NewDeviceTokenInput!) {
    createDeviceToken(input: $input)
  }
`;
export const DeleteDeviceTokenDocument = gql`
  mutation DeleteDeviceToken($deviceTokenValue: String!) {
    deleteDeviceToken(deviceTokenValue: $deviceTokenValue)
  }
`;
export const PatchUserGlobalPreferencesDocument = gql`
  mutation PatchUserGlobalPreferences($input: PatchUserGlobalPreferencesInput!) {
    patchUserGlobalPreferences(input: $input) {
      emailNotificationsEnabled
      pushNotificationsEnabled
    }
  }
`;
export const PatchUserPreferenceDocument = gql`
  mutation PatchUserPreference($input: PatchUserPreferenceInput!) {
    patchUserPreference(input: $input) {
      categoryPreferences {
        preferenceCategoryName
        email
        push
      }
    }
  }
`;
export const GetUserPreferencesDocument = gql`
  query GetUserPreferences {
    UserPreferences {
      emailNotificationsEnabled
      pushNotificationsEnabled
      categoryPreferences {
        preferenceCategoryName
        email
        push
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateDeviceToken(
      variables: CreateDeviceTokenMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateDeviceTokenMutation> {
      return withWrapper(() =>
        client.request<CreateDeviceTokenMutation>(CreateDeviceTokenDocument, variables, requestHeaders)
      );
    },
    DeleteDeviceToken(
      variables: DeleteDeviceTokenMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteDeviceTokenMutation> {
      return withWrapper(() =>
        client.request<DeleteDeviceTokenMutation>(DeleteDeviceTokenDocument, variables, requestHeaders)
      );
    },
    PatchUserGlobalPreferences(
      variables: PatchUserGlobalPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PatchUserGlobalPreferencesMutation> {
      return withWrapper(() =>
        client.request<PatchUserGlobalPreferencesMutation>(
          PatchUserGlobalPreferencesDocument,
          variables,
          requestHeaders
        )
      );
    },
    PatchUserPreference(
      variables: PatchUserPreferenceMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PatchUserPreferenceMutation> {
      return withWrapper(() =>
        client.request<PatchUserPreferenceMutation>(PatchUserPreferenceDocument, variables, requestHeaders)
      );
    },
    GetUserPreferences(
      variables?: GetUserPreferencesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetUserPreferencesQuery> {
      return withWrapper(() =>
        client.request<GetUserPreferencesQuery>(GetUserPreferencesDocument, variables, requestHeaders)
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
