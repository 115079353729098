import { CognitoUser } from '@aws-amplify/auth';

import { BasicUserInfo, CognitoUserAttributes } from './model';

export function getUserInfoFromCognitoUser(user: CognitoUser & { attributes?: CognitoUserAttributes }): BasicUserInfo {
  const { attributes } = user;
  if (!attributes) {
    throw new Error('Attributes missing in user object');
  }
  return {
    givenName: attributes.given_name,
    familyName: attributes.family_name,
    email: attributes.email,
  };
}
