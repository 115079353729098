import { apiGet } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { InvestorQuestionnaireResponse } from './model';

export async function getAbilityToBearLossQuestionnaireDefinition(): Promise<InvestorQuestionnaireResponse> {
  return apiGet<InvestorQuestionnaireResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.AbilityToBearLossQuestionnaire,
    true
  );
}
