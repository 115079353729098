import { SagaIterator } from 'redux-saga';
import { all, call, delay, fork, takeEvery } from 'redux-saga/effects';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import {
  secondaryMarketOfferCreatedAction,
  secondaryMarketOfferErrorAction,
  secondaryMarketOfferCanceledAction,
  secondaryMarketOffersModalClosedAction,
  secondaryMarketBidAcceptedAction,
} from './actions';

function* resetCaches(): SagaIterator {
  yield all([
    call([queryClient, queryClient.invalidateQueries], QueryKeys.Property),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.InvestedProperties),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PropertyInvestments),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.ActiveSecondaryMarketRemainingOffersSums),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.SecondaryMarketItems),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.Wallet),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.InvestmentRoundsSecondaryMarketInfo),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PortfolioStatistics),
  ]);

  yield delay(4000);

  // Setting new user level can take a while so delay a bit
  call([queryClient, queryClient.invalidateQueries], QueryKeys.UserDetails);
}

export function* watchSecondaryMarketOfferCreatedAction(): SagaIterator {
  yield takeEvery(secondaryMarketOfferCreatedAction, resetCaches);
}

export function* watchSecondaryMarketOfferErrorAction(): SagaIterator {
  yield takeEvery(secondaryMarketOfferErrorAction, resetCaches);
}

export function* watchSecondaryMarketOfferCanceledAction(): SagaIterator {
  yield takeEvery(secondaryMarketOfferCanceledAction, resetCaches);
}

export function* watchSecondaryMarketOffersModalClosed(): SagaIterator {
  yield takeEvery(secondaryMarketOffersModalClosedAction, resetCaches);
}

export function* watchsecondaryMarketBidAcceptedAction(): SagaIterator {
  yield takeEvery(secondaryMarketBidAcceptedAction, resetCaches);
}

export default function* root(): SagaIterator {
  yield all([
    fork(watchSecondaryMarketOfferCreatedAction),
    fork(watchSecondaryMarketOfferErrorAction),
    fork(watchSecondaryMarketOfferCanceledAction),
    fork(watchSecondaryMarketOffersModalClosed),
    fork(watchsecondaryMarketBidAcceptedAction),
  ]);
}
