import addDays from 'date-fns/addDays';
import * as Cookies from 'js-cookie';

const cookiesKey = 'veriffSessionUrl';

// Veriff sessions expire in 7 days, 1 day for temporary defensive approach
const sessionExpirationInDays = 1;

export function saveSessionUrl(url: string): void {
  Cookies.set(cookiesKey, url, { expires: addDays(new Date(), sessionExpirationInDays) });
}

export function getSessionUrl(): string | undefined {
  return Cookies.get(cookiesKey);
}

export function clearSessionUrl(): void {
  Cookies.remove(cookiesKey);
}
