import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as SvgLogo } from 'assets/images/logo.svg';

const Logo: FC<{ className?: string }> = ({ className }) => <StyledLogo className={className} />;

const StyledLogo = styled(SvgLogo)`
  position: relative;
  height: 20px;
  width: auto;
`;

export default Logo;
