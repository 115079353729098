import { googleTagManager } from '@investown/fe/common-utils';

/**
 * Virtual page views help with the tracking of events in a single page application
 */
interface VirtualPage {
  path: string;
  title: string;
}

export const VirtualPageViews: Record<string, VirtualPage> = {
  signUpOpened: {
    path: '/virtual/sign-up/opened',
    title: 'Virtual - Sign Up - Opened',
  },
  signUpFinished: {
    path: '/virtual/sign-up/finished',
    title: 'Virtual - Sign Up - Finished',
  },
  verificationOpened: {
    path: '/virtual/verification/opened',
    title: 'Virtual - Verification - Opened',
  },
  verificationFinishedAddress: {
    path: '/virtual/verification/finished-address',
    title: 'Virtual - Verification - Finished address',
  },
  verificationFinishedBirthday: {
    path: '/virtual/verification/finished-birthday',
    title: 'Virtual - Verification - Finished birthday',
  },
  verificationFinishedPhone: {
    path: '/virtual/verification/finished-phone',
    title: 'Virtual - Verification - Finished phone',
  },
  verificationFinishedPep: {
    path: '/virtual/verification/finished-pep',
    title: 'Virtual - Verification - Finished PEP',
  },
  verificationFinishedDocuments: {
    path: '/virtual/verification/finished-documents',
    title: 'Virtual - Verification - Finished documents',
  },
  verificationFinishedTermsAndConditions: {
    path: '/virtual/verification/finished-tnc',
    title: 'Virtual - Verification - Finished Terms and Conditions',
  },
  verificationFinishedMoneyOrigin: {
    path: '/virtual/verification/finished-money-origin',
    title: 'Virtual - Verification - Finished money origin',
  },
  verificationSubmitted: {
    path: '/virtual/verification/submitted',
    title: 'Virtual - Verification - Submitted all verification data',
  },
  verificationInvestorQuestionnaireOpened: {
    path: '/virtual/verification/investorQuestionnaireOpened',
    title: 'Virtual - Verification - InvestorQuestionnaire - Opened',
  },
  verificationInvestorQuestionnaireSubmitted: {
    path: '/virtual/verification/investorQuestionnaireSubmitted',
    title: 'Virtual - Verification - InvestorQuestionnaire - Submitted',
  },
  invested: {
    path: '/virtual/invested',
    title: 'Virtual - User invested',
  },
  userFirstInvestment: {
    path: '/virtual/invested/first',
    title: 'Virtual - User First invested',
  },
};

export const pushVirtualPageViewToGtm = ({ path, title }: VirtualPage): void => {
  try {
    googleTagManager({
      event: 'virtualPageView',
      virtualPageViewData: {
        path,
        title,
      },
    });
  } catch (e) {
    console.warn(e);
  }
};
