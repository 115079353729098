import { useQuery, UseQueryResult } from 'react-query';

import { HasPermissionToInviteThroughReferralQuery } from '../affiliate/__generated__/sdk';
import { hasPermissionToInviteThroughReferral } from '../affiliate';
import { QueryKeys } from '../enums';

export function useHasPermissionToInviteThroughReferralResult(): UseQueryResult<
  HasPermissionToInviteThroughReferralQuery,
  Error
> {
  return useQuery<HasPermissionToInviteThroughReferralQuery, Error>(
    [QueryKeys.HasPermissionToInviteThroughReferral],
    () => hasPermissionToInviteThroughReferral()
  );
}
