import { CognitoUser } from '@aws-amplify/auth';
import { ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as actions from 'appRedux/auth/actions';
import { AUTH_SIGN_IN_SUCCESS, AUTH_SIGN_OUT_SUCCESS } from 'appRedux/auth/constants';

export type AuthAction = ActionType<typeof actions>;

export interface AuthState {
  cognitoUser?: CognitoUser;
}

const initialState: AuthState = {
  cognitoUser: undefined,
};

const reducer: Reducer<AuthState, AuthAction> = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        cognitoUser: action.payload,
      };

    case AUTH_SIGN_OUT_SUCCESS:
      return {
        ...state,
        cognitoUser: undefined,
      };

    default:
      return state;
  }
};

export default reducer;
