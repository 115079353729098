import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { WalletWithdrawalBody } from './model';

export async function requestWithdrawal({
  bankAccountId,
  amount,
  currency,
  reason,
  note,
}: WalletWithdrawalBody): Promise<void> {
  await apiPost<unknown, WalletWithdrawalBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.WithdrawFromWallet,
    { bankAccountId, amount, currency, reason, note },
    true
  );
}
