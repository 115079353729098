import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { QueryKeys } from '../enums';
import { getUserDetails, UserDetailsDTO } from '../users';
import { getUserVerificationData, UserVerificationDataDTO } from '../userVerification';

export interface UserVerificationResponse {
  userDetails?: UserDetailsDTO;
  verificationData?: UserVerificationDataDTO;
}

/** @deprecated Use useUserVerificationData and useUserDetails separately from now */
export const useUserVerification = (refetchInterval?: number): UserVerificationResponse | undefined => {
  const [userVerification, setUserVerification] = useState<undefined | UserVerificationResponse>(undefined);

  const {
    status: userDetailsLoadingStatus,
    data: userDetails,
    error: userDetailsError,
  } = useQuery<UserDetailsDTO, Error>(QueryKeys.UserDetails, getUserDetails, {
    refetchInterval: refetchInterval ?? false,
  });

  const {
    status: verificationDataLoading,
    data: verificationData,
    error: verificationDataError,
  } = useQuery<UserVerificationDataDTO, Error>(QueryKeys.UserVerificationData, getUserVerificationData, {
    refetchInterval: refetchInterval ?? false,
  });

  useEffect(() => {
    if (userDetailsLoadingStatus !== 'loading' && verificationDataLoading !== 'loading') {
      if (userDetailsError) {
        throw userDetailsError;
      }

      if (verificationDataError) {
        throw verificationDataError;
      }

      if (!userDetails?.verificationStatus) {
        throw new Error('The user does not have the verification status.');
      }
      setUserVerification({
        userDetails,
        verificationData,
      });
    }
  }, [
    userDetailsLoadingStatus,
    verificationDataLoading,
    userDetails,
    verificationData,
    userDetailsError,
    verificationDataError,
  ]);

  return userVerification;
};
