import { DismissedBannerCode, UserInvestorTypeEnum, UserVerificationStatus } from './enums';

export interface UserExistsRequest {
  username: string;
  reCaptchaHash?: string; // TODO IN-3115: Make this required field when web is ready to use it
}

export interface UserExistsResponse {
  userExists: boolean;
}

export interface VerifyPhoneNumberRequest {
  accessToken: string;
  smsCode: string;
}

export interface DisableSmsMfaRequest {
  accessToken: string;
  code: string;
}

export interface EnableSmsMfaRequest {
  accessToken: string;
  code: string;
}

export interface CalculateNetWorthRequest {
  totalIncome: number;
  liquidAssets: number;
  regularAnnualExpenditure: number;
}

export interface CalculateNetWorthResponse {
  calculatedTenthOfNetWorth: number;
}

export interface UserSignUpRequest {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  newsletter: boolean;
  referral?: {
    code: string;
    clickId: string | undefined;
  };
  reCaptchaHash?: string; // TODO IN-3115: Make this required field when web is ready to use it
  notificationPreferences: {
    personalizedEmail: boolean;
    personalizedPush: boolean;
    newProjectsMarketingEmail: boolean;
    newProjectsMarketingPush: boolean;
  };
}

export interface LegalEntitySignUpRequest {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  companyId: string;
  referral?: {
    code: string;
    clickId: string | undefined;
  };
  reCaptchaHash?: string; // TODO IN-3115: Make this required field when web is ready to use it

  notificationPreferences: {
    personalizedEmail: boolean;
    personalizedPush: boolean;
    newProjectsMarketingEmail: boolean;
    newProjectsMarketingPush: boolean;
  };
}

export interface UserSignUpResponse {
  intercomUserId: string;
  intercomUserHash: string;
  intercomUserHashIOS: string;
  intercomUserHashAndroid: string;
}

export type UserDeclineReasons = {
  [lang in 'cs' | 'en']: string[];
};

export enum UserLevelType {
  Standard = 'standard',
  Premium = 'premium',
}

export interface UserLevelLastUpdateDetails {
  oldLevel: string;
  newLevel: string;
  createdAt: Date;
}

export interface UserLevelTrialDetails {
  startDate: Date;
  endDate: Date;
  oldLevel: string;
  newLevel: string;
}

export type UserLevel = {
  type: UserLevelType;
  updatedAt: Date;
  currentLevel: string;
  currentLevelLastUpdateInfo?: UserLevelLastUpdateDetails | null;
  currentLevelTrialInfo?: UserLevelTrialDetails | null;
};

export interface DismissBannerRequest {
  bannerCode: DismissedBannerCode;
}

export interface DismissedBanner {
  bannerCode: DismissedBannerCode;
  dismissedAt: Date;
}

export interface UserInvestorType {
  value: UserInvestorTypeEnum;
  updatedAt: Date;
}

export enum VeriffSessionStatus {
  /** Document was approved as valid */
  Approved = 'approved',

  /** User needs to submit the document again */
  ResubmissionRequested = 'resubmission_requested',

  /** User document was declined, new verification is needed */
  Declined = 'declined',

  /** User did not start the verification process and it expired after 7 days */
  Expired = 'expired',

  /** User entered the verification process but he didn't finish it in 7 days */
  Abandoned = 'abandoned',
}

export interface UserDetailsDTO {
  email: string;
  firstName: string;
  lastName: string;
  newsletter: boolean;
  verificationStatus: UserVerificationStatus;
  declineReasons?: UserDeclineReasons | null;
  phoneNumber?: string;
  phoneNumberVerified: boolean;
  madeInvestment: boolean;
  madeDeposit: boolean;
  intercomUserId: string;
  intercomUserHash: string;
  intercomUserHashIOS: string;
  intercomUserHashAndroid: string;
  companyId?: string;
  level: UserLevel;
  dismissedBanners: DismissedBanner[];
  signedUpAt: string;
  investorType: UserInvestorType;
  inflectedName: string;
  latestVeriffSessionStatus?: VeriffSessionStatus;
}

export interface TaxReport {
  year: number;
  link: string;
}

export type TaxReports = TaxReport[];

export interface MerkRawPersonRecord {
  firstName: string;
  lastName: string;
  companyRoleId: number;
  age: string;
}

export interface MerkRawCompanyRecord {
  name: string;
  legalForm: {
    id: number;
  };
  address: CompanyAddress;
  body: {
    average_age: number;
    persons: MerkRawPersonRecord[];
  };
}

export interface CompanyMerkResponse {
  structuredCompanyInfo: MerkRawCompanyRecord;
  persons: MerkRawPersonRecord[];
}

interface CompanyAddress {
  street?: string;
  number?: string;
  numberDescriptive?: string;
  municipality?: string;
  countryCode?: string;
  postalCode?: string;
  lines?: string[];
}

export interface CompanyInfoResponse {
  name?: string;
  regNumber?: string;
  address?: CompanyAddress;
  courtFileNumber?: string;
  courtName?: string;
}

export type InvestmentThresholdResponse =
  | {
      showAlertThreshold: number;
      hasToPassAdditionalSuitabilityQuestionnaireThreshold?: never;
      latestUserFinancialDataDate?: Date;
    }
  | {
      showAlertThreshold?: never;
      hasToPassAdditionalSuitabilityQuestionnaireThreshold: number;
      latestUserFinancialDataDate?: Date;
    };

export type UserTimestampsResponse = {
  investorQuestionnairePartOne: {
    startedAt: Date | null;
    endedAt: Date | null;
  };
  investorQuestionnairePartTwo: {
    startedAt: Date | null;
    endedAt: Date | null;
  };
  abilityToBearLossQuestionnaire: {
    startedAt: Date | null;
    endedAt: Date | null;
  };
};

export interface UserLevelConfigDTO {
  id: string; // represents the name, e.g. "starter" or "premium_1"
  translations: { [langCode: string]: string };
  isPremium: boolean; // is part of premium program
  order: number; // int, starts from 0
  portfolioSizeFrom: number; // inclusive, int, min 0
  bonusYield: number; // not null, example: 0.002 or 0, positive or zero
  vipProjectsAccess: boolean;
  externalBenefits: Array<{
    translations: { [langCode: string]: string };
    name: string; // (string, can come a new unknown)
  }>;
}

export interface UserLevelConfigExternalBenefitDescriptionsDTO {
  name: string; // represents the name, e.g. "starter" or "premium_1"
  translations: { [langCode: string]: string };
}

export interface UserLevelsConfigDTO {
  levels: UserLevelConfigDTO[];
  externalBenefitDescriptions: UserLevelConfigExternalBenefitDescriptionsDTO[];
  banners: {
    nextLevelWaitingPortfolioThreshold: number;
  };
}
