export function createImageResizeUrl({
  originalUrl,
  height,
  width,
}: {
  originalUrl: string;
  height: number;
  width: number;
}): string {
  const { protocol, host, pathname } = new URL(originalUrl);

  return `${protocol}//${host}/${width}x${height}${pathname}`;
}
