/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { UserDetailsDTO } from '../../../../api-sdk/src/lib/users/model';

import * as InvestownEvents from './actions';

export type ScreenViewPayload = Record<string, any>;

export type InvestownActionNames = keyof typeof InvestownEvents;
export type InvestownActionCreators = typeof InvestownEvents;

export interface AnalyticsService {
  init?(): void;
  setUser?(payload: UserDetailsDTO): void;
  trackEvent<T extends InvestownActionNames>(event: ReturnType<InvestownActionCreators[T]>): void;
  trackScreenView?(payload: ScreenViewPayload): void;
  resetUser?(): void;
}

export type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: NonNullable<Type[Key]>;
};

export function createAnalyticsService(
  analyticsIntegrations: AnalyticsService[]
): WithoutNullableKeys<AnalyticsService> {
  const integrations = analyticsIntegrations;

  function hasMethod(analyticsIntegration: AnalyticsService, methodName: keyof AnalyticsService): boolean {
    return (
      Object.hasOwnProperty.call(analyticsIntegration, methodName) &&
      typeof analyticsIntegration[methodName] === 'function'
    );
  }

  function call(
    methodName: keyof AnalyticsService,
    param?: ScreenViewPayload | UserDetailsDTO | ReturnType<InvestownActionCreators[InvestownActionNames]>
  ): void {
    integrations.forEach((analyticsIntegration: AnalyticsService) => {
      if (hasMethod(analyticsIntegration, methodName)) {
        // @ts-ignore TS doesn't understand that we've checked for the method
        analyticsIntegration[methodName](param);
      }
    });
  }

  return {
    setUser: (payload) => {
      call('setUser', payload);
    },
    trackEvent: (event) => {
      call('trackEvent', event);
    },
    trackScreenView: (payload) => {
      call('trackScreenView', payload);
    },
    init: () => {
      call('init');
    },
    resetUser: () => {
      call('resetUser');
    },
  };
}
