import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { queryClient } from '@investown/fe/api-sdk';

import { userIsIdle, userIsActive } from './actions';

function* disableQueriesRefetchingInterval(): SagaIterator {
  yield call([queryClient, queryClient.setDefaultOptions], {
    queries: {
      refetchInterval: false,
    },
  });
}

function* enableQueriesRefetchingInterval(): SagaIterator {
  yield call([queryClient, queryClient.setDefaultOptions], {}); // set defaults to empty object return defaults to their original state
}

export function* watchUserIsIdle(): SagaIterator {
  yield takeEvery(userIsIdle, disableQueriesRefetchingInterval);
}

export function* watchUserIsActive(): SagaIterator {
  yield takeEvery(userIsActive, enableQueriesRefetchingInterval);
}

export default function* root(): SagaIterator {
  yield all([fork(watchUserIsIdle), fork(watchUserIsActive)]);
}
