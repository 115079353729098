import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { AbilityToBearLossQuestionnaireSubmitDTO, InvestorQuestionnaireSubmitResponse } from './model';

export async function submitAbilityToBearLossQuestionnaire(
  body: AbilityToBearLossQuestionnaireSubmitDTO
): Promise<InvestorQuestionnaireSubmitResponse> {
  return apiPost<InvestorQuestionnaireSubmitResponse, AbilityToBearLossQuestionnaireSubmitDTO>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.AbilityToBearLossQuestionnaireSubmit,
    body,
    true
  );
}
