import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { WithdrawalFromInvestmentBody, WithdrawalFromInvestmentResponse } from './model';

export async function requestWithdrawalFromInvestment({
  transactionId,
  investmentAmount,
  investmentRoundId,
}: WithdrawalFromInvestmentBody): Promise<WithdrawalFromInvestmentResponse> {
  return apiPost<WithdrawalFromInvestmentResponse, WithdrawalFromInvestmentBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.WithdrawFromInvestment,
    { transactionId, investmentRoundId, investmentAmount },
    true
  );
}
