import React, { FC } from 'react';

import { UndrawImages } from 'constants/UndrawImages';
import StatusPage from 'components/Layout/StatusPage';

const ErrorPage: FC = () => (
  // TODO: Connect this component to IntlProvider or somehow make it translatable
  <StatusPage
    heading="Ooops, něco se pokazilo."
    buttonText="Zkusit znovu"
    onClick={() => window.location.reload()}
    undrawImage={UndrawImages.ServerDown}
  />
);

export default ErrorPage;
