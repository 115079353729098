import { API } from '@aws-amplify/api';
import { GraphQLClient } from 'graphql-request';
import { Logger } from '@aws-amplify/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { getJwtToken } from '../../../common-utils/src/lib/auth/token';

import { ApiNames, ApiPath } from './enums';
import { createApiErrorFromAmplifyError, isAmplifyResponseApiError, isAmplifyResponseGeneralError } from './utils';
import { AmplifyResponseGeneralError } from './model';
import { ApiError } from './ApiError';

let acceptLanguage: string | null;
const logger = new Logger('auth', 'ERROR');

export function init(options: { acceptLanguage: string | null; apiUrl: string }): void {
  acceptLanguage = options.acceptLanguage;
  API.configure({
    endpoints: [
      {
        name: ApiNames.MonorepoApiEndpoint,
        endpoint: options.apiUrl,
      },
    ],
  });
}

export function setAcceptLanguage(options: { acceptLanguage: string }): void {
  acceptLanguage = options.acceptLanguage;
}

function decideErrorToThrow(err: Error): ApiError | AmplifyResponseGeneralError | Error {
  logger.error(err);
  if (isAmplifyResponseGeneralError(err)) {
    if (isAmplifyResponseApiError(err)) {
      return createApiErrorFromAmplifyError(err); // Error with information from our API
    }
    return err; // Probably error coming from other layer (API Gateway)
  }
  return err; // SettingsOther unexpected errors
}

export async function createHeaders(options: { authenticated: boolean }): Promise<Record<string, string>> {
  const headers: Record<string, string> = {};
  if (acceptLanguage) {
    headers['Accept-Language'] = acceptLanguage;
  }
  if (options.authenticated) {
    headers.Authorization = `Bearer ${await getJwtToken()}`;
  }
  return headers;
}

export async function apiGet<Response = unknown, RequestParams = unknown>(
  apiName: ApiNames,
  path: ApiPath | string,
  authenticated: boolean,
  queryStringParameters?: RequestParams,
  responseType?: string
): Promise<Response> {
  try {
    const headers = await createHeaders({ authenticated });
    return await API.get(apiName, path, { queryStringParameters, headers, responseType });
  } catch (err) {
    /*
     * Under the hood the API category utilizes Axios to execute the HTTP requests.
     * API status code response > 299 are thrown as an exception.
     * If you need to handle errors managed by your API, work with the error.response object.
     */
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw decideErrorToThrow(err as Error);
  }
}

export async function apiPost<Response = unknown, RequestBody = unknown>(
  apiName: ApiNames,
  path: ApiPath,
  body: RequestBody,
  authenticated: boolean
): Promise<Response> {
  try {
    const headers = await createHeaders({ authenticated });
    return await API.post(apiName, path, {
      body,
      headers,
    });
  } catch (err) {
    /*
     * Under the hood the API category utilizes Axios to execute the HTTP requests.
     * API status code response > 299 are thrown as an exception.
     * If you need to handle errors managed by your API, work with the error.response object.
     */
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw decideErrorToThrow(err as Error);
  }
}

export async function createGraphQLClient({
  endpoint,
  authenticated,
}: {
  endpoint: string;
  authenticated: boolean;
}): Promise<GraphQLClient> {
  const headers = await createHeaders({ authenticated });
  return new GraphQLClient(endpoint, { headers });
}
