import React, { FC } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import Topbar from 'components/V2/Topbar/Topbar';
import App from 'routes/index';
import { ConditionalWrapper } from 'components/V2/Topbar/ConditionalWrapper';
import Footer from 'components/V2/Footer/Footer';

const { Content } = Layout;

const MainApp: FC = () => (
  <Layout className="gx-app-layout">
    <ContentWrapper className="gx-layout-content">
      <ConditionalWrapper>
        <Topbar />
      </ConditionalWrapper>
      <App />
      <ConditionalWrapper>
        <Footer />
      </ConditionalWrapper>
    </ContentWrapper>
  </Layout>
);

// Adding the margin from bottom so Footer will fit better and not cover the content
const ContentWrapper = styled(Content)`
  margin-bottom: 66px;
`;
export default MainApp;
