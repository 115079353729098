import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

/** Monetary amount value and currency */
export type FloatMonetaryAmountObjectType = {
  __typename?: 'FloatMonetaryAmountObjectType';
  value: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  generateUserReferral: UserReferral;
};

export type Query = {
  __typename?: 'Query';
  hasPermissionToInviteThroughReferral: Scalars['Boolean'];
  userReferralConditions: UserReferralConditions;
};

export type UserReferral = {
  __typename?: 'UserReferral';
  id: Scalars['ID'];
  /** Referral code */
  code: Scalars['String'];
  /** Bonus amount */
  bonus: FloatMonetaryAmountObjectType;
  /** Minimum investment amount to get bonus */
  minimumInvestment: FloatMonetaryAmountObjectType;
  userId: Scalars['ID'];
  /** Date when the referral code expires */
  expiresAt: Scalars['Timestamp'];
};

export type UserReferralConditions = {
  __typename?: 'UserReferralConditions';
  /** Bonus amount */
  bonus: FloatMonetaryAmountObjectType;
  /** Minimum investment amount to get bonus */
  minimumInvestment: FloatMonetaryAmountObjectType;
};

export type GenerateUserRefferalMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateUserRefferalMutation = { __typename?: 'Mutation' } & {
  generateUserReferral: { __typename?: 'UserReferral' } & Pick<UserReferral, 'code' | 'expiresAt'> & {
      bonus: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
        FloatMonetaryAmountObjectType,
        'currency' | 'value'
      >;
      minimumInvestment: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
        FloatMonetaryAmountObjectType,
        'currency' | 'value'
      >;
    };
};

export type HasPermissionToInviteThroughReferralQueryVariables = Exact<{ [key: string]: never }>;

export type HasPermissionToInviteThroughReferralQuery = { __typename?: 'Query' } & Pick<
  Query,
  'hasPermissionToInviteThroughReferral'
>;

export type UserReferralConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserReferralConditionsQuery = { __typename?: 'Query' } & {
  userReferralConditions: { __typename?: 'UserReferralConditions' } & {
    bonus: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<FloatMonetaryAmountObjectType, 'value' | 'currency'>;
    minimumInvestment: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
      FloatMonetaryAmountObjectType,
      'value' | 'currency'
    >;
  };
};

export const GenerateUserRefferalDocument = gql`
  mutation GenerateUserRefferal {
    generateUserReferral {
      code
      expiresAt
      bonus {
        currency
        value
      }
      minimumInvestment {
        currency
        value
      }
    }
  }
`;
export const HasPermissionToInviteThroughReferralDocument = gql`
  query HasPermissionToInviteThroughReferral {
    hasPermissionToInviteThroughReferral
  }
`;
export const UserReferralConditionsDocument = gql`
  query UserReferralConditions {
    userReferralConditions {
      bonus {
        value
        currency
      }
      minimumInvestment {
        value
        currency
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GenerateUserRefferal(
      variables?: GenerateUserRefferalMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GenerateUserRefferalMutation> {
      return withWrapper(() =>
        client.request<GenerateUserRefferalMutation>(GenerateUserRefferalDocument, variables, requestHeaders)
      );
    },
    HasPermissionToInviteThroughReferral(
      variables?: HasPermissionToInviteThroughReferralQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<HasPermissionToInviteThroughReferralQuery> {
      return withWrapper(() =>
        client.request<HasPermissionToInviteThroughReferralQuery>(
          HasPermissionToInviteThroughReferralDocument,
          variables,
          requestHeaders
        )
      );
    },
    UserReferralConditions(
      variables?: UserReferralConditionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserReferralConditionsQuery> {
      return withWrapper(() =>
        client.request<UserReferralConditionsQuery>(UserReferralConditionsDocument, variables, requestHeaders)
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
