import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import rootReducer from 'appRedux/store/rootReducer';
import rootSaga from 'appRedux/store/rootSaga';

const { routerMiddleware, createReduxHistory } = createReduxHistoryContext({ history: createBrowserHistory() });
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewares = [routerMiddleware, sagaMiddleware];
const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;

const initialState = undefined;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const store = createStore(
  rootReducer(),
  initialState,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
);
const history = createReduxHistory(store);
// then run the saga
sagaMiddleware.run(rootSaga);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const nextRootReducer = require('./rootReducer');
    store.replaceReducer(nextRootReducer(history));
  });
}

export { history, store };
