import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';

import { QueryKeys } from '../enums';
import { queryStaleTimes } from '../reactQueryConfig';
import { calculateNetWorth } from '../users';

import { getInvestorQuestionnaireForLang } from './getInvestorQuestionnaireForLang';
import {
  InvestorQuestionnaireAnswer,
  InvestorQuestionnaireQuestion,
  InvestorQuestionnaireSubmitQuestionAnswer,
  UseInvestorQuestionnaire,
  UseInvestorQuestionnaireProps,
  InvestorQuestionnaireQuestionType,
  SubmitAnswerPayload,
  BooleanAnswer,
  NumberAnswer,
} from './model';
import { getAbilityToBearLossQuestionnaireDefinition } from './getAbilityToBearLossQuestionnaireDefinition';
import { submitAbilityToBearLossQuestionnaire } from './submitAbilityToBearLossQuestionnaire';

// Temporary add an extra question with consent boolean
const consentQuestion = {
  id: 'consent_screen',
  questionConfig: {
    type: 'BOOLEAN_INPUT' as InvestorQuestionnaireQuestionType.BooleanInput,
  },
  questionText: '',
  tooltipText: '',
};

export function useAbilityToBearLossQuestionnaire({
  language,
  submitGaEvent,
}: UseInvestorQuestionnaireProps): UseInvestorQuestionnaire & {
  isConsent: boolean | undefined;
  isNetWorthLoading: boolean;
  netWorth: number | undefined;
} {
  const [progress, setProgress] = useState<number>(1);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const [questionnaire, setQuestionnaire] = useState<InvestorQuestionnaireQuestion[]>([]);
  const [actualQuestion, setActualQuestion] = useState<InvestorQuestionnaireQuestion | undefined>(undefined);
  const [questionnaireVersion, setQuestionnaireVersion] = useState<number>(0);
  const [answers, setAnswers] = useState<InvestorQuestionnaireSubmitQuestionAnswer[]>([]);
  const [lastAnswer, setLastAnswer] = useState<{
    questionId: string;
    type: InvestorQuestionnaireQuestionType;
    answer: InvestorQuestionnaireAnswer;
  } | null>(null);
  const [isConsent, setIsConsent] = useState<boolean | undefined>(undefined);

  const { data: definition, isLoading: isDefinitionLoading } = useQuery(
    [QueryKeys.AbilityToBearLossQuestionnaire, language],
    () => getAbilityToBearLossQuestionnaireDefinition(),
    {
      staleTime: queryStaleTimes.infinite,
    }
  );

  const { isLoading: isNetWorthLoading, mutate: getNetWorth, data: netWorthData } = useMutation(calculateNetWorth);
  const {
    data: submitResponseData,
    mutate: submit,
    isLoading: isSubmitLoading,
  } = useMutation(QueryKeys.AbilityToBearLossQuestionnaireSubmit, submitAbilityToBearLossQuestionnaire);

  /** parse response and handle only requested language */
  useEffect(() => {
    if (definition && questionnaireVersion !== definition.version) {
      const { version, questions } = getInvestorQuestionnaireForLang(definition, language);
      questions.push(consentQuestion);
      setQuestionnaire(questions);
      setQuestionnaireVersion(version);
      setProgress(1);
      setNumberOfQuestions(questions.length);
    }
  }, [
    definition,
    questionnaireVersion,
    setQuestionnaire,
    language,
    setQuestionnaireVersion,
    setProgress,
    setNumberOfQuestions,
  ]);

  /** set actual question */
  useEffect(() => {
    setActualQuestion(questionnaire[progress - 1]);
  }, [progress, setActualQuestion, questionnaire]);

  const goBack = useCallback(() => {
    if (progress > 1) {
      setProgress((oldProgress) => oldProgress - 1);
      setLastAnswer(null);
      actualQuestion &&
        submitGaEvent &&
        submitGaEvent({
          questionId: actualQuestion.id,
          answer: '',
        });
    }
  }, [progress, setProgress, setLastAnswer, submitGaEvent, actualQuestion]);

  const submitAnswer = useCallback(
    (payload: SubmitAnswerPayload) => {
      // isEqual condition prevents duplicate (double-click) answers to the same question
      if (!isEqual(payload, lastAnswer)) {
        setLastAnswer(payload);
        const newAnswers = [
          ...answers.filter((oldAnswer) => oldAnswer.questionId !== payload.questionId), // answer must be replaced if user moved back and fills it in again
          payload,
        ];
        setAnswers(newAnswers);

        const consentQuestionAnswer = newAnswers.find((item) => item.questionId === 'consent_screen');
        const consent = consentQuestionAnswer ? (consentQuestionAnswer?.answer as BooleanAnswer)?.value : undefined;
        setIsConsent(consent);
        if (progress === numberOfQuestions - 1) {
          getNetWorth({
            totalIncome:
              (answers.find((item) => item.questionId === 'total_income')?.answer as NumberAnswer)?.value ?? 0,
            liquidAssets:
              (answers.find((item) => item.questionId === 'liquid_assets')?.answer as NumberAnswer)?.value ?? 0,
            regularAnnualExpenditure: (payload.answer as NumberAnswer).value,
          });
        }
        if (progress === numberOfQuestions && consent !== undefined) {
          // remove consent question from answers so BE can handle submit data
          const originalNewAnswers = newAnswers.filter((item) => item.questionId !== 'consent_screen');
          submit({ consent, submittedQuestionnaire: { version: questionnaireVersion, answers: originalNewAnswers } });
        } else {
          setProgress((oldProgress) => oldProgress + 1);
        }
      }
    },
    [lastAnswer, answers, progress, numberOfQuestions, getNetWorth, submit, questionnaireVersion]
  );

  if (!isDefinitionLoading && !isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: false,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData,
      isConsent,
      isNetWorthLoading,
      netWorth: netWorthData?.calculatedTenthOfNetWorth,
    };
  }

  if (!isDefinitionLoading && isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: true,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData: undefined,
      isConsent,
      isNetWorthLoading,
      netWorth: netWorthData?.calculatedTenthOfNetWorth,
    };
  }

  return {
    isDefinitionLoading: true,
    isSubmitLoading: false,
    submitResponseData: undefined,
    isConsent,
    isNetWorthLoading: false,
    netWorth: 0,
  };
}
