export function isUrlValid(url: string): boolean {
  try {
    const { protocol } = new URL(url);
    if (protocol === 'https:' && !url.endsWith('.')) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}
