import TagManager from 'react-gtm-module';

export interface GtmUserStatusEvent {
  event: 'userStatus';
  userId: string;
  userStatusData: {
    verified: boolean;
    madeInvestment: boolean;
    madeDeposit: boolean;
  };
}

export enum GTMDataLayers {
  defaultDataLayer = 'dataLayer',
}

export const GTMEvents = {
  OnSignUpComplete: {
    event: 'gaEvent',
    gaEventData: {
      eCat: 'Form',
      eAct: 'Registration',
      eLab: 'Completed',
    },
  },
  OnVerificationComplete: {
    event: 'gaEvent',
    gaEventData: {
      eCat: 'Form',
      eAct: 'Verification',
      eLab: 'Completed',
    },
  },
};

// Google Analytics Enhanced E-Commerce format
export interface GTMEcommercePurchaseObject {
  actionField: {
    id: string; // Transaction ID. Required for purchases and refunds.
    revenue: string; // Total transaction value (incl. tax and shipping), e.g. '35.43' (really is string in docs)
  };
  products?: {
    name: string; // Name is required.
  }[];
}

export const createUserStatusGtmEventObject = ({
  userId,
  verified,
  madeInvestment,
  madeDeposit,
}: {
  userId: string;
  verified: boolean;
  madeInvestment: boolean;
  madeDeposit: boolean;
}): GtmUserStatusEvent => ({
  event: 'userStatus',
  userId,
  userStatusData: {
    verified,
    madeInvestment,
    madeDeposit,
  },
});

/**
 * https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
 */
export function pushEcommercePurchaseObjectToDataLayer({
  purchase,
  currency,
}: {
  purchase: GTMEcommercePurchaseObject;
  currency: string;
}): void {
  // It's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer.
  // Clearing the object will prevent multiple ecommerce events on a page from affecting each other.
  googleTagManager({ ecommerce: null });

  googleTagManager({
    ecommerce: {
      currencyCode: currency,
      purchase,
    },
  });
}

export const googleTagManager = (dataLayerObject?: Record<string, unknown> | GtmUserStatusEvent): void => {
  if (dataLayerObject) {
    TagManager.dataLayer({ dataLayer: dataLayerObject, dataLayerName: GTMDataLayers.defaultDataLayer });
  }
};
