import { Auth, CognitoUser } from '@aws-amplify/auth';

import { SignInResult } from './model';

/** This is used to store authResult object from sign-in to be used MFA code form */
let mfaAuthResult: SignInResult | undefined;

export const MfaStatusQueryKey = 'mfaStatus';

export async function getMfaDeliveryMediums(): Promise<undefined | ('SMS' | 'EMAIL')[]> {
  const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    /*
     * getMFAOptions() can be only used for SMS MFA status and it's the old way; but it is used because of using deprecated Auth.enableSMS() so we are able to control MFA status in AWS console
     * docs: https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#getmfaoptions
     */
    currentUser.getMFAOptions((err, options) => {
      if (err) {
        reject(err);
      }
      if (options && options.length > 0) {
        resolve(options.map((o) => o.DeliveryMedium));
      } else {
        resolve(undefined);
      }
    });
  });
}

export async function isSmsMfaEnabled(): Promise<boolean> {
  const deliveryMediums = await getMfaDeliveryMediums();
  if (deliveryMediums) {
    return deliveryMediums.includes('SMS');
  }
  return false;
}

export function getMfaAuthResult(): SignInResult | undefined {
  return mfaAuthResult;
}

export function setMfaAuthResult(value?: SignInResult): void {
  mfaAuthResult = value;
}

/**
 * Allows this device to be used as a second factor in MFA (skip regular MFA)
 * https://docs.amplify.aws/lib/auth/device_features/q/platform/js/#terminology
 */
export async function setDeviceStatusRemembered(user: CognitoUser): Promise<void> {
  return new Promise((resolve, reject) => {
    user.getCachedDeviceKeyAndPassword(); // required for setDeviceStatusRemembered() https://github.com/aws-amplify/amplify-js/issues/1355
    user.setDeviceStatusRemembered({
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
}
