// We're getting circular dependency error when trying to import from @investown/fe/api-sdk
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BankAccountsQuery } from '../../../../api-sdk/src/lib/core/__generated__/sdk';

export function isForeignAccount(iban: string): boolean {
  return iban.startsWith('CZ') === false;
}

export function formatAccountNumber(account: BankAccountsQuery['VerifiedBankAccounts'][0]): string {
  if (account.iban != null && isForeignAccount(account.iban)) {
    return account.iban;
  }

  if (account.accountNumber && account.bankCode) {
    return `${account.accountNumber}/${account.bankCode}`;
  }

  return account.accountNumber!;
}
