import { ApiErrorCode } from './enums';

/**
 * List of api error codes which are caused by the client (user). These should not be retried.
 */
export const clientErrorCodes: string[] = [ApiErrorCode.NotFound];

export const defaultCurrency = 'CZK';

export const AUTOINVEST_STRATEGY_SCHEMA_VERSION = 1;
