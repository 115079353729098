import React, { ComponentType } from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';

import App from './App';
import * as serviceWorker from './serviceWorker';

import * as bootstrap from 'bootstrap';
import { store } from 'appRedux/store';

const render = (Component: ComponentType): void => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <AppContainer>
      <Component />
    </AppContainer>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

bootstrap.init({ store }).then(() => {
  render(App);
});

// webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
