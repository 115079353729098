import { Auth } from '@aws-amplify/auth';
import pLimit from 'p-limit';
import { Logger } from '@aws-amplify/core';

import { SentryLogging } from './sentryLogging';

/**
 * Prevents calling Cognito HTTP API multiple times in one moment.
 * This can happen while rendering a screen with data from multiple APIs (results in multiple getJwtToken() calls).
 * If tokens need to be refreshed, only the first getJwtToken() call initiates (slow) communication with Cognito, later calls get the cached result.
 */
const limit = pLimit(1);
const logger = new Logger('token', 'ERROR');

export async function getJwtToken(): Promise<string> {
  try {
    return (await limit(() => Auth.currentSession())).getIdToken().getJwtToken();
  } catch (error) {
    SentryLogging.logError('getJwtToken ERROR:', error as Error);
    logger.error('getJwtToken ERROR:', error);
    await Auth.signOut().catch((err) => {
      logger.error('Auth.signOut() ERROR', err);
    });
    throw error;
  }
}
