import type { DismissBannerRequest, DismissedBannerCode, UserDetailsDTO } from '../index';
import { apiPost } from '../../api';
import { ApiNames, ApiPath } from '../../enums';

export function wasBannerDismissed({
  userDetails,
  bannerCode,
}: {
  userDetails: UserDetailsDTO;
  bannerCode: DismissedBannerCode;
}): boolean {
  return (
    userDetails.dismissedBanners && userDetails.dismissedBanners.some((banner) => banner.bannerCode === bannerCode)
  );
}

export async function dismissBanner({ bannerCode }: { bannerCode: DismissedBannerCode }): Promise<void> {
  await apiPost<unknown, DismissBannerRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UsersDismissBanner,
    { bannerCode },
    true
  );
}

// If banner is not dismissed, returns null
export function getDismissedBannerDismissTime({
  userDetails,
  bannerCode,
}: {
  userDetails: UserDetailsDTO;
  bannerCode: DismissedBannerCode;
}): Date | undefined {
  const dismissedBanner = userDetails.dismissedBanners.find((banner) => banner.bannerCode === bannerCode);
  return dismissedBanner ? dismissedBanner.dismissedAt : undefined;
}

// If event was triggered after dismissing banner, return true
export function shouldPremiumBannerBeShownAgain({
  userLevelChangedDateTime,
  dismissDateTime,
}: {
  userLevelChangedDateTime: Date | undefined;
  dismissDateTime: Date | undefined;
}): boolean {
  // Event happened (upgrade/downgrade)
  if (userLevelChangedDateTime) {
    // Event happened, banner wasn't dismissed
    if (!dismissDateTime) {
      return true;
    }
    // Event happened, banner was dismissed, but before event happened
    if (dismissDateTime < userLevelChangedDateTime) {
      return true;
    }
  }
  // Event didn't happen, banner wasn't dismissed
  return false;
}
