import React from 'react';
import { connect } from 'react-redux';
import { ConfigCatProvider } from 'configcat-react';
import { PollingMode, User } from 'configcat-common';

import { BasicUserInfo } from '@investown/fe/common-utils';

import { RootState } from '../appRedux/store/rootReducer';
import * as authSelectors from '../appRedux/auth/selectors';

type ConfigCatProviderProps = {
  sdkKey: string;
  children: React.ReactNode;
};

interface StateProps {
  cognitoUser: BasicUserInfo | undefined;
}

const FeatureFlagsProvider: React.FC<StateProps & ConfigCatProviderProps> = ({ sdkKey, children, cognitoUser }) => (
  <ConfigCatProvider
    sdkKey={sdkKey}
    pollingMode={PollingMode.AutoPoll}
    options={{
      pollIntervalSeconds: 5 * 60,
      defaultUser: new User(cognitoUser?.email ? cognitoUser?.email : 'anonymous', cognitoUser?.email),
    }}
  >
    {children}
  </ConfigCatProvider>
);

const connector = connect<StateProps, void, ConfigCatProviderProps, RootState>((state) => ({
  cognitoUser: authSelectors.getBasicUserInfo(state.auth),
}));
export default connector(FeatureFlagsProvider);
