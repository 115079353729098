import { apiGet } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { InvestorQuestionnaireResponse } from './model';

export async function getInvestorQuestionnairePartTwoDefinition(): Promise<InvestorQuestionnaireResponse> {
  return apiGet<InvestorQuestionnaireResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.InvestorQuestionnairePartTwo,
    true
  );
}
