export const fontSize = {
  display: {
    XXXXL: '58px',
    XXXL: '52px',
    XXL: '46px',
    XL: '41px',
    LG: '36px',
    MD: '32px',
    SM: '29px',
    XS: '26px',
  },
  body: {
    XXL: '23px',
    XL: '20px',
    LG: '18px',
    BASE: '16px',
    SM: '14px',
    XS: '12px',
    XXS: '11px',
  },
  button: {
    LG: '18px',
    BASE: '16px',
    SM: '14px',
    XS: '12px',
  },
  label: {
    LG: '18px',
    BASE: '16px',
    SM: '14px',
    SS: '13px',
    XS: '12px',
  },
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const fontLineHeight = {
  display: {
    XXXXL: '74.24px',
    XXXL: '66.56px',
    XXL: '60.72px',
    XL: '55.76px',
    LG: '49.68px',
    MD: '38.4px',
    SM: '40.6px',
    XS: '37.44px',
  },
  body: {
    XXL: '33.12px',
    XL: '28.8px',
    LG: '28.8px',
    BASE: '25.6px',
    SM: '22.4px',
    XS: '19.2px',
    XXS: '17.6px',
  },
  button: {
    XXL: '24px',
    LG: '18px',
    BASE: '16px',
    SM: '14px',
    XS: '12px',
  },
  label: {
    LG: '21.6px',
    BASE: '19.2px',
    SM: '16.8px',
    SS: '15.6px',
    XS: '14.4px',
  },
};

export const deprecatedFontSize = {
  nano: fontSize.body.XXS,
  mini: fontSize.body.XS,
  small: fontSize.body.SM,
  medium: fontSize.body.BASE,
  large: fontSize.body.LG,
  extraLarge: fontSize.body.XXL,
  huge: fontSize.body.XXL,
  massive: fontSize.display.SM,
};
