import { createGraphQLClient } from '../api';
import { sdkFunctionWrapper } from '../utils';

import { getSdk } from './__generated__/sdk';

let ordersGQLEndpoint: string;

export function initOrdersClient(endpoint: string): void {
  ordersGQLEndpoint = endpoint;
}

/**
 * Returns generated SDK for orders microservice.
 * For instructions how to regenerate the SDK see README in the project root.
 * More info: https://graphql-code-generator.com/docs/plugins/typescript-graphql-request
 */
export async function getOrdersClient(): Promise<ReturnType<typeof getSdk>> {
  if (!ordersGQLEndpoint) {
    throw new Error('Missing ordersGQLEndpoint variable');
  }
  // TODO: Save created GraphQLClient into caches but keep in mind that we need to reset these cache externally.
  return getSdk(await createGraphQLClient({ endpoint: ordersGQLEndpoint!, authenticated: true }), sdkFunctionWrapper);
}
