/* eslint-disable global-require,@typescript-eslint/no-explicit-any */
import { setAcceptLanguage } from '@investown/fe/api-sdk';
import { LocalazyCDN } from '@investown/fe/common-utils';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';

import enLang from './entries/en-US';
import csLang from './entries/cs-CZ';

interface AppLocaleObject {
  messages: { [id: string]: string };
  antd: any;
  locale: string;
}

const AppLocale: { [language: string]: AppLocaleObject } = {
  en: enLang,
  cs: csLang,
};

// https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/cs');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  require('@formatjs/intl-relativetimeformat/locale-data/cs');
}

if (!Intl.NumberFormat || typeof new Intl.NumberFormat().formatToParts !== 'function') {
  require('@formatjs/intl-numberformat/polyfill');
  require('@formatjs/intl-numberformat/locale-data/en');
  require('@formatjs/intl-numberformat/locale-data/cs');
}

const userLocaleStorageKey = 'userLocale';

/** Returns current language code (e.g. "cs" or "en") */
export function getAppLanguageLocalVariable(): string {
  const defaultAppLocale = 'en';
  const appLanguageLocalVariable = localStorage.getItem(userLocaleStorageKey);

  if (appLanguageLocalVariable === '' || !appLanguageLocalVariable) {
    if (['cs', 'sk'].includes(getBrowserLanguage())) {
      return 'cs';
    }
    return defaultAppLocale;
  }

  return appLanguageLocalVariable;
}

export function setAppLanguageLocalVariable(languageCode: string): void {
  setAcceptLanguage({ acceptLanguage: languageCode });
  localStorage.setItem(userLocaleStorageKey, languageCode);
}

export function getCurrentAppLocaleObject(): AppLocaleObject {
  const appLanguage = getAppLanguageLocalVariable();
  if (!appLanguage) {
    throw new Error('App language not initialized');
  }

  return {
    ...AppLocale[appLanguage],
    messages: Object.assign(AppLocale[appLanguage].messages, LocalazyCDN.getTranslations(appLanguage)),
  };
}

function getBrowserLocale(): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en';
}

function getLanguageCodeFromLanguageTag(locale: string): string {
  return locale.split('-')[0];
}

function getBrowserLanguage(): string {
  let userLanguage = getBrowserLocale();
  if (userLanguage.includes('-')) {
    userLanguage = getLanguageCodeFromLanguageTag(userLanguage);
  }

  return userLanguage;
}

export function initLocalStorageLocale(): void {
  const appLanguage = getAppLanguageLocalVariable();
  if (!appLanguage) {
    const userLanguage = getBrowserLanguage();

    if (AppLocale[userLanguage]) {
      return setAppLanguageLocalVariable(userLanguage);
    }

    // if user has set browser language for anything else then cs or en - it set default locale to en
    setAppLanguageLocalVariable('en');
  }
}

export default AppLocale;
