/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMessage = (props) => (
  <FormattedMessage
    id={props.id}
    defaultMessage={props.defaultMessage}
    values={{
      ...props.values,
      b: (chunks) => <b>{chunks}</b>,
      i: (chunks) => <i>{chunks}</i>,
      ul: (chunks) => <ul>{chunks}</ul>,
      li: (chunks) => <li>{chunks}</li>,
    }}
  />
);
export default injectIntl(InjectMessage, {
  withRef: false,
});
