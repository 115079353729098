/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type,@typescript-eslint/no-explicit-any */
import { createAction } from 'typesafe-actions';

import { LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE } from 'constants/ThemeSetting';
import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from 'appRedux/settings/constants';
import { LanguageData } from 'constants/LanguageData';

export function toggleCollapsedSideNav(navCollapsed: any) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width: any) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeType(themeType: any) {
  return { type: THEME_TYPE, themeType };
}

export function setThemeColorSelection(colorSelection: any) {
  return { type: THEME_COLOR_SELECTION, colorSelection };
}

export function onNavStyleChange(navStyle: any) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType: any) {
  return { type: LAYOUT_TYPE, layoutType };
}

export const switchLanguage = createAction(SWITCH_LANGUAGE)<LanguageData['locale']>();
