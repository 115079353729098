import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { queryClient } from '@investown/fe/api-sdk';

import { switchLanguage } from './actions';

import { setAppLanguageLocalVariable } from 'lngProvider';

export function* onSwitchLanguage(): SagaIterator {
  yield takeEvery(switchLanguage, ({ payload }) => {
    setAppLanguageLocalVariable(payload);
  });
  yield call([queryClient, queryClient.clear]);
}

export default function* root(): SagaIterator {
  yield all([fork(onSwitchLanguage)]);
}
