/* eslint-disable @nx/enforce-module-boundaries */
import { IntlShape } from 'react-intl';

import { BasicUserInfoObjectType } from '../../../../api-sdk/src/lib/core/__generated__/sdk';

export function removeWhitespaces(input: string): string {
  return input.replace(/\s/g, '');
}

export function removeNonNumericCharacters(input: string): string {
  return input.replace(/[^0-9]/g, '');
}

/**
 * Sanitizes phone number to keep only tha last 4 digits
 * Example: '+420 603 151 048' => '*********1048'
 */
export function sanitizePhoneNumber(number: string): string {
  return removeWhitespaces(number).replace(/[+\d](?=\d{4})/g, '*');
}

/**
 * Check if given number is string
 */
export const checkNumberString = (
  _rule: unknown,
  value: unknown,
  callback: (arg0: string | undefined) => void
): void => {
  const number = Number(value);

  if (!Number.isInteger(number) || number < 1) {
    callback('The number should be an positive integer');
  }
};

/**
 * Convert string to number type.
 */
export const convertStringToNumber = (e: { currentTarget: { value: string } }, maxLength = 0): undefined | number => {
  const convertedValue = Number(e.currentTarget.value.trim());

  if (e.currentTarget.value.trim() === '') {
    return undefined;
  }

  // if you set the limit, string wont be converted if it is bigger than limit. It simulates maxLength rule.
  if (maxLength && convertedValue > maxLength) {
    return Number(e.currentTarget.value.slice(0, -1));
  }

  if (isNaN(convertedValue)) {
    return undefined;
  }

  return convertedValue;
};

/**
 * Convert currency ISO code to proper translation.
 */
export const translateCurrency = (intl: IntlShape, currency: string): string => {
  const formatter = new Intl.NumberFormat(intl.locale, { style: 'currency', currency });
  const currencyPart = formatter.formatToParts(0).find(({ type }) => type === 'currency');
  return currencyPart ? currencyPart.value : currency;
};

/**
 * Sanitize email to all lower case.
 */
export const sanitizeEmail = (email: string): string => email.toLowerCase().trim();

/**
 * SettingsOther possible parameters can be found here
 * https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
 */
export const getQRPaymentString = (iban: string, variableSymbol: string, note: string, amount?: string): string =>
  `SPD*1.0*ACC:${iban}*CC:CZK*X-VS:${variableSymbol}*MSG:${note}${amount ? `*AM:${amount}` : ''}`;

export const removeSpaces = (string: string): string => string.replace(/\s/g, '');

export const getDepositNote = (): string => `Vklad Investown`;

export function formatAddress({
  street,
  city,
  zip,
  countryIsoCode,
}: {
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  countryIsoCode?: string | null;
}): string | null {
  if (street && city && zip && countryIsoCode) {
    const formattedZip = countryIsoCode === 'CZ' && zip.length >= 3 ? `${zip.substr(0, 3)} ${zip.substr(3)}` : zip;
    return `${street}, ${city}, ${formattedZip}, ${countryIsoCode}`;
  }
  return null;
}

// In app we have intl.locale === 'cs' but on web we have 'cs-CZ'. So this function returns only first two letters without specifying language variant.
export function intlLocaleCrossPlatform(locale: string): string {
  return locale.slice(0, 2);
}

export function getSellerNameForSecondaryMarketOffers(secondaryMarketItemSeller: BasicUserInfoObjectType): string {
  return secondaryMarketItemSeller.companyName
    ? secondaryMarketItemSeller.companyName
    : `${secondaryMarketItemSeller.firstName} ${secondaryMarketItemSeller.lastName}`;
}
