import { call, all, spawn } from 'redux-saga/effects';

import userInteractionSaga from '../userInteraction/sagas';

import authSaga from 'appRedux/auth/sagas';
import marketingIntegrationsSaga from 'appRedux/marketingIntegrations/sagas';
import investmentsSaga from 'appRedux/investment/sagas';
import secondaryMarketSaga from 'appRedux/secondaryMarket/sagas';
import settingsSaga from 'appRedux/settings/sagas';
import onboardingSaga from 'appRedux/onboarding/sagas';
import autoinvestSaga from 'appRedux/autoinvest/sagas';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default function* rootSaga() {
  // Here you will aggregate all sagas you want to be running
  const sagas = [
    authSaga,
    marketingIntegrationsSaga,
    userInteractionSaga,
    investmentsSaga,
    secondaryMarketSaga,
    settingsSaga,
    onboardingSaga,
    autoinvestSaga,
  ];

  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line func-names
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.warn(e);
          }
        }
      })
    )
  );
}
