import { googleTagManager } from '@investown/fe/common-utils';

/**
 * Custom events help with the tracking of various events on our custom calls
 */
interface CustomEvent {
  name: string;
  category?: string;
}

interface CustomEventData {
  [index: string]: string | number | boolean;
}

export const CustomEvents: Record<string, CustomEvent> = {
  signUpOpen: {
    name: 'sign_up_open',
    category: 'registration',
  },
  signUpStart: {
    name: 'sign_up_start',
    category: 'registration',
  },
  signUpEmailComplete: {
    name: 'sign_up_email_complete',
    category: 'registration',
  },
  signUpNameComplete: {
    name: 'sign_up_name_complete',
    category: 'registration',
  },
  signUpPasswordComplete: {
    name: 'sign_up_password_complete',
    category: 'registration',
  },
  verificationStart: {
    name: 'verification_start',
    category: 'verification',
  },
  verificationAddressComplete: {
    name: 'verification_address_complete',
    category: 'verification',
  },
  verificationPersonalIdentifierComplete: {
    name: 'verification_personal_identifier_complete',
    category: 'verification',
  },
  verificationPhoneComplete: {
    name: 'verification_phone_complete',
    category: 'verification',
  },
  verificationFirstPhaseComplete: {
    name: 'verification_first_phase_complete',
    category: 'verification',
  },
  verificationSecondPhaseStart: {
    name: 'verification_second_phase_start',
    category: 'verification',
  },
  verificationSofComplete: {
    name: 'verification_sof_complete',
    category: 'verification',
  },
  verificationFinishedTermsAndConditions: {
    name: 'verification_tnc_complete',
    category: 'verification',
  },
  verificationPepComplete: {
    name: 'verification_pep_complete',
    category: 'verification',
  },
  verificationDocumentsCaptureStart: {
    name: 'verification_documents_capture_start',
    category: 'verification',
  },
  verificationDocumentsCaptureComplete: {
    name: 'verification_documents_capture_complete',
    category: 'verification',
  },
  verificationQuestionnaireStart: {
    name: 'verification_questionnaire_start',
    category: 'verification',
  },
  verificationQuestionnaireQuestionAnswered: {
    name: 'verification_questionnaire_question_answered',
    category: 'verification',
  },
  verificationQuestionnaireComplete: {
    name: 'verification_questionnaire_complete',
    category: 'verification',
  },
  verificationUpgradeStart: {
    name: 'verification_upgrade_start',
    category: 'verification',
  },
  verificationUpgradeComplete: {
    name: 'verification_upgrade_complete',
    category: 'verification',
  },
};

export const pushCustomEventToGtm = ({ name, category }: CustomEvent, eventDataExtras?: CustomEventData): void => {
  const eventData = (eventDataExtras || category) && {
    ...(eventDataExtras && { ...eventDataExtras }),
    ...(category && { category }),
  };

  try {
    googleTagManager({
      event: name,
      ...(eventData && { eventData }),
    });
  } catch (e) {
    console.warn(e);
  }
};
