import { useMediaQuery } from 'react-responsive';

import { LocalStorageKeys } from '../constants/LocalStorageKeys';
import { overlayForMobileViewportResetTimeInHours } from '../constants/OverlayForMobileViewportResetTimeInHours';
import { PATH_CHANGE_PASSWORD, PATH_FORGOTTEN_PASSWORD, PATH_MOBILE, PATH_RESET_PASSWORD } from '../routes/routesPaths';

interface Props {
  screenWidthInPixels: string;
  onShowMobileScreen: () => void;
  actualLocation: string;
}

const useMobileScreenRedirect = ({ screenWidthInPixels, actualLocation, onShowMobileScreen }: Props): void => {
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: screenWidthInPixels });
  const isAlreadyOnMobileScreen = actualLocation === PATH_MOBILE;
  const mobileScreenClosedTime = localStorage.getItem(LocalStorageKeys.MobileScreenClosedTime);
  const excludedPaths = [PATH_CHANGE_PASSWORD, PATH_FORGOTTEN_PASSWORD, PATH_RESET_PASSWORD];

  if (
    isMobileDevice &&
    !isAlreadyOnMobileScreen &&
    !excludedPaths.includes(actualLocation) &&
    !actualLocation.includes('referral')
  ) {
    if (mobileScreenClosedTime) {
      const currentTime = new Date().getTime();
      const closedTimePeriod = overlayForMobileViewportResetTimeInHours * 60 * 60 * 1000;

      if (currentTime - parseInt(mobileScreenClosedTime, 10) > closedTimePeriod) {
        // Modal was closed within time limit
        onShowMobileScreen();
      }
    } else {
      onShowMobileScreen();
    }
  }
};

export default useMobileScreenRedirect;
