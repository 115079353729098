import React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import clamp from 'lodash/clamp';

import { WithChildren } from '@investown/fe/common-utils';

interface ProgressBarProps extends WithChildren {
  size: number;
  progress: number; // A value between 0 and 100
  progressColor: string;
  backgroundColor: string;
  strokeWidth: number;
  withLinearGradient?: boolean;
}

const ProgressBarCircle: React.FC<ProgressBarProps> = ({
  size,
  progress,
  progressColor,
  backgroundColor,
  strokeWidth,
  withLinearGradient,
  children,
}) => {
  const radius = (size - strokeWidth) / 2; // Radius of the inner circle
  const circumference = radius * 2 * Math.PI; // Circumference of the inner circle

  // Normalize the progress value to be between 0 and 100
  const normalizedProgress = clamp(progress, 0, 100);
  const normalizedBigProgress = Big(normalizedProgress);
  const progressPercentage = normalizedBigProgress.div(100).toNumber();

  // The stroke dash array and offset create the effect of the progress bar
  const strokeDasharray = circumference.toFixed(2);
  const strokeDashoffset = (circumference - progressPercentage * circumference).toFixed(2);

  return (
    <Svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
      <Circle cx={size / 2} cy={size / 2} r={radius} fill="none" stroke={backgroundColor} strokeWidth={strokeWidth} />
      {withLinearGradient && (
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <Stop offset="0%" stopColor={progressColor} stopOpacity={1} />
            <Stop offset="50%" stopColor={progressColor} stopOpacity={0.65} />
            <Stop offset="100%" stopColor={progressColor} stopOpacity={0.5} />
          </linearGradient>
        </defs>
      )}
      <Circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={withLinearGradient ? 'url(#gradient)' : progressColor}
        strokeWidth={strokeWidth}
        style={{
          strokeDasharray,
          strokeDashoffset,
          strokeLinecap: 'round',
          transform: `rotate(-90deg)`,
        }}
      />
    </Svg>
  );
};

const Svg = styled.svg`
  display: block;
  margin: auto;
`;

const Circle = styled.circle`
  transform-origin: center;
  transition: stroke-dashoffset 0.3s ease-in-out;
`;

const Stop = styled.stop<{ stopColor: string }>(({ stopColor }) => ({
  stopColor,
}));

export default ProgressBarCircle;
