import clamp from 'lodash/clamp';
import Big from 'big.js';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  UserLevelConfigDTO,
  UserLevelConfigExternalBenefitDescriptionsDTO,
} from '../../../../api-sdk/src/lib/users/model';
import { intlLocaleCrossPlatform } from '../string/stringUtils';

export function getLevelFromConfig({
  levelId,
  levels,
}: {
  levelId: string | undefined;
  levels: UserLevelConfigDTO[];
}): UserLevelConfigDTO | undefined {
  if (levelId && levels && levels.length > 0) {
    return levels.find((level) => level.id === levelId);
  }
  return undefined;
}

export function getNextLevelFromConfig({
  levelId,
  levels,
}: {
  levelId: string;
  levels: UserLevelConfigDTO[];
}): UserLevelConfigDTO | null | undefined {
  const currentLevel = levels.find((level) => level.id === levelId);

  if (currentLevel) {
    const nextLevelOrder = currentLevel.order + 1;
    const nextLevel = levels.find((level) => level.order === nextLevelOrder);

    return nextLevel || null;
  }

  // If the current level is not found, return undefined
  return undefined;
}

interface TranslateUserLevelProps {
  locale: string;
  toTranslate: {
    translations: { [langCode: string]: string };
  };
  fallbackLocale?: string;
}

export function getExternalBenefitDescriptionTranslation({
  locale,
  benefitName,
  externalBenefitDescriptions,
  fallbackLocale = 'en',
}: {
  locale: string;
  benefitName: string;
  externalBenefitDescriptions: UserLevelConfigExternalBenefitDescriptionsDTO[];
  fallbackLocale?: string;
}): string | undefined {
  const externalBenefitDescription = externalBenefitDescriptions
    ? externalBenefitDescriptions.find((benefit) => benefit.name === benefitName)
    : undefined;

  if (externalBenefitDescription) {
    const translations = getUserLevelTranslations({
      locale,
      toTranslate: externalBenefitDescription,
      fallbackLocale,
    });

    return translations;
  }

  return undefined;
}

export function getUserLevelTranslations({
  locale,
  toTranslate,
  fallbackLocale = 'en',
}: TranslateUserLevelProps): string {
  // Use the current locale if available, otherwise fallback to the specified fallbackLocale
  const translations =
    toTranslate.translations[intlLocaleCrossPlatform(locale)] ?? toTranslate.translations[fallbackLocale];

  return translations;
}

export function calculateLevelProgress({
  currentPortfolioSize,
  nextLevelPortfolioSizeFrom,
  walletSize,
}: {
  currentPortfolioSize: number;
  nextLevelPortfolioSizeFrom: number;
  walletSize?: number; // Pass this props if you want to add walletSize to portfolioSize progress calculation
}): { percentDone: number; remainingAmount: number } {
  // Use clamp to ensure currentPortfolioSize is within the valid range
  const currentWalletSizeBig = walletSize !== undefined && walletSize > 0 ? Big(walletSize) : undefined;
  const adjustedCurrentPortfolioSize =
    currentWalletSizeBig !== undefined
      ? Big(currentPortfolioSize).plus(currentWalletSizeBig)
      : Big(currentPortfolioSize);
  const normalizedProgress = clamp(adjustedCurrentPortfolioSize.toNumber(), 0, nextLevelPortfolioSizeFrom);
  const normalizedBigProgress = Big(normalizedProgress);
  const totalBigProgress = Big(nextLevelPortfolioSizeFrom);

  // Calculate progress percentage
  let progressPercentage = 0;
  if (!totalBigProgress.eq(0)) {
    progressPercentage = normalizedBigProgress.div(totalBigProgress).times(100).toNumber();
  }
  // Calculate remaining amount
  const remainingAmount = totalBigProgress.minus(normalizedBigProgress).toNumber();

  return { percentDone: progressPercentage, remainingAmount };
}

export type LevelProgressType = { percentDone: number; remainingAmount: number; isLastLevel?: boolean } | null;
export function calculateUiLevelProgress({
  currentLevel,
  targetLevelId,
  levels,
  portfolioSize,
  walletSize,
}: {
  currentLevel: string;
  targetLevelId?: string; // Calculate progress to this level, if not provided, calculate to the next level
  levels: UserLevelConfigDTO[];
  portfolioSize: number;
  walletSize?: number; // Count with walletSize + portfolioSize, if not provided, count only with portfolioSize
}): LevelProgressType {
  const nextLevel = targetLevelId
    ? getLevelFromConfig({ levelId: targetLevelId, levels })
    : getNextLevelFromConfig({ levelId: currentLevel, levels });
  if (nextLevel === null) {
    // User has max level
    return { percentDone: 100, remainingAmount: 0, isLastLevel: true };
  }
  if (nextLevel) {
    return calculateLevelProgress({
      currentPortfolioSize: portfolioSize,
      nextLevelPortfolioSizeFrom: nextLevel.portfolioSizeFrom,
      walletSize,
    });
  }
  return null;
}

export enum LevelUpdateType {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  Same = 'same',
}
export function getLevelUpdateType({
  newLevelId,
  oldLevelId,
  levels,
}: {
  newLevelId: string | undefined;
  oldLevelId: string | undefined;
  levels: UserLevelConfigDTO[];
}): LevelUpdateType | undefined {
  const newLevel = getLevelFromConfig({ levelId: newLevelId, levels });
  const oldLevel = getLevelFromConfig({ levelId: oldLevelId, levels });
  if (!newLevel || !oldLevel) {
    return undefined;
  }
  if (newLevel.order > oldLevel.order) {
    return LevelUpdateType.Upgrade;
  }
  if (newLevel.order < oldLevel.order) {
    return LevelUpdateType.Downgrade;
  }
  return LevelUpdateType.Same;
}
