import { IntlShape } from 'react-intl';

/**
 * Translate error of investment
 */
export const translateInvestmentError = (intl: IntlShape, errorCode?: string): string =>
  intl.formatMessage({ id: `errors.investment.${errorCode}` });

/**
 * Translate error of offer investment
 */
export const translateOfferInvestmentError = (intl: IntlShape, errorCode?: string): string =>
  intl.formatMessage({ id: `errors.offerInvestment.${errorCode}` });
