import { AuthOptions } from '@aws-amplify/auth/lib/types';

import CustomCookieStorage from 'auth/CustomCookieStorage';

export const authOptions: AuthOptions = {
  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_COGNITO_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_SRP_AUTH',

  storage: new CustomCookieStorage({
    // OPTIONAL - Cookie expiration in days; use undefined for session cookies (should expire when browser is closed - not always the case!)
    expires: 1,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: process.env.NODE_ENV !== 'development',
  }),
};
