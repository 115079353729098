import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as SvgLogo } from 'assets/images/logos/drfg.svg';

const DRFGLogo: FC<{ className?: string }> = ({ className }) => <StyledLogo className={className} title="DRFG" />;

const StyledLogo = styled(SvgLogo)`
  position: relative;
  width: 70px;
  height: 35px;
`;

export default DRFGLogo;
