import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UserVerificationDataDTO } from './model';

export async function updateVerificationData(verificationData: UserVerificationDataDTO): Promise<void> {
  await apiPost<unknown, UserVerificationDataDTO>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UserVerificationDataUpdate,
    verificationData,
    true
  );
}
