import { LocalDate } from '@js-joda/core';

/**
 * Returns `boolean` value based on validity of the given `birthNumber`
 * according to the rules specified on [ČSSZ website](https://www.cssz.cz/standardni-kontrola-rodneho-cisla-a-evidencniho-cisla-pojistence).
 * It must be in normalized form without `/` or any other non-numeric character.
 */
export const isValidCzechBirthNumber = (birthNumber: string): boolean => {
  if (birthNumber === '') {
    return false;
  }
  if (!/^\d{9,10}$/.test(birthNumber)) {
    return false;
  }
  if (birthNumber.length === 9 && birthNumber.endsWith('000')) {
    return false;
  }
  const year = resolveYear(birthNumber);
  const month = resolveMonth(birthNumber);
  const day = parseInt(birthNumber.substr(4, 2), 10);

  return isValidDate({ year, month, day }) && isBNDivisibleByElevenIfTenDigit(birthNumber);
};

const resolveYear = (birthNumber: string): number => {
  const yearPart = parseInt(birthNumber.substr(0, 2), 10);
  const bNSuffixLength = birthNumber.length === 9 ? 3 : 4;
  const centuryBaseYear = resolveCenturyBaseYear(yearPart, bNSuffixLength);

  return centuryBaseYear + yearPart;
};

const resolveCenturyBaseYear = (yearPart: number, bNSuffixLength: 3 | 4): number => {
  /* 53 is boundary year within birth number.
     Together with length of birth number suffix it determines
     the century of the resolved birth date. */
  if (bNSuffixLength === 3) {
    return yearPart > 53 ? 1800 : 1900;
  }
  // birth number suffix is 4 characters long
  return yearPart > 53 ? 1900 : 2000;
};

const resolveMonth = (birthNumber: string): number => {
  const femaleMonthIncrement = 50;
  const incrementWhenAllBnsWithinDayExhausted = 20;
  let month = parseInt(birthNumber.substr(2, 2), 10);
  if (month > femaleMonthIncrement) {
    month -= femaleMonthIncrement;
  }
  if (month > incrementWhenAllBnsWithinDayExhausted) {
    month -= incrementWhenAllBnsWithinDayExhausted;
  }
  return month;
};

const isValidDate = ({ year, month, day }: { year: number; month: number; day: number }): boolean => {
  try {
    LocalDate.of(year, month, day);
    return true;
  } catch (error) {
    return false;
  }
};

const isBNDivisibleByElevenIfTenDigit = (birthNumber: string): boolean => {
  if (birthNumber.length === 10 && !isDivisibleByEleven(birthNumber)) {
    return false;
  }
  return true;
};

const isDivisibleByEleven = (birthNumber: string): boolean => {
  const birthNumberParsed = parseInt(birthNumber, 10);
  const divisionRemainder = birthNumberParsed % 11;
  return divisionRemainder === 0;
};
