import styled from 'styled-components';

export const ContentWrapper = styled.div<{ fullScreen?: boolean }>`
  /* Disable auto zooming functionality on iphone */
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: ${(props) =>
      props.theme.breakpoints.large}) {
    select,
    textarea,
    input,
    select:focus,
    textarea:focus,
    input:focus {
      font-size: ${(props) => props.theme.font.size.medium};
    }
  }

  ${({ fullScreen, theme }) =>
    !fullScreen
      ? `
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      padding: 0;

      .ant-col {
        padding: 0;
      }
    }
    padding: ${theme.spacing.huge} ${theme.spacing.massive};
    flex: 1;
    max-width: calc(${theme.container.maxWidth} + (2*${theme.spacing.massive}));
    margin: 0 auto;
    min-width: ${theme.breakpoints.extraLarge};
    overflow-x: auto;
    width: 100%;
    height: 100%;
  `
      : ``}
`;
