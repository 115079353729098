interface Locale {
  language: string;
  region: string;
  uri: string;
}

interface Translations {
  [key: string]: string;
}

let translations: { [locale: string]: Translations };

export async function fetchWithTimeout(url: string, options = {}, timeout = 1000): Promise<Response> {
  return (await Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout)),
  ])) as Response;
}

export async function init({
  metaFileURL,
  fileId,
  enable = true,
}: {
  metaFileURL?: string;
  fileId?: string;
  enable?: boolean;
}): Promise<void> {
  if (enable) {
    if (!metaFileURL || !fileId) {
      throw new Error('When Localazy is enabled, you must provide missing metaFileURL or fileId variable');
    }

    const metaFileContent = await fetchWithTimeout(metaFileURL)
      .then((r) => r.json())
      .then((r) => r)
      .catch(() => undefined);

    if (metaFileContent?.files[fileId]?.locales) {
      translations = await getAllTranslationFiles(fileId, metaFileContent?.files[fileId]?.locales);
    }
  }
}

export function getTranslations(locale: string): Translations {
  if (translations && translations[locale]) {
    return translations[locale];
  }
  return {};
}

async function getTranslationFile(fileId: string, locale: Locale): Promise<{ [key: string]: string }> {
  return fetchWithTimeout(`https://delivery.localazy.com${locale.uri}`)
    .then((response) => response.json())
    .then((response) => response)
    .catch(() => ({}));
}

async function getAllTranslationFiles(fileId: string, locales: Locale[]): Promise<{ [locale: string]: Translations }> {
  return Object.fromEntries(
    await Promise.all(
      locales.map(async (locale) => {
        const translationFile = await getTranslationFile(fileId, locale);
        return [locale.language, translationFile];
      })
    )
  );
}
