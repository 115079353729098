import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { WithChildren } from '@investown/fe/common-utils';

import { routesConfig } from '../../../routes';

export const ConditionalWrapper: React.FC<WithChildren> = ({ children }) => (
  <Switch>
    {routesConfig.map((route) => (
      <Route path={route.path} exact={route.exact} key={route.path as string}>
        {!route.hideNavbar && children}
      </Route>
    ))}
  </Switch>
);
