import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { CreateUserReferralCodeConnection, CreateUserReferralCodeConnectionResponse } from './model';

export async function createUserReferralCodeConnection(email: string, referralCode: string): Promise<unknown> {
  return apiPost<CreateUserReferralCodeConnectionResponse, CreateUserReferralCodeConnection>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.CreateReferralCodeConnection,
    { email, referralCode },
    false
  );
}
