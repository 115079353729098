import { getUserDetails, queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { IntercomEvents } from 'constants/IntercomEvents';

function getIntercomInstance(): (method: string, args?: unknown) => void {
  if (!window.Intercom) {
    throw new Error('Could not find window.Intercom');
  }
  return window.Intercom;
}

export async function onFirstSignUpStep(email: string): Promise<void> {
  try {
    const Intercom = getIntercomInstance();
    Intercom('update', {
      anonymous_email: email,
    });
    Intercom('trackEvent', IntercomEvents.SignUpInProgress);
  } catch (err) {
    console.error(err);
  }
}

export async function onSignIn(email: string): Promise<void> {
  try {
    // TODO: upgrade to react-query v3 and use QueryClient here to share cache
    const { intercomUserId, intercomUserHash } = await queryClient.fetchQuery(QueryKeys.UserDetails, getUserDetails);
    const Intercom = getIntercomInstance();
    // Shutdown to delete automatic visitor welcome message from messenger (causes 404 when you want to read it)
    Intercom('shutdown');
    // Boot signed in session
    Intercom('boot', { email, user_id: intercomUserId, user_hash: intercomUserHash });
  } catch (err) {
    console.error(err);
  }
}

export function onSignOut(): void {
  try {
    const Intercom = getIntercomInstance();
    // Shutdown signed in session
    Intercom('shutdown');
    // Boot anonymous visitor session
    Intercom('boot');
  } catch (err) {
    console.error(err);
  }
}

export function trackEvent(event: IntercomEvents): void {
  try {
    const Intercom = getIntercomInstance();
    Intercom('trackEvent', event);
  } catch (err) {
    console.error(err);
  }
}
