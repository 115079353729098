import Auth from '@aws-amplify/auth';
import { Store } from 'redux';

import { authOptions } from 'auth/authConfig';
import * as authEventListener from 'auth/authEventsListener';
import { RootState } from 'appRedux/store/rootReducer';

async function checkUserStoredInBrowser(store: Store<RootState>): Promise<void> {
  const currentUser = await Auth.currentAuthenticatedUser().catch(() => undefined); // promise rejects if not authenticated
  if (currentUser) {
    authEventListener.onSignIn({ user: currentUser, store });
  }
}

export async function init(options: { store: Store<RootState> }): Promise<void> {
  Auth.configure(authOptions);
  authEventListener.listen(options.store);
  await checkUserStoredInBrowser(options.store);
}
