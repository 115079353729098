import { useQuery, UseQueryResult } from 'react-query';

import { CompanyInfoResponse, getCompanyInfo } from '../users';
import { refetchIntervals } from '../reactQueryConfig';
import { QueryKeys } from '../enums';

export const useCompanyInfo = (identificationNumber?: string): UseQueryResult<CompanyInfoResponse> =>
  useQuery([QueryKeys.GetCompanyInfo, { identificationNumber }], () => getCompanyInfo(identificationNumber), {
    refetchInterval: refetchIntervals.infinite,
    enabled: !!identificationNumber,
  });
