/* eslint-disable @nx/enforce-module-boundaries */
import { AUTOINVEST_STRATEGY_SCHEMA_VERSION } from '../../../../api-sdk/src/lib/constants';
import * as CoreSDK from '../../../../api-sdk/src/lib/core/__generated__/sdk';

import { calculateMatchingLoansPercentage } from './calculateMatchingLoansPercentage';

function isStrategyDeprecated(
  strategy: CoreSDK.GetLatestAutoinvestStrategyQuery['getLatestAutoinvestStrategy']
): boolean | undefined {
  if (strategy?.parameters.schemaVersion == null) {
    return;
  }

  return strategy?.parameters.schemaVersion < AUTOINVEST_STRATEGY_SCHEMA_VERSION;
}

export { isStrategyDeprecated, calculateMatchingLoansPercentage };
