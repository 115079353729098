/* eslint-disable @nx/enforce-module-boundaries */
import { UserVerificationDataDTO } from '../../../../api-sdk/src/lib/userVerification/model';
import { LegalEntityVerificationVersion, UserVerificationVersion } from '../../../../api-sdk/src/lib/users';

interface NewContractAvailabilityProps {
  userVerificationData?: UserVerificationDataDTO;
  personalEntityExpectedVersion?: UserVerificationVersion;
  legalEntityExpectedVersion?: LegalEntityVerificationVersion;
}
export function getNewContractAvailability({
  userVerificationData,
  personalEntityExpectedVersion = UserVerificationVersion.WithConfirmedContract,
  legalEntityExpectedVersion = LegalEntityVerificationVersion.WithConfirmedContract,
}: NewContractAvailabilityProps): boolean {
  const personalEntityVersion =
    !!userVerificationData?.version && userVerificationData?.version < personalEntityExpectedVersion;
  const legalEntityVersion =
    !!userVerificationData?.companyVerificationVersion &&
    userVerificationData?.companyVerificationVersion < legalEntityExpectedVersion;

  return personalEntityVersion || legalEntityVersion;
}
