import antdCs from 'antd/lib/locale-provider/cs_CZ';

import czMessages from '../locales/cs_CZ.json';

const CZLang = {
  messages: {
    ...czMessages,
  },
  antd: antdCs,
  locale: 'cs-CZ',
};
export default CZLang;
