import usePagination, { PaginationDefaultOptions, UsePaginationProps } from './usePagination';
import useFilter, {
  FilterDefaultOptions,
  UseFilterProps,
  FilterOperator,
  ManagedDataFilterItem as ManagedDataFilterItemType,
} from './useFilter';
import useSortBy, {
  ManagedDataColumn as ManagedDataColumnType,
  SortByDefaultOptions,
  UseSortByProps,
} from './useSortBy';

export type ManagedDataColumn = ManagedDataColumnType;
export type ManagedDataFilterItem<T> = ManagedDataFilterItemType<T>;
export { FilterOperator };

export function useManagedData<TFilterType>({
  columns,
  defaultOptions,
}: {
  columns: string[];
  defaultOptions?: {
    sortBy?: SortByDefaultOptions;
    filter?: FilterDefaultOptions<TFilterType>;
    pagination?: PaginationDefaultOptions;
  };
}): {
  sortBy: UseSortByProps;
  filter: UseFilterProps<TFilterType>;
  pagination: UsePaginationProps;
} {
  const pagination = usePagination(defaultOptions?.pagination);
  const filter = useFilter<TFilterType>(defaultOptions?.filter);
  const sortBy = useSortBy(columns, defaultOptions?.sortBy);

  return { sortBy, filter, pagination };
}
