import { createGraphQLClient } from '../api';
import { sdkFunctionWrapper } from '../utils';

import { getSdk } from './__generated__/sdk';

let affiliateGQLEndpoint: string;

export function initAffiliateClient(endpoint: string): void {
  affiliateGQLEndpoint = endpoint;
}

/**
 * Returns generated SDK for affiliate microservice.
 * For instructions how to regenerate the SDK see README in the project root.
 * More info: https://graphql-code-generator.com/docs/plugins/typescript-graphql-request
 */
export async function getAffiliateClient(): Promise<ReturnType<typeof getSdk>> {
  if (!affiliateGQLEndpoint) {
    throw new Error('Missing affiliateGQLEndpoint variable');
  }

  return getSdk(
    await createGraphQLClient({ endpoint: affiliateGQLEndpoint!, authenticated: true }),
    sdkFunctionWrapper
  );
}
