/* eslint-disable @nx/enforce-module-boundaries */
import { PropertyInvestmentRoundContractVersion } from '../../../../api-sdk/src/lib/core/enums';

export function isProjectWithContractVersion(
  currentVersionNumber: number | undefined,
  targetVersionNumber: PropertyInvestmentRoundContractVersion
): boolean {
  if (currentVersionNumber === undefined) {
    return false;
  }
  if (currentVersionNumber < targetVersionNumber) {
    return false;
  }
  return true;
}
