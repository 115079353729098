import produce from 'immer';
import { useMutation, UseMutationOptions } from 'react-query';

import { QueryKeys } from '../enums';
import { queryClient } from '../reactQueryConfig';
import { dismissBanner, UserDetailsDTO, DismissedBannerCode, DismissBannerRequest } from '../users';

export function useOptimisticDismissBanner(
  bannerCode: DismissedBannerCode,
  mutationOptions: UseMutationOptions<DismissBannerRequest, unknown, DismissBannerRequest> = {}
): {
  dismissAutoinvestBanner: () => void;
} {
  const { mutate } = useMutation(dismissBanner, {
    // https://tanstack.com/query/v3/docs/framework/react/guides/optimistic-updates
    // ...mutationOptions,
    onMutate: async function optimisticUserDetailsUpdate() {
      await queryClient.cancelQueries(QueryKeys.UserDetails);
      const previousData = queryClient.getQueryData<UserDetailsDTO>(QueryKeys.UserDetails);

      queryClient.setQueryData(QueryKeys.UserDetails, (oldData) =>
        produce(oldData as UserDetailsDTO, (draft) => {
          draft.dismissedBanners.push({ bannerCode: DismissedBannerCode.AutoinvestBanner, dismissedAt: new Date() });
        })
      );

      return { previousData };
    },
    onError: (error, variables, context) => {
      if (mutationOptions.onError && typeof mutationOptions.onError === 'function') {
        mutationOptions.onError(error, variables, context);
      }
      queryClient.setQueryData(QueryKeys.UserDetails, context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.UserDetails);
    },
  });

  return {
    dismissAutoinvestBanner: () => mutate({ bannerCode }),
  };
}
