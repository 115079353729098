import { SagaIterator } from 'redux-saga';
import { all, call, fork, takeEvery } from 'redux-saga/effects';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { verificationSubmittedAction } from './actions';

import { clearSessionUrl } from 'components/VeriffOpenButton/utils';

function* resetCaches(): SagaIterator {
  yield all([
    call([queryClient, queryClient.invalidateQueries], QueryKeys.UserVerificationData),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.UserDetails),
    call(clearSessionUrl),
  ]);
}

export function* watchVerificationSubmittedAction(): SagaIterator {
  yield takeEvery(verificationSubmittedAction, resetCaches);
}

export default function* root(): SagaIterator {
  yield all([fork(watchVerificationSubmittedAction)]);
}
