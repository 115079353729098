import { useEffect, useRef } from 'react';

/**
 * Inspired by Dan Abramov's
 * <a href="https://overreacted.io/making-setinterval-declarative-with-react-hooks/">blog post</a>.
 *
 * This hook allows using similar behavior as classic `setInterval`
 * utility.
 * It clears interval automatically after component unmounts.
 * Another advantage over the classic `setInterval` is an ability
 * to change params dynamically.
 */
export const useInterval = (callback: (clearInterval: () => void) => void | Promise<void>, delay: number): void => {
  const savedCallback = useRef<(clearInterval: () => void) => void | Promise<void>>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    async function tick(clearInterval: () => void): Promise<void> {
      if (savedCallback.current) {
        await savedCallback.current(clearInterval);
      }
    }
    if (delay !== null) {
      const id: number = setInterval(() => tick(() => clearInterval(id)), delay) as unknown as number;
      return () => clearInterval(id);
    }
  }, [delay]);
};
