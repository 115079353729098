import React, { FC, useContext } from 'react';
import ReactUndraw from 'react-undraw';
import { ThemeContext } from 'styled-components';

interface UndrawProps {
  name: string;
  height?: string;
}

const Undraw: FC<UndrawProps> = (props) => {
  const theme = useContext(ThemeContext);
  const { name, height } = props;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ReactUndraw name={name} height={height} primaryColor={theme ? theme.color.primary : '#1961FF'} />;
};

export default Undraw;
