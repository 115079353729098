/* eslint-disable no-console */
import React, { createContext, useContext, PropsWithChildren } from 'react';

import { AnalyticsService, WithoutNullableKeys } from './analyticsService';

const AnalyticsContext = createContext<WithoutNullableKeys<AnalyticsService> | undefined>(undefined);

export function AnalyticsProvider({
  analyticsService,
  children,
}: PropsWithChildren<{ analyticsService: WithoutNullableKeys<AnalyticsService> }>): JSX.Element {
  return <AnalyticsContext.Provider value={analyticsService}>{children}</AnalyticsContext.Provider>;
}

export const useAnalytics = (): WithoutNullableKeys<AnalyticsService> => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }

  return context;
};
