import { useQuery, UseQueryResult } from 'react-query';

import { CompanyMerkResponse, getCompanyInfoFromMerk } from '../users';
import { refetchIntervals } from '../reactQueryConfig';
import { QueryKeys } from '../enums';
import { ApiError } from '../ApiError';

export const useCompanyMerkInfo = (identificationNumber: string): UseQueryResult<CompanyMerkResponse, ApiError> =>
  useQuery(
    [QueryKeys.GetMerkCompanyInfo, { identificationNumber }],
    () => getCompanyInfoFromMerk(identificationNumber),
    {
      refetchInterval: refetchIntervals.infinite,
      enabled: !!identificationNumber,
    }
  );
