import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UserExistsRequest, UserExistsResponse } from './model';

// TODO IN-3115: Make reCaptchaHash as required field when web is ready to use it
export async function userExists(username: string, reCaptchaHash?: string): Promise<boolean> {
  const response = await apiGet<UserExistsResponse, UserExistsRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UsersUserExists,
    false,
    { username, reCaptchaHash }
  );
  if (response.userExists === undefined) {
    throw new Error('Invalid response');
  }
  return response.userExists;
}
