// eslint-disable-next-line @nx/enforce-module-boundaries
import { getAccessToken } from '../../../../common-utils/src/lib/auth';
import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { DisableSmsMfaRequest } from './model';

export async function disableSmsMfa({ code }: { code: string }): Promise<void> {
  const accessToken = await getAccessToken();

  await apiPost<unknown, DisableSmsMfaRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UsersSmsMfaDisable,
    { accessToken, code },
    true
  );
}
