import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UserTimestampsResponse } from './model';

export async function getUserTimestamps(): Promise<UserTimestampsResponse> {
  const response = await apiGet<UserTimestampsResponse>(ApiNames.MonorepoApiEndpoint, ApiPath.UserTimestamps, true);

  if (response === undefined) {
    throw new Error('Invalid response');
  }

  return response;
}
