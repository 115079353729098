export enum GuideModalIds {
  Autoinvest = 'autoinvest',
}

type GuideModalsState = 'seen' | 'shouldSee' | 'unseen';

type GuideModalsObject = {
  [key in GuideModalIds]: GuideModalsState;
};

const localStorageKey = 'guideModals';
const initialGuideModalsState: GuideModalsObject = {
  [GuideModalIds.Autoinvest]: 'unseen',
};

// Fetch modal states from localStorage
export const getGuideModalStates = (): GuideModalsObject => {
  const modalStates = localStorage.getItem(localStorageKey);
  return modalStates ? JSON.parse(modalStates) : initialGuideModalsState;
};

// Save modal states to localStorage
export const saveGuideModalStates = (modals: GuideModalsObject): void => {
  localStorage.setItem(localStorageKey, JSON.stringify(modals));
};

// Check if the modal should be shown
export const checkShouldShowGuideModal = (guideModalId: GuideModalIds): boolean => {
  const guideModalStates = getGuideModalStates();
  const state = guideModalStates[guideModalId];
  return state === 'shouldSee';
};

// Check if the modal should be shown
export const checkGuideModalIsUnseen = (guideModalId: GuideModalIds): boolean => {
  const guideModalStates = getGuideModalStates();
  const state = guideModalStates[guideModalId];
  return state === 'unseen';
};

// Update modal state
export const updateGuideModalState = (modalId: GuideModalIds, newState: GuideModalsState): void => {
  const guideModalStates = getGuideModalStates();
  guideModalStates[modalId] = newState;
  saveGuideModalStates(guideModalStates);
};
