import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { DeclineSecondaryMarketOfferBidBody } from './model';

export async function declineSecondaryMarketBid({
  transactionId,
  secondaryMarketOfferId,
  bidId,
}: DeclineSecondaryMarketOfferBidBody): Promise<void> {
  await apiPost<unknown, DeclineSecondaryMarketOfferBidBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.DeclineSecondaryMarketOfferBid,
    { transactionId, secondaryMarketOfferId, bidId },
    true
  );
}
