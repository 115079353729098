export enum UndrawImages {
  Account = 'account',
  AcceptTerms = 'acceptTerms',
  AccessDenied = 'accessDenied',
  AddTasks = 'addTasks',
  Authentication = 'authentication',
  BestPlace = 'bestPlace',
  BirthdayCake = 'birthdayCake',
  BugFixing = 'bugFixing',
  BuyHouse = 'buyHouse',
  Candidate = 'candidate',
  ChoosingHouse = 'choosingHouse',
  ContractConfirmed = 'contractConfirmed',
  Data = 'data',
  Documents = 'documents',
  FileSearch = 'fileSearch',
  ForSale = 'forSale',
  Houses = 'houses3',
  IntenseFeeling = 'intenseFeeling',
  MessageSend = 'messageSend',
  MobileSuccess = 'mobileSuccess',
  MobilePhotos = 'mobilePhotos',
  OrderConfirmed = 'orderConfirmed',
  PageNotFound = 'pageNotFound',
  PayOnline = 'payOnline',
  PersonalFile = 'personalFile',
  ProfileData = 'profileData',
  RightDirection = 'rightDirection',
  RightPlaces = 'rightPlaces',
  QaEngeneers = 'qaEngeneers',
  QuietTown = 'quietTown',
  Safe = 'safe',
  SecureLogin = 'secureLogin',
  Security = 'security',
  ServerDown = 'serverDown',
  Settings = 'settings',
  SnapTheMoment = 'snapTheMoment',
  SometingWrong = 'sometingWrong',
  TransferMoney = 'transferMoney',
  UnderConstruction = 'underConstruction',
  Unsuitable = 'unsuitable',
  UpVote = 'upVote',
  UploadImages = 'uploadImages',
  WallPost = 'wallPost',
  Wallet = 'wallet',
  VisualData = 'visualData',
}
