import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { autoinvestStrategyWasSet } from './actions';

import { updateGuideModalState, GuideModalIds, checkGuideModalIsUnseen } from 'util/guideModals';

function* onAutoinvestStrategyWasSet(): SagaIterator {
  // if Autoinvest guide modal was not seen yet, mark it as 'shouldSee'
  if (checkGuideModalIsUnseen(GuideModalIds.Autoinvest)) {
    updateGuideModalState(GuideModalIds.Autoinvest, 'shouldSee');
  }

  // reset the cache for the latest autoinvest strategy
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.LatestAutoinvestStrategy);
}

function* watchAutoinvestStrategySetup(): SagaIterator {
  yield takeEvery(autoinvestStrategyWasSet, onAutoinvestStrategyWasSet);
}

export default function* root(): SagaIterator {
  yield all([fork(watchAutoinvestStrategySetup)]);
}
