import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';

import { useAnalytics } from '@investown/fe/common-utils';
import { defaultCurrency, useUserDetails, useUserInvestmentStats } from '@investown/fe/api-sdk';

import { isUserSignedIn as isUserSignedInSelector } from 'appRedux/auth/selectors';
import { PATH_INDIVIDUAL_SIGN_UP_PASSWORD, PATH_USER_VERIFICATION } from 'routes/routesPaths';

const PageViewsLogger: React.FC = () => {
  const isUserSignedIn = useSelector(isUserSignedInSelector);
  const history = useHistory();
  const analytics = useAnalytics();
  const { data } = useUserDetails({ enabled: isUserSignedIn });
  const isNotOnSignupOrVerification = useCheckPathNotSignUpOrVerification();
  const { data: userInvestmentStatsData, isFetchedAfterMount } = useUserInvestmentStats(defaultCurrency, {
    enabled: isUserSignedIn && isNotOnSignupOrVerification,
    staleTime: isUserSignedIn && isNotOnSignupOrVerification ? Infinity : 0,
  });

  const logPageView = useCallback(
    (newPathName: string, userId = '') => {
      analytics.trackScreenView!({
        path: newPathName,
        user_id: userId,
        last_purchase: userInvestmentStatsData?.UserInvestmentStats.lastInvestmentAt
          ? format(userInvestmentStatsData?.UserInvestmentStats.lastInvestmentAt, 'yyyy-MM-dd')
          : '',
        average_purchase: (userInvestmentStatsData?.UserInvestmentStats.avgInvestedAmount.value ?? 0).toString(),
        purchases: (userInvestmentStatsData?.UserInvestmentStats.numberOfInvestments ?? 0).toString(),
      });
    },
    [
      analytics.trackScreenView,
      userInvestmentStatsData?.UserInvestmentStats.avgInvestedAmount.value,
      userInvestmentStatsData?.UserInvestmentStats.lastInvestmentAt,
      userInvestmentStatsData?.UserInvestmentStats.numberOfInvestments,
    ]
  );

  useEffect(() => {
    let isInitialLogDone = false;

    if (isFetchedAfterMount) {
      // Initial page view
      logPageView(history.location.pathname, data?.intercomUserId);
      isInitialLogDone = true;
    }

    const unsubscribe = history.listen((location, action) => {
      // Ensure that we skip logging for the initial route that was just logged
      if (isInitialLogDone) {
        logPageView(location.pathname, data?.intercomUserId);
      } else {
        isInitialLogDone = true;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [history, data?.intercomUserId, logPageView, isFetchedAfterMount]);

  return null;
};

export function useReplaceUrlPath(): (newUrl: string) => void {
  const isUserSignedIn = useSelector(isUserSignedInSelector);
  const analytics = useAnalytics();
  const { data } = useUserDetails({ enabled: isUserSignedIn });
  const isNotOnSignupOrVerification = useCheckPathNotSignUpOrVerification();
  const { data: userInvestmentStatsData } = useUserInvestmentStats(defaultCurrency, {
    enabled: isUserSignedIn && isNotOnSignupOrVerification,
    staleTime: isUserSignedIn && isNotOnSignupOrVerification ? Infinity : 0,
  });

  const replaceUrlPathWithTracking = (newUrl: string): void => {
    window.history.replaceState(null, '', newUrl);
    analytics.trackScreenView!({
      path: newUrl,
      user_id: data?.intercomUserId || '',
      last_purchase: userInvestmentStatsData?.UserInvestmentStats.lastInvestmentAt
        ? format(userInvestmentStatsData?.UserInvestmentStats.lastInvestmentAt, 'yyyy-MM-dd')
        : '',
      average_purchase: (userInvestmentStatsData?.UserInvestmentStats.avgInvestedAmount.value ?? 0).toString(),
      purchases: (userInvestmentStatsData?.UserInvestmentStats.numberOfInvestments ?? 0).toString(),
    });
  };

  return replaceUrlPathWithTracking;
}

// Do not call wallet query on Signup and Verification as there is no Wallet created yet
export function useCheckPathNotSignUpOrVerification(): boolean {
  const location = useLocation();
  return (
    !location.pathname.startsWith(PATH_USER_VERIFICATION) &&
    !location.pathname.startsWith(PATH_INDIVIDUAL_SIGN_UP_PASSWORD)
  );
}

export function useLogScreenViewPath(): () => void {
  const history = useHistory();
  const analytics = useAnalytics();

  return () => {
    analytics.trackScreenView({
      path: history.location.pathname,
    });
  };
}

export default PageViewsLogger;
