/* eslint-disable @nx/enforce-module-boundaries */
import * as CoreSDK from '../../../../api-sdk/src/lib/core/__generated__/sdk';

export type LoanParametersWithRiskCategoryArray = Omit<CoreSDK.LoanParameters, 'riskCategory'> & {
  riskCategory: CoreSDK.RiskCategory[];
};

export function calculateMatchingLoansPercentage(
  loans: CoreSDK.GetRecentLoansParametersQuery['getRecentLoansParameters'],
  params: LoanParametersWithRiskCategoryArray
): number {
  const matchingLoans = loans.filter(
    (loan) =>
      loan.minInvestment <= params.minInvestment &&
      loan.loanToValue <= params.loanToValue &&
      loan.loanLengthInMonths <= params.loanLengthInMonths &&
      params.riskCategory.includes(loan.riskCategory)
  );

  if (matchingLoans.length === 0) {
    return 0;
  }

  const percentage = Math.floor((matchingLoans.length / loans.length) * 100);

  return percentage;
}
