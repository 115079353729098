/* eslint-disable import/no-duplicates */
import { Locale } from 'date-fns';
import { cs, enUS } from 'date-fns/locale';

export const availableLocales: { [key: string]: Locale } = {
  cs,
  en: enUS,
};

type LocaleKey = keyof typeof availableLocales;

// Get the date-fns locale based on the locale string
export function getDateFnsLocale(localeString: string): Locale {
  const twoLetterLocale = localeString.substring(0, 2);
  if (twoLetterLocale in availableLocales) {
    return availableLocales[twoLetterLocale as LocaleKey];
  }
  return cs;
}
