import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** An amount of money */
export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  value: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

/** A positive amount of money */
export type MonetaryAmountInput = {
  value: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  placeOrder: OrderDetail;
};

export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};

/** Describes user's order of an investment. */
export type OrderDetail = {
  __typename?: 'OrderDetail';
  /** Unique ID of the investment order */
  transactionId: Scalars['ID'];
  /** Identifier of the user who ordered an investment */
  userId: Scalars['ID'];
  /** Identifier of the project in which user ordered an investment */
  projectId: Scalars['ID'];
  /** Ordered amount */
  amount: MonetaryAmount;
  /** Status of the order */
  status: OrderStatus;
  /** In case the status is "Unsuccessful" or "Failed" this field contains reasons. */
  unsuccessOrFailReasons: Array<Scalars['String']>;
};

export enum OrderStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Unsuccessful = 'Unsuccessful',
  Failed = 'Failed',
}

/** Input of "PlaceOrder" command mutation */
export type PlaceOrderInput = {
  /** Unique ID of the investment order */
  transactionId: Scalars['ID'];
  /** Identifier of the project in which user ordered an investment */
  projectId: Scalars['ID'];
  /** Ordered amount */
  amount: MonetaryAmountInput;
};

export type Query = {
  __typename?: 'Query';
  Order: OrderDetail;
};

export type QueryOrderArgs = {
  transactionId: Scalars['ID'];
};

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;

export type PlaceOrderMutation = { __typename?: 'Mutation' } & {
  placeOrder: { __typename?: 'OrderDetail' } & Pick<OrderDetail, 'status' | 'unsuccessOrFailReasons'>;
};

export type GetOrderInfoQueryVariables = Exact<{
  transactionId: Scalars['ID'];
}>;

export type GetOrderInfoQuery = { __typename?: 'Query' } & {
  Order: { __typename?: 'OrderDetail' } & Pick<OrderDetail, 'status' | 'unsuccessOrFailReasons'>;
};

export const PlaceOrderDocument = gql`
  mutation PlaceOrder($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      status
      unsuccessOrFailReasons
    }
  }
`;
export const GetOrderInfoDocument = gql`
  query GetOrderInfo($transactionId: ID!) {
    Order(transactionId: $transactionId) {
      status
      unsuccessOrFailReasons
    }
  }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    PlaceOrder(
      variables: PlaceOrderMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PlaceOrderMutation> {
      return withWrapper(() => client.request<PlaceOrderMutation>(PlaceOrderDocument, variables, requestHeaders));
    },
    GetOrderInfo(
      variables: GetOrderInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetOrderInfoQuery> {
      return withWrapper(() => client.request<GetOrderInfoQuery>(GetOrderInfoDocument, variables, requestHeaders));
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
