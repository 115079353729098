import React, { FC } from 'react';
import styled from 'styled-components';

import { SpacingSizeName } from 'styles/theme';

interface SpacerProps {
  height?: string | SpacingSizeName;
  width?: string | SpacingSizeName;
}

/**
 *
 * @param height:
 * small: '4px',
 * medium: '8px',
 * regular: '12px',
 * large: '16px',
 * larger: '20px',
 * extraLarge: '24px',
 * huge: '32px',
 * massive: '40px',
 * extensive: '62px',
 * immense: '80px',
 */
const Spacer: FC<SpacerProps> = ({ height, width }) => <StyledSpacer height={height} width={width} />;

const StyledSpacer = styled.div<SpacerProps>`
  display: block;
  height: ${({ theme, height }) =>
    theme.spacing[height as SpacingSizeName] ? theme.spacing[height as SpacingSizeName] : `${height}px`};
  min-height: ${({ theme, height }) =>
    theme.spacing[height as SpacingSizeName] ? theme.spacing[height as SpacingSizeName] : `${height}px`};
  width: ${({ theme, width }) =>
    theme.spacing[width as SpacingSizeName] ? theme.spacing[width as SpacingSizeName] : `${width}px`};
  min-width: ${({ theme, width }) =>
    theme.spacing[width as SpacingSizeName] ? theme.spacing[width as SpacingSizeName] : `${width}px`};
`;

export default Spacer;
