import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { signOut, useAnalytics } from '@investown/fe/common-utils';

import { PATH_SIGN_IN } from 'routes/routesPaths';
import { LocationState } from 'routes/SignIn';

interface Props {
  afterLogoutMessage?: string;
  afterLogoutPath?: string;
}

const useLogout = (): (({ afterLogoutMessage, afterLogoutPath }: Props) => Promise<void>) => {
  const history = useHistory();
  const analytics = useAnalytics();

  return useCallback(
    async ({ afterLogoutMessage, afterLogoutPath }: Props) => {
      try {
        await signOut();
        analytics.resetUser();
        const state: LocationState = { successAlertMessage: afterLogoutMessage };
        history.replace(afterLogoutPath || PATH_SIGN_IN, state);
      } catch (authErr) {
        console.error(authErr);
      }
    },
    [analytics, history]
  );
};

export default useLogout;
