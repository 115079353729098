import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { TestIds } from '@investown/fe/test-utils/testIds';

import MenuItem from '../MenuItem/MenuItem';

import HomeIcon from 'components/V2/Icons/Home';
import PortfolioIcon from 'components/V2/Icons/Portfolio';
import TransactionIcon from 'components/V2/Icons/Transaction';
import { PATH_DASHBOARD, PATH_INVESTED_PROPERTIES, PATH_WALLET } from 'routes/routesPaths';

const Menu: FC = () => {
  const intl = useIntl();

  return (
    <MenuContainer>
      <MenuItem
        menuItemLink={PATH_DASHBOARD}
        name={intl.formatMessage({ id: 'topbar.menu.home' })}
        icon={HomeIcon}
        testId={TestIds.Dashboard}
      />
      <MenuItem
        menuItemLink={PATH_INVESTED_PROPERTIES}
        name={intl.formatMessage({ id: 'topbar.menu.portfolio' })}
        icon={PortfolioIcon}
        testId={TestIds.Portfolio}
      />
      <MenuItem
        menuItemLink={PATH_WALLET}
        name={intl.formatMessage({ id: 'topbar.menu.transactions' })}
        icon={TransactionIcon}
        testId={TestIds.Transactions}
      />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;

export default Menu;
