import React, { ComponentProps, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

interface Props {
  fallbackComponent: ComponentProps<typeof Sentry.ErrorBoundary>['fallback'];
  children?: ReactNode | Element;
}

/**
 * Note from Sentry docs: In development mode, React will rethrow errors caught within an error boundary.
 * This will result in errors being reported twice to Sentry with the above setup,
 * but this won’t occur in your production build.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
 */
export const ErrorBoundary: React.FC<Props> = ({ fallbackComponent, children }) => (
  // @ts-expect-error children props is not typed in Sentry
  <Sentry.ErrorBoundary fallback={fallbackComponent}>{children}</Sentry.ErrorBoundary>
);
