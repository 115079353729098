interface AmountDTO {
  value: number;
  currency: string;
}

export interface LoanAgreementTemplateDataInputDTO {
  userData: LoanAgreementUserDataInputDTO;
  borrowerData: LoanAgreementBorrowerDataInputDTO;
  investmentRoundData: LoanAgreementRoundDataInputDTO;
  investmentAmount: AmountDTO;
  signedAtIsoDate: string;
}

export interface LoanAgreementCompanyDataInputDTO {
  id: string;
  name: string;
  courtFileNumber?: string;
  courtName?: string;
  street: string;
  city: string;
  zip: string;
}

export interface LoanAgreementLegalEntityUserDataInputDTO {
  firstName: string;
  lastName: string;
  dateBirth?: string;
  personalIdentifier?: string;
  company: LoanAgreementCompanyDataInputDTO;
}

export interface LoanAgreementIndividualUserDataInputDTO {
  firstName: string;
  lastName: string;
  dateBirth?: string;
  personalIdentifier?: string;
  street: string;
  city: string;
  zip: string;
}

export type LoanAgreementUserDataInputDTO =
  | LoanAgreementLegalEntityUserDataInputDTO
  | LoanAgreementIndividualUserDataInputDTO;

export interface LoanAgreementBorrowerDataInputDTO {
  companyIdentifier: string; // IČO
  companyName: string;
  address: {
    street: string;
    city: string;
    zip: string;
    countryIsoCode: string;
  };
  registerEntry?: string; // zápis v rejstříku
}

export interface LoanAgreementRoundDataInputDTO {
  legalId?: string | null;
  investmentAmountTotal: AmountDTO;
  investmentLengthInMonths: number;
  annualPercentageYield: number;
  endPercentageBonus: number;
  purpose: string;
}

export interface LoanAgreementVersionPreviewDTO {
  version: string;
  htmlPreview: string;
}

export interface LegalEntityRequiredDocuments {
  fileUrl: string;
}

export enum AgreementType {
  Primary = 'primary', // primary market transaction
  SecondaryComplete = 'secondary_complete', // secondary market transaction (complete purchase of the marketplace offer)
  SecondaryPartial = 'secondary_partial', // secondary market transaction (partial purchase of the marketplace offer)
}

export enum TransactionSide {
  Buyer = 'buyer',
  Seller = 'seller',
}

export interface AgreementGetFileUrlInput {
  transactionId: string;
  transactionSide?: TransactionSide | null;
}

interface InvestmentRound {
  id: string;
  name: string;
}

export interface AgreementDTO {
  investmentRound: InvestmentRound;
  transactionId: string;
  transactionDate: Date;
  type: AgreementType;
  transactionSide?: TransactionSide | null;
}
