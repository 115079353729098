import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { LegalEntitySignUpRequest, UserSignUpResponse } from './model';

export async function legalEntitySignUp(body: LegalEntitySignUpRequest): Promise<UserSignUpResponse> {
  return apiPost<UserSignUpResponse, LegalEntitySignUpRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.LegalEntitySignUp,
    body,
    false
  );
}
