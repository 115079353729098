import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ActionType } from 'typesafe-actions';

import { getUserDetails, queryClient, QueryKeys, UserDetailsDTO } from '@investown/fe/api-sdk';
import { googleTagManager, GTMEvents, pushEcommercePurchaseObjectToDataLayer } from '@investown/fe/common-utils';

import { signInSuccessAction } from '../auth/actions';

import {
  signUpOpenedAction,
  verificationFinishedDocuments,
  verificationFinishedTermsAndConditions,
  verificationFinishedMoneyOrigin,
  verificationFinishedPep,
  verificationOpenedAction,
  verificationStartAction,
  verificationDocumentsCaptureStartAction,
  verificationSecondPhaseStartAction,
  verificationSubmittedAction,
  verificationInvestorQuestionnaireOpenedAction,
  verificationInvestorQuestionAnsweredAction,
  verificationInvestorQuestionnaireSubmittedAction,
} from 'appRedux/onboarding/actions';
import { investedAction, investmentPurchaseAction } from 'appRedux/investment/actions';
import { pushVirtualPageViewToGtm, VirtualPageViews } from 'analytics/gtm/virtualPageViews';
import { pushCustomEventToGtm, CustomEvents } from 'analytics/gtm/customEvents';
import analyticsService from 'analytics';

function* onSignUpOpened(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.signUpOpened);
  yield call(pushCustomEventToGtm, CustomEvents.signUpStart);
}

function* onVerificationOpened(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationOpened);
}

function* onVerificationStart(): SagaIterator {
  yield call(pushCustomEventToGtm, CustomEvents.verificationStart);
}

function* onVerificationDocumentsCaptureStart(): SagaIterator {
  yield call(pushCustomEventToGtm, CustomEvents.verificationDocumentsCaptureStart);
}

function* onVerificationSecondPhaseStart(): SagaIterator {
  yield call(pushCustomEventToGtm, CustomEvents.verificationSecondPhaseStart);
}

function* onVerificationFinishedPep(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationFinishedPep);
  yield call(pushCustomEventToGtm, CustomEvents.verificationPepComplete);
}

function* onVerificationFinishedDocuments(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationFinishedDocuments);
  yield call(pushCustomEventToGtm, CustomEvents.verificationDocumentsCaptureComplete);
}

function* onVerificationFinishedTermsAndConditions(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationFinishedTermsAndConditions);
  yield call(pushCustomEventToGtm, CustomEvents.verificationFinishedTermsAndConditions);
}

function* onVerificationFinishedMoneyOrigin(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationFinishedMoneyOrigin);
  yield call(pushCustomEventToGtm, CustomEvents.verificationSofComplete);
}

function* onVerificationSubmitted(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationSubmitted);
  yield call(googleTagManager, GTMEvents.OnVerificationComplete); // legacy event, now using virtual page views
}

function* onVerificationInvestorQuestionnaireOpened(): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationInvestorQuestionnaireOpened);
  yield call(pushCustomEventToGtm, CustomEvents.verificationQuestionnaireStart);
}

function* onVerificationInvestorQuestionnaireQuestionAnswered(
  questionIndex: ActionType<typeof verificationInvestorQuestionAnsweredAction>
): SagaIterator {
  yield call(pushCustomEventToGtm, CustomEvents.verificationQuestionnaireQuestionAnswered, questionIndex.payload);
}

function* onVerificationInvestorQuestionnaireSubmitted(
  verifResult: ActionType<typeof verificationInvestorQuestionnaireSubmittedAction>
): SagaIterator {
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.verificationInvestorQuestionnaireSubmitted);
  if (verifResult.payload.isUpgrade) {
    yield call(pushCustomEventToGtm, CustomEvents.verificationUpgradeComplete, {
      verif_result: verifResult.payload.verifResult,
    });
  } else {
    yield call(pushCustomEventToGtm, CustomEvents.verificationQuestionnaireComplete, {
      verif_result: verifResult.payload.verifResult,
    });
  }
}

function* onInvested(action: ActionType<typeof investedAction>): SagaIterator {
  const { amount, currency, transactionId, propertySlug } = action.payload;
  const investmentSize = amount.toFixed(2);

  // for GA Enhanced ecommerce
  yield call<typeof pushEcommercePurchaseObjectToDataLayer>(pushEcommercePurchaseObjectToDataLayer, {
    purchase: {
      actionField: {
        id: transactionId,
        revenue: investmentSize,
      },
      products: [{ name: propertySlug }],
    },
    currency,
  });

  // for FB event
  yield call(googleTagManager, { invest: { size: investmentSize, currency } });

  yield call(pushVirtualPageViewToGtm, VirtualPageViews.invested);
}

function* onSignInSuccessAction(): SagaIterator {
  try {
    const userDetails: UserDetailsDTO = yield call(
      [queryClient, queryClient.fetchQuery],
      QueryKeys.UserDetails,
      getUserDetails
    );
    analyticsService.setUser(userDetails);
  } catch (e) {
    console.error('Error fetching user details', e);
  }
  yield call(googleTagManager, { event: 'login' });
}

function* sendUserFirstInvestmentEvent(action: ActionType<typeof investmentPurchaseAction>): SagaIterator {
  const { amount, currency, transactionId, propertySlug } = action.payload;
  const investmentSize = amount.toFixed(2);

  const userDetails: UserDetailsDTO = yield call(
    [queryClient, queryClient.fetchQuery],
    QueryKeys.UserDetails,
    getUserDetails
  );

  if (userDetails.madeInvestment) {
    return;
  }

  yield call(googleTagManager, {
    user_first_investment: {
      actionField: {
        id: transactionId,
        revenue: investmentSize,
      },
      products: [{ name: propertySlug }],
      amount: investmentSize,
      currency,
    },
  });
  yield call(pushVirtualPageViewToGtm, VirtualPageViews.userFirstInvestment);
}

export function* watchMarketingEvents(): SagaIterator {
  yield takeEvery(signUpOpenedAction, onSignUpOpened);

  yield takeEvery(verificationOpenedAction, onVerificationOpened);
  yield takeEvery(verificationStartAction, onVerificationStart);
  yield takeEvery(verificationDocumentsCaptureStartAction, onVerificationDocumentsCaptureStart);
  yield takeEvery(verificationSecondPhaseStartAction, onVerificationSecondPhaseStart);
  yield takeEvery(verificationFinishedPep, onVerificationFinishedPep);
  yield takeEvery(verificationFinishedDocuments, onVerificationFinishedDocuments);
  yield takeEvery(verificationFinishedTermsAndConditions, onVerificationFinishedTermsAndConditions);
  yield takeEvery(verificationFinishedMoneyOrigin, onVerificationFinishedMoneyOrigin);
  yield takeEvery(verificationSubmittedAction, onVerificationSubmitted);
  yield takeEvery(verificationInvestorQuestionnaireOpenedAction, onVerificationInvestorQuestionnaireOpened);
  yield takeEvery(verificationInvestorQuestionAnsweredAction, onVerificationInvestorQuestionnaireQuestionAnswered);
  yield takeEvery(verificationInvestorQuestionnaireSubmittedAction, onVerificationInvestorQuestionnaireSubmitted);
  yield takeEvery(investedAction, onInvested);
  yield takeEvery(investmentPurchaseAction, sendUserFirstInvestmentEvent);
  yield takeEvery(signInSuccessAction, onSignInSuccessAction);
}

export default function* root(): SagaIterator {
  yield all([fork(watchMarketingEvents)]);
}
