import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { getUserDetails, UserDetailsDTO } from '../users';
import { QueryKeys } from '../enums';

export const useUserDetails = (
  queryOptions: UseQueryOptions<UserDetailsDTO, unknown> = {}
): UseQueryResult<UserDetailsDTO, unknown> =>
  useQuery<UserDetailsDTO, unknown>(QueryKeys.UserDetails, getUserDetails, {
    refetchOnMount: true,
    notifyOnChangeProps: ['data', 'isError', 'isLoading'],
    ...queryOptions,
  });
