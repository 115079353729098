import React, { FC, useContext, FunctionComponent } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { fillColor, textColor } from '../utils';
import Typography from '../../Typography/Typography';

import Spacer from 'components/Spacer';
import { IconProps } from 'components/V2/Icons';

type IconType = FunctionComponent<IconProps>;
interface MenuItemProps {
  menuItemLink: string;
  testId?: string;
}

interface MenuItemPropsWithIcon extends MenuItemProps {
  icon: IconType;
  name?: string;
}

interface MenuItemPropsWithName extends MenuItemProps {
  icon?: IconType;
  name: string;
}

type Props = MenuItemPropsWithIcon | MenuItemPropsWithName;
const MenuItem: FC<Props> = ({ menuItemLink, name, testId, icon: Icon }) => {
  const { pathname } = useLocation();
  const theme = useContext(ThemeContext);
  const isActive = pathname === menuItemLink;

  return (
    <MenuItemContainer to={menuItemLink} $active={pathname === menuItemLink} data-testid={testId}>
      {Icon && <Icon fillColor={fillColor(isActive, theme)} color={textColor(isActive, theme)} />}
      {name && Icon && <Spacer width="medium" />}
      {name && (
        <Typography color="strong" variant="labelSMMedium">
          {name}
        </Typography>
      )}
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled(Link)<{ $active: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.regular};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  span {
    color: ${({ theme }) => theme.colorTokens.text.strong};
  }

  &:hover {
    cursor: pointer;
    span {
      color: ${({ theme }) => theme.colorTokens.text.brand};
    }

    svg path {
      stroke: ${({ theme }) => theme.colorTokens.text.brand};
    }
  }

  ${({ $active, theme }) =>
    $active &&
    `
    background: ${theme.colorTokens.button.secondary.default};
    span {
      color: ${theme.colorTokens.text.brand};
    }

  `}
`;
export default MenuItem;
