import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as SvgLogo } from 'assets/images/logos/finbus.svg';

const FinbusLogo: FC<{ className?: string }> = ({ className }) => <StyledLogo className={className} title="Finbus" />;

const StyledLogo = styled(SvgLogo)`
  position: relative;
  width: auto;
`;

export default FinbusLogo;
