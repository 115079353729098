export const PATH_DASHBOARD = '/';
export const PATH_INVITE_FRIEND = '/invite-friend';
export const PATH_SIGN_IN = '/sign-in';
export const PATH_SIGN_IN_MFA = '/sign-in/mfa';
export const PATH_PROPERTIES = '/properties';
export const PATH_PREMIUM = '/premium';
export const PATH_OPEN_PROPERTIES = '/properties/open';
export const PATH_SECONDARY_MARKET = '/properties/secondary';
export const PATH_FULL_PROPERTIES = '/properties/full';
export const PATH_SECONDARY_MARKET_BIDS = '/properties/secondary-bids';
export const PATH_INVESTED_PROPERTIES = '/properties/invested';
export const PATH_INVESTED_PROPERTIES_REGULAR = '/properties/invested/regular';
export const PATH_INVESTED_PROPERTIES_COLLECTION = '/properties/invested/collection';
export const PATH_INVESTED_PROPERTIES_DELAYED = '/properties/invested/delayed';
export const PATH_INVESTED_PROPERTIES_OPEN = '/properties/invested/open';
export const PATH_INVESTED_PROPERTIES_PROCESSING = '/properties/invested/processing';
export const PATH_REFERRAL_CONNECTION = '/referral/:referralCode';
export const PATH_REFERRAL_DOWNLOAD = '/referral-download-app';
export const PATH_INDIVIDUAL_SIGN_UP_CREDENTIALS = '/sign-up/credentials';
export const PATH_INDIVIDUAL_SIGN_UP_PASSWORD = '/sign-up/password';
export const PATH_INDIVIDUAL_SIGN_UP_EMAIL = '/sign-up/email';
export const PATH_LEGAL_ENTITY_SIGN_UP_IDENTIFICATION_NUMBER = '/sign-up/legal-entity/identification-number';
export const PATH_LEGAL_ENTITY_SIGN_UP_EXECUTIVE = '/sign-up/legal-entity/executive';
export const PATH_LEGAL_ENTITY_SIGN_UP_CONFIRM_COMPANY_INFORMATIONS =
  '/sign-up/legal-entity/confirm-company-informations';
export const PATH_LEGAL_ENTITY_SIGN_UP_PASSWORD = '/sign-up/legal-entity/password';
export const PATH_LEGAL_ENTITY_SIGN_UP_EMAIL = '/sign-up/legal-entity/email';
export const PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS = '/legal-entity/required-documents';
export const PATH_USER_VERIFICATION = '/user/verification';
export const PATH_ABILITY_TO_BEAR_LOSS = '/ability-to-bear-loss';
export const PATH_INVESTOR_QUESTIONNAIRE = '/investor-questionnaire';
export const PATH_INVESTOR_QUESTIONNAIRE_ADDITION = '/investor-questionnaire-addition';
export const PATH_WALLET = '/wallet';
export const PATH_AUTOINVEST = '/autoinvest';
export const PATH_AUTOINVEST_CALCULATOR = '/autoinvest-calculator';
export const PATH_AUTOINVEST_INTRO = '/autoinvest-intro';
export const PATH_AUTOINVEST_STRATEGY_CONFIGURATION = '/autoinvest-strategy-configuration';
export const PATH_AUTOINVEST_STANDING_ORDER = '/autoinvest-standing-order';
export const PATH_AUTOINVEST_SUCCESS = '/autoinvest-success';
export const PATH_AUTOINVEST_EDIT_STRATEGY = '/autoinvest-edit-strategy';
export const PATH_FORGOTTEN_PASSWORD = '/forgotten-password';
export const PATH_CHANGE_PASSWORD = '/change-password';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_PROPERTY = '/property/:slug';
export const PATH_FRAMEWORK_AGREEMENT_PREVIEW = '/framework-agreement-preview';
export const PATH_ABOUT_MARKETPLACE = '/about/marketplace';
export const PATH_APP = '/app';

export const PATH_ERROR_404 = '/error/404';
export const PATH_MOBILE = '/mobile';
export const PATH_USER = '/user';
export const PATH_USER_DOCUMENTS = '/user/documents';
export const PATH_USER_REFERRAL = '/user/referral';
export const PATH_USER_NOTIFICATIONS = '/user/notifications';
export const PATH_USER_LANGUAGES = '/user/languages';
export const PATH_USER_PASSWORD_CHANGE = '/user/password-change';
export const PATH_USER_MFA = '/user/mfa';
export const PATH_USER_MEMBERSHIP_LEVELS = '/user/membership-levels';
export const PATH_USER_SUPPORT = '/user/support';

export const APP_DEFAULT_PATH = PATH_DASHBOARD;
