import { UserDetailsDTO } from '../users';

import { UserVerificationDataDTO } from './model';

export function isAddressVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return (
    userVerificationData.street !== null &&
    userVerificationData.city !== null &&
    userVerificationData.zip !== null &&
    userVerificationData.countryIsoCode !== null &&
    userVerificationData.street !== undefined &&
    userVerificationData.city !== undefined &&
    userVerificationData.zip !== undefined &&
    userVerificationData.countryIsoCode !== undefined
  );
}

export function isBirthNumberOrDateBirthVerificationStepComplete(
  userVerificationData: UserVerificationDataDTO
): boolean {
  return (
    (userVerificationData.dateBirth !== null && userVerificationData.dateBirth !== undefined) ||
    (userVerificationData.personalIdentifier !== null && userVerificationData.personalIdentifier !== undefined)
  );
}

export function isPhoneNumberVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return userVerificationData.phoneNumber !== null && userVerificationData.phoneNumber !== undefined;
}

export function isPhoneVerificationVerificationStepComplete(userDetails: UserDetailsDTO): boolean {
  return userDetails.phoneNumber !== undefined && userDetails.phoneNumber !== null && userDetails.phoneNumberVerified;
}

export function isContractVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return !!userVerificationData.submitted;
}

export function isMoneyOriginVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return (
    userVerificationData.sofEmployment ||
    userVerificationData.sofRent ||
    userVerificationData.sofPension ||
    userVerificationData.sofBusiness ||
    userVerificationData.sofParentalLeave ||
    (userVerificationData.sofOther !== null && userVerificationData.sofOther !== undefined)
  );
}

export function isTermsAndConditionsVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return !!userVerificationData.termsAndConditions;
}

export function isPepVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return userVerificationData.pep !== null && userVerificationData.pep !== undefined;
}

export function isVeriffVerificationStepComplete(userVerificationData: UserVerificationDataDTO): boolean {
  return !!userVerificationData.submitted;
}
