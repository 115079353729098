/* eslint-disable @nx/enforce-module-boundaries */
import { UserDetailsDTO } from '../../../../../api-sdk/src/lib/users/model';
import { AnalyticsAction } from '../analyticsActions';

export const Events = {
  [AnalyticsAction.INVEST_OVERVIEW]: 'view_investment',
  [AnalyticsAction.SCREEN_APP_VIEW]: 'screen_app_view',
  [AnalyticsAction.INVITE_FRIENDS]: 'invite_friends',
};

export function createUserIdentifiersPayload(userDetails: UserDetailsDTO): Record<string, string> {
  return {
    user_id: userDetails.intercomUserId,
    email: userDetails.email,
    intercomId: userDetails.intercomUserId,
    first_name: userDetails.firstName,
    last_name: userDetails.lastName,
    phone: userDetails.phoneNumber || '',
    customer_type: userDetails.companyId ? 'company' : 'person',
    membership_status: userDetails.level.currentLevel,
  };
}
