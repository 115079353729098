/**
Examples of use:
inflectTimeUnit({ number: 1, unit: 'day', locale: 'cs-CZ' }) // {number: 1, unit: 'den'}
inflectTimeUnit({ number: 2, unit: 'day', locale: 'cs-CZ' }) // {number: 2, unit: 'dny'}
inflectTimeUnit({ number: 5, unit: 'day', locale: 'cs-CZ' }) // {number: 5, unit: 'dní'}
 */

export function inflectTimeUnit({
  number,
  unit = 'day',
  locale,
}: {
  number: number;
  unit?: Intl.RelativeTimeFormatUnitSingular;
  splitToParts?: boolean;
  locale: string;
}): { number: number; unit: string } {
  const relativeFormat = new Intl.RelativeTimeFormat(locale, { style: 'short' });
  const unitParts = relativeFormat.formatToParts(number, unit);
  const inflectedUnit = unitParts[unitParts.length - 1].value.trim();
  return { number, unit: inflectedUnit };
}
