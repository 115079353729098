import { apiPost } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { InvestorQuestionnaireSubmitDTO, InvestorQuestionnaireSubmitResponse } from './model';

export async function submitSuitabilityInvestorQuestionnaire(
  body: InvestorQuestionnaireSubmitDTO
): Promise<InvestorQuestionnaireSubmitResponse> {
  return apiPost<InvestorQuestionnaireSubmitResponse, InvestorQuestionnaireSubmitDTO>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.InvestorSuitabilityQuestionnaireSubmit,
    body,
    true
  );
}
