import { InvestownActionCreators, Bloomreach } from '@investown/fe/common-utils';

type ViewInvestmentPayload = Omit<
  Bloomreach.ViewInvestmentPayload,
  | 'os' // tracked automatically https://documentation.bloomreach.com/engagement/docs/tracking#example-2
  | 'device' // tracked automatically
  | 'browser' // tracked automatically
  | 'location' // tracked automatically
  | 'country_code' // tracked automatically from Google Tag Manager
  | 'global_target_amount' // not implemented until Investown expands to foreign countries
  | 'global_currency' // not implemented until Investown expands to foreign countries
>;

export function createInvestmentViewPayload(
  event: ReturnType<InvestownActionCreators['investOverviewAction']>
): ViewInvestmentPayload {
  return {
    referrer: document.referrer,
    path: window.location.pathname,
    __cpLocation: localStorage.getItem('__cpLocation') || '',
    id: event.payload.project.id,
    title: event.payload.project.name,
    investment_location: event.payload.project.city,
    country: event.payload.project.countryIsoCode,
    rating: event.payload.project.investmentRound.riskCategory,
    image_url: event.payload.project.photos[0].url,
    url: window.location.href,
    currency: event.payload.project.currentInvestmentRound?.investmentCurrency || '',
    target_amount: event.payload.project.investmentRound.investmentAmountTotal.toString(),
  };
}
