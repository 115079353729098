// eslint-disable-next-line @nx/enforce-module-boundaries
// import { getAccessToken } from '@investown/fe/common-utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getAccessToken } from '../../../../common-utils/src/lib/auth';
import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { EnableSmsMfaRequest } from './model';

export async function enableSmsMfa({ code }: { code: string }): Promise<void> {
  const accessToken = await getAccessToken();

  await apiPost<unknown, EnableSmsMfaRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UsersSmsMfaEnable,
    { accessToken, code },
    true
  );
}
