/** @deprecated use apps/web/src/styles/colors instead */
export const colors = {
  primary: {
    /** @deprecated use primitives.investownBrand.500 instead */
    base: '#1961FF',
    disabled: '#E7E7E7',
    disabledFloating: '#AFC7FF', // e.g. disabled floating button
    active: '#1C46AC',
    loading: '#5F8EFF',
    faded: '#D7E3FF',
    light: '#F0F4FF',
  },
  secondary: {
    base: '#EBF1FF',
    active: '#E8EBF1',
    loading: '#EFF4FF',
    background: '#F5F5F5',
  },
  text: {
    base: '#04102A',
    disabled: '#B1B1B1',
    faded: '#697586',
    one: '#172140',
    two: '#424F76',
  },
  primitive: {
    white: '#FFFFFF',
    grey: '#F0F0F0',
    darkGrey: '#E3E3E3',
    background: '#FAFAFA',
    black: '#343434',
    pureBlack: '#000000',
    faded: '#718096',
    shadow: '#e7e7e780',
  },
  premium: '#F4BB27',
  alert: {
    base: '#EE6352',
    faded: '#FCE0DC',
    heavy: '#CF1322',
    active: '#A8493D',
  },
  success: {
    base: '#21CE99',
    faded: '#D3F5EB',
    active: '#2B8966',
  },
  warning: {
    base: '#B2660A',
    faded: '#FEF9EC',
  },
  error: {
    base: '#C40D39',
    faded: '#FCE7EC',
  },
  highlight: {
    base: '#FF5E76',
    faded: '#FFEAED',
    active: '#D44056',
  },
  transparent: 'transparent',
  stroke: {
    medium: '#E3E8EF',
  },
};
