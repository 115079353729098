import { createAction } from 'typesafe-actions';

import { AbilityToBearLossQuestionnaireSubmitResponseData } from '@investown/fe/api-sdk';

export const signUpClickedAction = createAction('SIGNUP_CLICKED')<void>();
export const signUpOpenedAction = createAction('SIGNUP_OPENED')<void>();

export const verificationOpenedAction = createAction('VERIFICATION_OPENED')<void>();
export const verificationStartAction = createAction('VERIFICATION_START')<void>();
export const verificationDocumentsCaptureStartAction = createAction('VERIFICATION_DOCUMENTS_CAPTURE_START')<void>();
export const verificationSecondPhaseStartAction = createAction('VERIFICATION_SECOND_PHASE_START')<void>();
export const verificationFinishedPep = createAction('VERIFICATION_FINISHED_PEP')<void>();
export const verificationFinishedDocuments = createAction('VERIFICATION_FINISHED_DOCUMENTS')<void>();
export const verificationFinishedTermsAndConditions = createAction('VERIFICATION_FINISHED_TNC')<void>();
export const verificationFinishedMoneyOrigin = createAction('VERIFICATION_FINISHED_MONEY_ORIGIN')<void>();
export const verificationInvestorQuestionnaireOpenedAction = createAction('VERIFICATION_QUESTIONNAIRE_START')<void>();

export const verificationInvestorQuestionAnsweredAction = createAction('VERIFICATION_QUESTIONNAIRE_ANSWERED')<{
  questionId: number | string;
  answer?: number | string | boolean;
}>();

export const verificationInvestorQuestionReopenedAction = createAction('VERIFICATION_QUESTIONNAIRE_REOPENED')<{
  questionId: number | string;
}>();

export const verificationInvestorQuestionnaireSubmittedAction = createAction('VERIFICATION_QUESTIONNAIRE_COMPLETE')<{
  verifResult: string;
  isUpgrade?: boolean;
}>();

export const verificationQuestionnaireUnsuitableAlertAction = createAction(
  'VERIFICATION_QUESTIONNAIRE_UNSUITABLE'
)<void>();

export const verificationInvestorQuestionnairePartTwoOpenedAction = createAction(
  'VERIFICATION_QUESTIONNAIRE_P2_START'
)<void>();

export const verificationInvestorQuestionPartTwoAnsweredAction = createAction(
  'VERIFICATION_QUESTIONNAIRE_P2_ANSWERED'
)<{
  questionId: number | string;
  answer?: number | string | boolean;
}>();

export const verificationInvestorQuestionPartTwoReopenedAction = createAction(
  'VERIFICATION_QUESTIONNAIRE_P2_REOPENED'
)<{
  questionId: number | string;
}>();

export const verificationInvestorQuestionnairePartTwoSubmittedAction = createAction(
  'VERIFICATION_QUESTIONNAIRE_P2_COMPLETE'
)<{
  verifResult: string;
  isUpgrade?: boolean;
}>();
export const verificationSubmittedAction = createAction('VERIFICATION_SUBMITTED')<void>();

export const abilityToBearLossQuestionnaireStartAction = createAction('ABILITY_TO_BEAR_LOSS_START')();

export const abilityToBearLossQuestionnaireQuestionAnsweredAction = createAction('ABILITY_TO_BEAR_LOSS_ANSWERED')<{
  questionId: number | string;
}>();

export const abilityToBearLossQuestionnaireQuestionReopenedAction = createAction('ABILITY_TO_BEAR_LOSS_REOPENED')<{
  questionId: number | string;
}>();

export const abilityToBearLossQuestionnaireCompleteAction = createAction('ABILITY_TO_BEAR_LOSS_COMPLETE')<{
  submitResponseData: AbilityToBearLossQuestionnaireSubmitResponseData;
}>();
