import { ApiNames, ApiPath } from '../enums';
import { apiGet } from '../api';

import { AgreementDTO } from './model';

export async function getPageableAgreements(page = 1, size = 10): Promise<{ agreements: AgreementDTO[] }> {
  return apiGet<{ agreements: AgreementDTO[] }>(ApiNames.MonorepoApiEndpoint, ApiPath.AgreementsList, true, {
    page,
    size,
  });
}
