import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useLocation } from 'react-router-dom';

import Typography from 'components/V2/Typography/Typography';
import Cross from 'components/V2/Icons/Cross';
import useGuideModal from 'hooks/useGuideModal';
import { GuideModalIds } from 'util/guideModals';
import Lang from 'util/IntlMessages';
import { PATH_DASHBOARD } from 'routes/routesPaths';

const AutoinvestGuideModal = () => {
  const theme = useContext(ThemeContext);
  const { markAsSeen, shouldBeSeen } = useGuideModal(GuideModalIds.Autoinvest);
  const [shouldShow, setShouldShow] = React.useState(shouldBeSeen());
  const location = useLocation();

  const handleClose = () => {
    markAsSeen();
    setShouldShow(false);
  };

  if (shouldShow && location.pathname === PATH_DASHBOARD) {
    return (
      <Modal>
        <Typography variant="bodySMRegular" color="invertedStrong">
          <Lang id="guideModals.autoinvest" />
        </Typography>
        <CloseButton onClick={handleClose}>
          <Cross color={theme.colorTokens.text.invertedStrong} hoverColor={theme.colorTokens.text.invertedMedium} />
        </CloseButton>
      </Modal>
    );
  }

  return null;
};

export default AutoinvestGuideModal;

const Modal = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colorTokens.surface.inverted900};
  box-shadow: 0px 2.03px 4.059px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: -80px;
  top: ${({ theme }) => theme.topbar.height};
  min-width: 380px;
  padding: ${({ theme }) => theme.spacing.large};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  &::before {
    content: '';
    width: 0;
    height: 0;
    background-color: transparent;
    border-style: solid;
    border-left-width: 20px;
    border-right-width: 20px;
    border-bottom-width: 30px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colorTokens.surface.inverted900};
    position: absolute;
    top: -25px;
    right: 80px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
`;
