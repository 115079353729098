import { apiGet } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { InvestorQuestionnaireResponse } from './model';

export async function getInvestorQuestionnaireDefinition({
  isLegalEntity,
  suitability,
}: {
  isLegalEntity?: boolean;
  suitability?: boolean;
}): Promise<InvestorQuestionnaireResponse> {
  return apiGet<InvestorQuestionnaireResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.InvestorSuitabilityQuestionnaire,
    true,
    {
      legalEntity: isLegalEntity,
      suitability,
    }
  );
}
