import { QueryClient, setLogger } from 'react-query';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthError, signOut } from '@investown/fe/common-utils';

import { isClientError } from './utils';
import { ApiError } from './ApiError';
import { ApiErrorCode } from './enums';

setLogger({
  // eslint-disable-next-line no-console
  log: console.log,
  warn: console.warn,
  error: (...args: unknown[]) => {
    // Do not log API and auth errors
    if (args[0] instanceof ApiError || args[0] instanceof AuthError) {
      return;
    }
    console.error(...args);
  },
});

export const queryStaleTimes = {
  thirtySeconds: 30000, // 30 seconds
  fiveMinutes: 300000, // 5 minutes
  tenMinutes: 600000, // 10 minutes
  thirtyMinutes: 1800000, // 30 minutes
  sixtyMinutes: 3600000, // 60 minutes
  infinite: Infinity,
};

export const refetchIntervals = {
  fiveSeconds: 5000, // 5 seconds
  tenSeconds: 10000, // 10 seconds
  thirtySeconds: 30000, // 30 seconds
  fiveMinutes: 300000, // 5 minutes
  tenMinutes: 600000, // 10 minutes
  thirtyMinutes: 1800000, // 30 minutes
  sixtyMinutes: 3600000, // 60 minutes
  infinite: Infinity,
};

async function defaultReactQueryOnError(error: unknown): Promise<void> {
  if (error instanceof ApiError && error.code === ApiErrorCode.UserNotFoundError) {
    console.warn('User does not exist anymore. Signing out.');
    queryClient.clear();
    await signOut(true);
  }
  console.error('Query error: ', error);
}

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      staleTime: queryStaleTimes.fiveMinutes,
      retry: (failureCount, error) => !isClientError(error as Error) && failureCount < 3, // Retry only non-client errors and max 3 times
      onError: defaultReactQueryOnError,
    },
    mutations: {
      onError: defaultReactQueryOnError,
    },
  },
});
