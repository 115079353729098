import { Redirect, Route, useLocation } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import { useConfigCatClient, User } from 'configcat-react';

import { BasicUserInfo } from '@investown/fe/common-utils';

import useLogout from '../hooks/useLogout';

import { APP_DEFAULT_PATH, PATH_USER_VERIFICATION } from 'routes/routesPaths';
import { RootState } from 'appRedux/store/rootReducer';
import * as authSelectors from 'appRedux/auth/selectors';
import { withSuspense } from 'util/withSuspense';
import { ContentWrapper } from 'routes/ContentWrapper';
import { RouteConfig } from 'routes/routeInterfaces';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';

interface StateProps {
  isUserSignedIn: boolean;
  cognitoUser: BasicUserInfo | undefined;
}

interface LocationState {
  from?: Location; // user was redirected from path
}

/**
 * A wrapper for <Route> that redirects to the app if user is signed in
 */
const SignedOutRoute: React.FunctionComponent<StateProps & RouteConfig> = ({
  isUserSignedIn,
  cognitoUser,
  ...props
}) => {
  const { component, fullScreen } = props;
  const location = useLocation();
  const { search } = location;
  const redirectUserToVerification = localStorage.getItem(LocalStorageKeys.ShowUserVerificationAfterSignup);
  const logout = useLogout();
  const query = new URLSearchParams(search);
  const afterLogoutPath = query.get('afterLogoutPath');
  const client = useConfigCatClient();

  if (isUserSignedIn) {
    if (redirectUserToVerification) {
      localStorage.removeItem(LocalStorageKeys.ShowUserVerificationAfterSignup);
      return <Redirect to={PATH_USER_VERIFICATION} />;
    }

    if (afterLogoutPath) {
      logout({ afterLogoutPath });
    }

    // Set default user for ConfigCat after successful login for better feature flag targeting
    client.setDefaultUser(new User(cognitoUser?.email ? cognitoUser?.email : 'anonymous', cognitoUser?.email));

    return <Redirect to={(location.state as LocationState)?.from || APP_DEFAULT_PATH} />;
  }
  return (
    <ContentWrapper fullScreen={fullScreen}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Route {...props} component={withSuspense(component as React.ComponentType)} />
    </ContentWrapper>
  );
};

const connector = connect<StateProps, void, RouteConfig, RootState>((state) => ({
  isUserSignedIn: authSelectors.isUserSignedIn(state.auth),
  cognitoUser: authSelectors.getBasicUserInfo(state.auth),
}));

export default connector(SignedOutRoute);
