import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

export interface IconProps extends WithChildren {
  color?: string;
  fillColor?: string;
  hoverColor?: string;
  className?: string;
  width?: string;
  heightInherit?: boolean;
}

const Icon: FC<IconProps> = (props) => {
  const { color, fillColor, hoverColor, className, width, heightInherit, children } = props;
  return (
    <IconHolder
      color={color}
      fillColor={fillColor}
      hoverColor={hoverColor}
      className={className}
      width={width}
      heightInherit={heightInherit}
    >
      {children}
    </IconHolder>
  );
};

const IconHolder = styled.span<{
  color?: string;
  fillColor?: string;
  hoverColor?: string;
  width?: string;
  heightInherit?: boolean;
}>`
  display: flex;

  ${(props) =>
    `
    &:hover svg {
      fill: ${props.fillColor ? props.fillColor : props.theme.colorTokens.icon.brand};
      color: ${props.color ? props.color : props.theme.colorTokens.icon.brand};
      path {
        stroke: ${props.hoverColor ? props.hoverColor : props.theme.colorTokens.icon.brand};
      }
    }
  `}

  svg {
    fill: ${(props) => (props.fillColor ? props.fillColor : 'transparent')};
    color: ${(props) => (props.color ? props.color : props.theme.colorTokens.text.strong)};
    width: ${(props) => (props.width ? props.width : props.theme.icons.size.medium)};

    ${(props) =>
      props.heightInherit
        ? `height: inherit;
    @supports (-webkit-marquee-repetition: infinite) {
      /* Old deprecated callback for safari browsers - fixing 100% height bug/feature on iOS */
      height: intrinsic;
    }`
        : 'intrinsic'};

    path {
      stroke: ${(props) => (props.color ? props.color : props.theme.colorTokens.text.strong)};
    }
  }
`;

export default Icon;
