import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { SignedFrameworkAgreement } from './model';

/**
 * Returns null if user does not have a signed framework agreement.
 */
export async function getLatestSignedFrameworkAgreement(): Promise<SignedFrameworkAgreement | null> {
  return apiGet<SignedFrameworkAgreement | null>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.GetLatestSignedFrameworkAgreement,
    true
  );
}
