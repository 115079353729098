import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Lang from 'util/IntlMessages';
import Spacer from 'components/Spacer';
import DownloadMobileApp from 'assets/images/downloadMobileApp.png';
import Button from 'components/Button';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';

const OverlayForMobileViewport: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const handleClose = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem(LocalStorageKeys.MobileScreenClosedTime, currentTime.toString());
    history.push('/');
    window.location.reload();
  };

  const customStyles = `
    body {
      min-width: 100% !important;
    }
  `;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style type="text/css">{customStyles}</style>
      </Helmet>
      <Container>
        <ContentWrapper>
          <Spacer height="large" />
          <Image src={DownloadMobileApp} alt="Download mobile app" />
          <Spacer height="large" />
          <Heading>
            <Lang id="global.mobileScreen.title" />
          </Heading>
          <Description>
            <Lang id="global.mobileScreen.description" />
          </Description>
          <Spacer height="large" />
          <Buttons>
            <Button data-testid="mobile-screen-overlay-download-button" color="primary" block>
              <a
                data-testid="mobile-screen-overlay-download-link"
                href={intl.formatMessage({ id: 'mobileApp.installLink' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Lang id="global.mobileScreen.button" />
              </a>
            </Button>
            <Spacer height="large" />
            <Button data-testid="mobile-screen-overlay-close-button" color="link" block onClick={handleClose}>
              <Lang id="global.mobileScreen.link" />
            </Button>
          </Buttons>
        </ContentWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0 !important;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 999;
  padding: ${({ theme }) => theme.spacing.extraLarge};
  overflow: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 40%;
`;

const Buttons = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  max-width: 600px;
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colorTokens.text.strong};
  font-size: ${({ theme }) => theme.font.size.massive};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  line-height: 36.25px;
  text-align: center;
`;

const Description = styled.p`
  color: ${(props) => props.theme.colorTokens.text.subtle};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${(props) => props.theme.font.weight.regular};
  line-height: 25.6px;
  text-align: center;
`;

export default OverlayForMobileViewport;
