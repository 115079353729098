import { removeSpaces } from '../string';

export interface AddressObject {
  street: string;
  city: string;
  zip: string;
  country: string;
}

export interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const parseGeocoderAddressComponent = (geocoderAddressComponents: GeocoderAddressComponent[]): AddressObject => {
  let street = '';
  let streetNumber = '';
  let streetPremise = '';
  let postalTown = '';
  let subLocality = '';
  let administrativeArea = '';
  let zip = '';
  let country = '';

  for (const component of geocoderAddressComponents as GeocoderAddressComponent[]) {
    const componentTypes = component.types;

    if (componentTypes.includes('route')) {
      street = component.long_name;
    }

    if (componentTypes.includes('street_number')) {
      streetNumber = component.long_name;
    }

    if (componentTypes.includes('premise')) {
      streetPremise = component.long_name;
    }

    if (componentTypes.includes('postal_code')) {
      zip = component.long_name;
    }

    if (componentTypes.includes('postal_town')) {
      postalTown = component.long_name;
    }

    if (componentTypes.includes('sublocality')) {
      subLocality = component.long_name;
    }

    if (componentTypes.includes('administrative_area_level_2')) {
      administrativeArea = component.long_name;
    }

    if (componentTypes.includes('country')) {
      country = component.short_name;
    }
  }

  return {
    street: `${street} ${
      streetNumber && streetPremise ? `${streetPremise}/${streetNumber}` : streetNumber || streetPremise
    }`,
    city: subLocality || administrativeArea || postalTown,
    zip: removeSpaces(zip),
    country,
  };
};
