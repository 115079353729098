import { clamp } from 'lodash';

/**
 * Converts a hex color code to an RGBA color with specified opacity.
 *
 * @param {string} hex - The hex color code (e.g., "#FF5733" or "#F53").
 * @param {number} opacity - The opacity value between 0 and 1.
 * @returns {string} The color in rgba format.
 */
export function addAlpha(colorInHexFormat: '#{string}', opacity: number): string {
  if (!isValidHexFormat(colorInHexFormat)) {
    return colorInHexFormat;
  }

  let hex = colorInHexFormat.replace('#', '');

  // Handle 3-digit hex codes
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((h) => h + h)
      .join('');
  }

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${clamp(opacity, 0, 1)})`;
}

function isValidHexFormat(hex: string): boolean {
  return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
}
