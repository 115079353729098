import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UserLevelConfigDTO } from './model';

export async function getUserLevels(): Promise<UserLevelConfigDTO> {
  const response = await apiGet<UserLevelConfigDTO>(ApiNames.MonorepoApiEndpoint, ApiPath.UserLevels, true);

  if (response === undefined) {
    throw new Error('Invalid response');
  }

  return response;
}
