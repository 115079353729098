import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig, fetchAndActivate, getString, getNumber, getBoolean, getValue } from 'firebase/remote-config';

import { remoteConfigDefaults, RemoteConfigParameter } from '@investown/fe/common-utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export function initFirebase(): void {
  const app = initializeApp(firebaseConfig);

  // Initialize analytics
  getAnalytics(app);

  // initialize remote config
  getRemoteConfig(app);
}

export async function loadRemoteConfigValues(): Promise<void> {
  const remoteConfig = getRemoteConfig();
  remoteConfig.defaultConfig = remoteConfigDefaults;
  remoteConfig.settings.minimumFetchIntervalMillis = 600000; // cache values for 10 minutes
  remoteConfig.settings.fetchTimeoutMillis = 3000; // do not block

  try {
    await fetchAndActivate(remoteConfig);
  } catch (err) {
    console.error('Remote config fetch failed', err);
    // catch fetch error and use the defaults to make the app usable in case of failure
  }
}

export const getStringValue = (key: RemoteConfigParameter): string => {
  const remoteConfig = getRemoteConfig();
  return getString(remoteConfig, key);
};

export const getNumberValue = (key: RemoteConfigParameter): number => {
  const remoteConfig = getRemoteConfig();
  return getNumber(remoteConfig, key);
};

export function getNumberValueOrUndefined(key: RemoteConfigParameter): number | undefined {
  const remoteConfig = getRemoteConfig();
  const value = getValue(remoteConfig, key);
  if (value.getSource() !== 'remote') {
    return undefined;
  }
  return value.asNumber();
}

export const getBooleanValue = (key: RemoteConfigParameter): boolean => {
  const remoteConfig = getRemoteConfig();
  return getBoolean(remoteConfig, key);
};

export function getRemoteBooleanValueOrFail(key: RemoteConfigParameter): boolean {
  const remoteConfig = getRemoteConfig();
  const value = getValue(remoteConfig, key);
  if (value.getSource() !== 'remote') {
    throw new Error(`Remote config value for ${key} not found`);
  }
  return value.asBoolean();
}

export function getJSONValue<T>(key: RemoteConfigParameter): T {
  const remoteConfig = getRemoteConfig();
  return JSON.parse(getString(remoteConfig, key));
}

export function getJSONValueOrUndefined<T>(key: RemoteConfigParameter): T | undefined {
  const remoteConfig = getRemoteConfig();
  const jsonString = getString(remoteConfig, key);

  if (!jsonString) {
    return undefined;
  }

  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    console.error(`Failed to parse Remote config JSON for key ${key}:`, error);
    return undefined;
  }
}
