import { useState, useCallback, useEffect } from 'react';

export interface PaginationDefaultOptions {
  pageSize: number;
  pageCount?: number;
}

export interface UsePaginationProps {
  page: number;
  pageSize: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  goToPage: (newPage: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (newPageSize: number) => void;
}

function usePagination(defaultOptions?: PaginationDefaultOptions): UsePaginationProps {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(defaultOptions?.pageSize ?? 100);
  const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false);
  const [canNextPage, setCanNextPage] = useState<boolean>(true);

  const pageCount = defaultOptions?.pageCount ?? 1;

  useEffect(() => {
    setCanPreviousPage(page > 1);
    setCanNextPage(page < pageCount);
  }, [page, pageCount]);

  const goToPage = useCallback(
    (newPage: number) => {
      if (newPage > 0 && newPage <= pageCount && newPage !== page) {
        setPage(newPage);
      }
    },
    [pageCount, page]
  );

  const previousPage = useCallback(() => {
    if (canPreviousPage) {
      setPage((oldPage) => oldPage - 1);
    }
  }, [canPreviousPage]);

  const nextPage = useCallback(() => {
    if (canNextPage) {
      setPage((oldPage) => oldPage + 1);
    }
  }, [canNextPage]);

  const setPageSize = useCallback(
    (newPageSize: number) => {
      if (newPageSize !== perPage) {
        setPerPage(newPageSize);
      }
    },
    [perPage]
  );

  return {
    page,
    pageSize: perPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    goToPage,
    previousPage,
    nextPage,
    setPageSize,
  };
}

export default usePagination;
