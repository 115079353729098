import { SagaIterator } from 'redux-saga';
import { all, call, delay, fork, takeEvery } from 'redux-saga/effects';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { investedAction, investmentErrorAction, investmentWithdrawnAction } from './actions';

function* resetCaches(): SagaIterator {
  // Refetch all queries after investment with a delay to make sure all data is updated on BE
  yield delay(4000);

  yield all([
    call([queryClient, queryClient.invalidateQueries], QueryKeys.Wallet),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.Property),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.InvestedProperties),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PropertyInvestments),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.SecondaryMarketItems),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PortfolioStatistics),
  ]);

  yield delay(4000);

  // Setting new user level can take a while after refreshing PortfolioStatistics so delay a bit
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.UserDetails);
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.UserInvestmentStats);

  yield delay(25000);

  // Refetch wallet if invalidateQuery above was too early to get updated balance in 30s after investment at the same time as currentUsersTotalInvestment in project detail
  // 1s delay after investment, 4s delay for UserInvestmentStats, 25s delay for Wallet. currentUsersTotalInvestment
  yield call([queryClient, queryClient.refetchQueries], QueryKeys.Wallet);
}

export function* watchInvestedAction(): SagaIterator {
  yield takeEvery(investedAction, resetCaches);
}

export function* watchInvestmentErrorAction(): SagaIterator {
  yield takeEvery(investmentErrorAction, resetCaches);
}

export function* watchInvestmentWithdrawnAction(): SagaIterator {
  yield takeEvery(investmentWithdrawnAction, resetCaches);
}

export default function* root(): SagaIterator {
  yield all([fork(watchInvestedAction), fork(watchInvestmentErrorAction), fork(watchInvestmentWithdrawnAction)]);
}
