import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCallback } from '@aws-amplify/core';
import { Store } from 'redux';

import { signInSuccessAction, signOutSuccessAction } from 'appRedux/auth/actions';
import * as intercom from 'util/intercom';
import * as sentry from 'util/sentry';

export function onSignIn({ user, store }: { user: CognitoUser; store: Store }): void {
  store.dispatch(signInSuccessAction(user));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercom.onSignIn((user as any).attributes.email);
  sentry.setCurrentUser();
}

export function onSignOut(store: Store): void {
  store.dispatch(signOutSuccessAction());
  intercom.onSignOut();
  sentry.clearCurrentUser();
}

/**
 * Connects auth events from amplify auth to redux actions
 * @see https://aws-amplify.github.io/docs/js/hub#authentication-events
 */
const listener =
  (store: Store): HubCallback =>
  (data) => {
    switch (data.payload.event) {
      case 'signIn':
        if (!data.payload.data.attributes) {
          // If attributes are missing we have to fetch them (attributes are missing after MFA sign in)
          Auth.currentAuthenticatedUser().then((user) => onSignIn({ user, store }));
        } else {
          onSignIn({ user: data.payload.data, store });
        }
        break;
      case 'signUp':
        break;
      case 'signOut':
        onSignOut(store);
        break;
      case 'signIn_failure':
        break;
      case 'configured':
        break;
      default:
    }
  };

export function listen(store: Store): void {
  Hub.listen('auth', listener(store));
}
