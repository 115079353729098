import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { getLevelFromConfig, getUserLevelTranslations } from '@investown/fe/common-utils';
import { UserLevelConfigDTO } from '@investown/fe/api-sdk';

import { textColor } from '../Topbar/utils';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import ProgressBarCircle from 'components/V2/ProgressBarCircle/ProgressBarCircle';
import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import { removePxSuffix } from 'util/helpers';
import { getAppLanguageLocalVariable } from 'lngProvider';
import ProfileIcon from 'components/V2/Icons/Profile';

interface Props {
  levels: UserLevelConfigDTO[]; // data from hook useUserLevels()
  userLevel: string;
  userLevelProgress?: number;
  withUserIcon?: boolean;
  onPress?: () => void;
  size: 'small' | 'large';
  active?: boolean;
}

const PremiumPill: FC<Props> = ({
  levels,
  userLevel,
  userLevelProgress,
  size,
  onPress,
  active = false,
  withUserIcon,
}) => {
  const userLocale = getAppLanguageLocalVariable();
  const mapUserLevel = getLevelFromConfig({ levelId: userLevel, levels });
  const theme = useContext(ThemeContext);
  if (mapUserLevel === undefined) return null;
  const { isPremium } = mapUserLevel;

  const progressBarColor = isPremium
    ? {
        progressColor: theme.colorTokens.surface.inverted900,
        backgroundColor: theme.colorTokens.surface.overlayBlack,
      }
    : {
        progressColor: theme.colorTokens.icon.brand,
        backgroundColor: theme.colorTokens.surface.brandFaded200,
      };

  return (
    <PremiumLabelWrapper
      isPremium={isPremium}
      hasProgressBar={userLevelProgress !== undefined}
      size={size}
      onClick={onPress}
      data-testid="premium-pill"
    >
      {userLevelProgress !== undefined && (
        <>
          <ProgressBarWithIcon>
            <ProgressBarCircle
              size={30}
              progress={userLevelProgress}
              progressColor={progressBarColor.progressColor}
              backgroundColor={progressBarColor.backgroundColor}
              strokeWidth={3}
            />
            {withUserIcon ? (
              <StyledProfileIcon
                width="22px"
                fillColor="transparent"
                color={textColor(active && !isPremium, theme)}
                hoverColor={isPremium ? theme.colorTokens.text.strong : theme.colorTokens.text.brand}
              />
            ) : null}
          </ProgressBarWithIcon>
          <Spacer width="medium" />
        </>
      )}
      {userLevelProgress === undefined && withUserIcon && (
        <UserIconContainer>
          <StyledProfileIcon
            width="22px"
            fillColor="transparent"
            color={textColor(active && !isPremium, theme)}
            hoverColor={isPremium ? theme.colorTokens.text.strong : theme.colorTokens.text.brand}
          />
        </UserIconContainer>
      )}
      <Typography
        color={active && !isPremium ? 'brand' : 'strong'}
        variant={size === 'small' ? 'labelXSMedium' : 'labelSMMedium'}
      >
        {getUserLevelTranslations({ locale: userLocale, toTranslate: mapUserLevel })}
      </Typography>
    </PremiumLabelWrapper>
  );
};

export const PremiumPillLoadingSkeleton = () => {
  const theme = useContext(ThemeContext);
  return (
    <LoadingSkeleton
      width={72 + removePxSuffix(theme.spacing.regular)}
      height={42}
      borderRadius={theme.borderRadius.extraLarge}
    />
  );
};

const PremiumLabelWrapper = styled.div<{ isPremium: boolean; hasProgressBar: boolean; size: 'small' | 'large' }>`
  height: ${({ size }) => (size === 'large' ? '42px' : '32px')};
  background: ${({ hasProgressBar, isPremium, theme }) => {
    if (isPremium) {
      return `linear-gradient(266deg, ${theme.colorTokens.gradient.gold.start} 1.64%, ${theme.colorTokens.gradient.gold.middle} 51.9%, ${theme.colorTokens.gradient.gold.end} 99.12%)`;
    }
    if (hasProgressBar) {
      return 'transparent';
    }
    return theme.colorTokens.surface.brandFaded100;
  }};
  padding: ${({ hasProgressBar, isPremium, size, theme }) => {
    if (!isPremium && hasProgressBar) {
      return `0 ${theme.spacing.regular} 0 ${theme.spacing.medium};`;
    }
    if (isPremium && hasProgressBar) {
      return `${theme.spacing.medium} ${theme.spacing.large} ${theme.spacing.medium} ${theme.spacing.regular};`;
    }
    if (size === 'large') {
      return `${theme.spacing.regular} ${theme.spacing.large};`;
    }
    return `${theme.spacing.medium} ${theme.spacing.regular};`;
  }}
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
`;

const ProgressBarWithIcon = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
`;

const UserIconContainer = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing.medium};
  /* fix position to better visual fit of icon */
  margin-left: ${({ theme }) => `-3px`};
`;

const StyledProfileIcon = styled(ProfileIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export default PremiumPill;
