/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */

import { AnyObject, Maybe } from 'yup/lib/types';
import * as yup from 'yup';

export function maxDecimalDigits(this: yup.NumberSchema, maxDigits: number, errorMessage: string) {
  return this.test(
    `max-decimal-digits`,
    errorMessage,
    function (this: yup.TestContext<AnyObject>, value: number | undefined) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      const rounded = Math.round(value * Math.pow(10, maxDigits)) / Math.pow(10, maxDigits);
      return rounded === value || createError({ path, message: errorMessage });
    }
  );
}

declare module 'yup' {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    maxDecimalDigits(maxDigits: number, errorMessage: string): NumberSchema<TType, TContext>;
  }
}
