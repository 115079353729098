import { Redirect, Route, useLocation } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import { PATH_SIGN_IN } from 'routes/routesPaths';
import { RootState } from 'appRedux/store/rootReducer';
import * as authSelectors from 'appRedux/auth/selectors';
import { LocationState } from 'routes/SignIn';
import { withSuspense } from 'util/withSuspense';
import { ContentWrapper } from 'routes/ContentWrapper';
import { RouteConfig } from 'routes/routeInterfaces';

interface StateProps {
  isUserSignedIn: boolean;
}

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 */
const StyledPrivateRoute: React.FunctionComponent<StateProps & RouteConfig> = ({ isUserSignedIn, ...props }) => {
  const location = useLocation();
  const { component, fullScreen } = props;

  if (!isUserSignedIn) {
    const state: LocationState = { from: location };
    return (
      <Redirect
        to={{
          pathname: PATH_SIGN_IN,
          state,
        }}
      />
    );
  }

  return (
    <ContentWrapper fullScreen={fullScreen}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Route {...props} component={withSuspense(component as React.ComponentType)} />
    </ContentWrapper>
  );
};

const connector = connect<StateProps, void, RouteConfig, RootState>((state) => ({
  isUserSignedIn: authSelectors.isUserSignedIn(state.auth),
}));

export default connector(StyledPrivateRoute);
