export enum AuthErrorCode {
  // The error happens if the user didn't finish the confirmation step when signing up
  // In this case you need to resend the code and confirm the user
  // About how to resend the code and confirm the user, please check the signUp part
  UserNotConfirmedException = 'UserNotConfirmedException',

  // The error happens when the password is reset in the Cognito console
  // In this case you need to call forgotPassword to reset the password
  // Please check the Forgot Password part.
  PasswordResetRequiredException = 'PasswordResetRequiredException',

  // The error happens when the incorrect password is provided
  NotAuthorizedException = 'NotAuthorizedException',

  // The error happens when the supplied username/email does not exist in the Cognito user pool
  UserNotFoundException = 'UserNotFoundException',

  // The error happens when the PostAuthentication trigger fails (see backend code)
  UserLambdaValidationException = 'UserLambdaValidationException',

  LimitExceededException = 'LimitExceededException',

  InvalidParameterException = 'InvalidParameterException',
}
