import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { GetUserReferralCodeConnectionResponse } from './model';

export async function getUserReferralCodeConnection(
  email: string | null
): Promise<GetUserReferralCodeConnectionResponse> {
  return apiGet<GetUserReferralCodeConnectionResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.GetReferralCodeConnection,
    false,
    { email }
  );
}
