import React, { FC } from 'react';
import styled from 'styled-components';

import { SpacingSizeName } from 'styles/theme';

interface SpacerProps {
  height?: string | SpacingSizeName;
  width?: string | SpacingSizeName;
  id?: string;
}

const Spacer: FC<SpacerProps> = ({ height, width, id }) => <StyledSpacer height={height} width={width} id={id} />;

const StyledSpacer = styled.div<SpacerProps>`
  display: block;
  height: ${({ theme, height }) => theme.spacing[height as SpacingSizeName] || height};
  min-height: ${({ theme, height }) => theme.spacing[height as SpacingSizeName] || height};
  width: ${({ theme, width }) => theme.spacing[width as SpacingSizeName] || width};
  min-width: ${({ theme, width }) => theme.spacing[width as SpacingSizeName] || width};
`;

export default Spacer;
