import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { CalculateNetWorthRequest, CalculateNetWorthResponse } from './model';

export async function calculateNetWorth({
  totalIncome,
  liquidAssets,
  regularAnnualExpenditure,
}: CalculateNetWorthRequest): Promise<CalculateNetWorthResponse> {
  return apiPost<CalculateNetWorthResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.CalculateNetWorth,
    { totalIncome, liquidAssets, regularAnnualExpenditure },
    true
  );
}
