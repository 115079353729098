import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Undraw from 'components/Undraw';
import { UndrawImages } from 'constants/UndrawImages';

interface Props {
  heading: string;
  subheading?: string;
  buttonText: string;
  buttonLink?: string;
  onClick?: () => void;
  undrawImage: UndrawImages;
}
/* This layout is not connected to our providers so we cannot use theme, intl, etc. All components are styled separately and are not responding to app styling changes. */
const StatusPage: React.FC<Props> = ({ heading, subheading, buttonText, buttonLink, undrawImage, onClick }) => (
  <Wrapper>
    <Texts>
      <h1>
        {heading}
        <br />
        {subheading && subheading}
      </h1>
      <Button data-testid="status-page-button" onClick={onClick}>
        {buttonLink ? <Link to={buttonLink}>{buttonText}</Link> : buttonText}
      </Button>
    </Texts>
    <Undraw name={undrawImage} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  justify-content: space-around;
  align-items: center;
  height: 100vh;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }

  svg {
    padding: 0 20px;
  }

  @media screen and (min-width: 992px) {
    svg {
      width: 25% !important;
    }
  }
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media screen and (min-width: 992px) {
    min-width: 240px;
  }

  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

const Button = styled.button`
  white-space: pre-wrap;
  vertical-align: middle;
  padding: 5px 15px;
  min-height: 46px;
  height: auto;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;
  margin-top: 15px;

  &,
  & > a {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  border-radius: 5px;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  color: #ffffff;
  background-color: #1e2022;
  border: none;

  &:hover,
  &:focus {
    background-color: #141618;
  }
`;

export default StatusPage;
