/* eslint-disable @nx/enforce-module-boundaries */
import { UserTimestampsResponse } from '../../../../api-sdk/src/lib/users/model';
import { UserVerificationDataDTO } from '../../../../api-sdk/src/lib/userVerification/model';

export function isSuitabilityQuestionnaireExpired({
  userVerificationData,
  userTimestamps,
}: {
  userVerificationData: UserVerificationDataDTO | undefined;
  userTimestamps: UserTimestampsResponse | undefined;
}): boolean {
  if (
    !userVerificationData ||
    userVerificationData.suitability === null ||
    !userTimestamps ||
    (!userTimestamps.investorQuestionnairePartOne.endedAt && !userTimestamps.investorQuestionnairePartTwo.endedAt)
  ) {
    return true;
  }
  return false;
}
