import { createAction } from 'typesafe-actions';
import { CognitoUser } from '@aws-amplify/auth';

import {
  AUTH_MFA_DISABLED_SUCCESS,
  AUTH_MFA_ENABLED_SUCCESS,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT_SUCCESS,
} from 'appRedux/auth/constants';

export const signInSuccessAction = createAction(AUTH_SIGN_IN_SUCCESS)<CognitoUser>();
export const signOutSuccessAction = createAction(AUTH_SIGN_OUT_SUCCESS)();
export const mfaEnabledSuccessAction = createAction(AUTH_MFA_ENABLED_SUCCESS)();
export const mfaDisabledSuccessAction = createAction(AUTH_MFA_DISABLED_SUCCESS)();
