/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Fix 0 width case -> https://www.npmjs.com/package/react-loading-skeleton#troubleshooting
interface Props extends SkeletonProps {
  parentFlex?: boolean;
  fullWidth?: boolean;
}

const LoadingSkeleton: FC<Props> = ({ parentFlex, fullWidth, ...props }) => {
  let customClass;

  if (parentFlex) {
    customClass = 'flex-1';
  } else if (fullWidth) {
    customClass = 'skeleton-full-width';
  }
  return <Skeleton containerClassName={customClass} {...props} />;
};
export default LoadingSkeleton;
