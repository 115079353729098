import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';

import { QueryKeys } from '../enums';
import { queryStaleTimes } from '../reactQueryConfig';

import { getInvestorQuestionnaireForLang } from './getInvestorQuestionnaireForLang';
import {
  InvestorQuestionnaireAnswer,
  InvestorQuestionnaireQuestion,
  InvestorQuestionnaireSubmitQuestionAnswer,
  UseInvestorQuestionnaire,
  UseInvestorQuestionnaireProps,
  InvestorQuestionnaireQuestionType,
  SubmitAnswerPayload,
  TextAnswer,
} from './model';
import { getInvestorQuestionnairePartTwoDefinition } from './getInvestorQuestionnairePartTwoDefinition';
import { submitInvestorQuestionnairePartTwo } from './submitInvestorQuestionnairePartTwo';

export function useInvestorQuestionnairePartTwo({
  language,
  submitGaEvent,
}: UseInvestorQuestionnaireProps): UseInvestorQuestionnaire {
  const [progress, setProgress] = useState<number>(1);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const [questionnaire, setQuestionnaire] = useState<InvestorQuestionnaireQuestion[]>([]);
  const [actualQuestion, setActualQuestion] = useState<InvestorQuestionnaireQuestion | undefined>(undefined);
  const [questionnaireVersion, setQuestionnaireVersion] = useState<number>(0);
  const [answers, setAnswers] = useState<InvestorQuestionnaireSubmitQuestionAnswer[]>([]);
  const [lastAnswer, setLastAnswer] = useState<{
    questionId: string;
    type: InvestorQuestionnaireQuestionType;
    answer: InvestorQuestionnaireAnswer;
  } | null>(null);

  const { data: definition, isLoading: isDefinitionLoading } = useQuery(
    [QueryKeys.InvestorQuestionnairePartTwo, language],
    () => getInvestorQuestionnairePartTwoDefinition(),
    {
      staleTime: queryStaleTimes.infinite,
    }
  );

  const {
    data: submitResponseData,
    mutate: submit,
    isLoading: isSubmitLoading,
  } = useMutation(QueryKeys.InvestorQuestionnairePartTwoSubmit, submitInvestorQuestionnairePartTwo);

  /** parse response and handle only requested language */
  useEffect(() => {
    if (definition && questionnaireVersion !== definition.version) {
      const { version, questions } = getInvestorQuestionnaireForLang(definition, language);
      setQuestionnaire(questions);
      setQuestionnaireVersion(version);
      setProgress(1);
      setNumberOfQuestions(questions.length);
    }
  }, [
    definition,
    questionnaireVersion,
    setQuestionnaire,
    language,
    setQuestionnaireVersion,
    setProgress,
    setNumberOfQuestions,
  ]);

  /** set actual question */
  useEffect(() => {
    setActualQuestion(questionnaire[progress - 1]);
  }, [progress, setActualQuestion, questionnaire]);

  const goBack = useCallback(() => {
    if (progress > 1) {
      setProgress((oldProgress) => oldProgress - 1);
      setLastAnswer(null);
      lastAnswer &&
        submitGaEvent &&
        submitGaEvent({
          questionId: lastAnswer?.questionId,
          answer: '',
          isGoingBack: true,
        });
    }
  }, [progress, setProgress, setLastAnswer, lastAnswer, submitGaEvent]);

  const getAnswerScore = useCallback(
    (payload: SubmitAnswerPayload) => {
      if (payload.type === 'SINGLE_CHOICE' && 'answerId' in payload.answer) {
        const { answer } = payload;
        if (actualQuestion?.questionConfig.type === 'SINGLE_CHOICE') {
          const score = actualQuestion?.questionConfig.choices.find((c) => c.id === answer.answerId)?.score;
          return score!; // Using the non-null assertion operator (!) to guarantee a number is returned.
        }
      }
      return -999;
    },
    [actualQuestion]
  );

  const submitAnswer = useCallback(
    (payload: SubmitAnswerPayload) => {
      // isEqual condition prevents duplicate (double-click) answers to the same question
      const answerScore = getAnswerScore(payload);

      if (!isEqual(payload, lastAnswer)) {
        setLastAnswer(payload);
        const newAnswers = [
          ...answers.filter((oldAnswer) => oldAnswer.questionId !== payload.questionId), // answer must be replaced if user moved back and fills it in again
          payload,
        ];
        setAnswers(newAnswers);

        // Get questionID and answerID to pass to GA
        const answerValue = (payload?.answer as TextAnswer)?.answerId;
        submitGaEvent &&
          submitGaEvent({
            questionId: payload.questionId,
            answer: answerValue,
          });

        if (progress === numberOfQuestions || answerScore < 0) {
          submit({ version: questionnaireVersion, answers: newAnswers });
        } else {
          setProgress((oldProgress) => oldProgress + 1);
        }
      }
    },
    [getAnswerScore, lastAnswer, answers, progress, numberOfQuestions, submit, submitGaEvent, questionnaireVersion]
  );

  if (!isDefinitionLoading && !isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: false,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData,
    };
  }

  if (!isDefinitionLoading && isSubmitLoading && actualQuestion) {
    return {
      progress,
      isDefinitionLoading: false,
      isSubmitLoading: true,
      questionnaire,
      actualQuestion,
      numberOfQuestions,
      submitAnswer,
      goBack,
      submitResponseData: undefined,
    };
  }

  return { isDefinitionLoading: true, isSubmitLoading: false, submitResponseData: undefined };
}
