import { CognitoUser } from '@aws-amplify/auth';

export interface BasicUserInfo {
  givenName: string;
  familyName: string;
  email: string;
}

/**
 * Workaround for invalid types in amplify/auth library
 * @see https://github.com/aws-amplify/amplify-js/issues/3733
 */
export type UserChallenge =
  | 'CUSTOM_CHALLENGE'
  | 'NEW_PASSWORD_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP'
  | undefined;

export interface SignInResult extends CognitoUser {
  challengeName: UserChallenge;
  challengeParam: {
    CODE_DELIVERY_DELIVERY_MEDIUM: 'SMS';
    CODE_DELIVERY_DESTINATION: string; // e.g. sanitized phone number
  };
}

export enum UserAttributeName {
  Email = 'email',
  GivenName = 'given_name',
  FamilyName = 'family_name',
  PhoneNumber = 'phone_number',
}

export interface CognitoUserAttributes {
  [UserAttributeName.Email]: string;
  [UserAttributeName.GivenName]: string;
  [UserAttributeName.FamilyName]: string;
  [UserAttributeName.PhoneNumber]?: string;
}
