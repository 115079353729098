import { createGraphQLClient } from '../api';
import { sdkFunctionWrapper } from '../utils';

import { getSdk } from './__generated__/sdk';

let notificationsGQLEndpoint: string;

export function initNotificationsClient(endpoint: string): void {
  notificationsGQLEndpoint = endpoint;
}

/**
 * Returns generated SDK for notifications microservice.
 * For instructions how to regenerate the SDK see README in the project root.
 * More info: https://graphql-code-generator.com/docs/plugins/typescript-graphql-request
 */
export async function getNotificationsClient(): Promise<ReturnType<typeof getSdk>> {
  if (!notificationsGQLEndpoint) {
    throw new Error('Missing notificationsGQLEndpoint variable');
  }

  return getSdk(
    await createGraphQLClient({ endpoint: notificationsGQLEndpoint!, authenticated: true }),
    sdkFunctionWrapper
  );
}
