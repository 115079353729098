import { apiGet } from '../api';
import { ApiPath, ApiNames } from '../enums';

import { TaxReports } from './model';

export async function getUserTaxReports(): Promise<TaxReports> {
  const response = await apiGet<TaxReports>(ApiNames.MonorepoApiEndpoint, ApiPath.TaxReports, true);

  if (response === undefined) {
    throw new Error('Invalid response');
  }

  return response;
}
