export class SentryLogging {
  static sentry: {
    addBreadcrumb: (breadcrumb: object) => void;
    captureException: (error: Error) => void;
  };

  static setSentry(sentryImplementation: {
    addBreadcrumb: (breadcrumb: object) => void;
    captureException: (error: Error) => void;
  }): void {
    SentryLogging.sentry = sentryImplementation;
  }

  static logError(message: string, error: Error): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!SentryLogging.sentry || __DEV__) {
      return;
    }

    SentryLogging.sentry.addBreadcrumb({
      message,
      level: 'error',
    });
    SentryLogging.sentry.captureException(error);
  }
}
