import { useState, useCallback } from 'react';

export enum FilterOperator {
  Equal = 'equal',
  NotEqual = 'notEqual',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThanOrEqual = 'lessThanOrEqual',
}

export interface ManagedDataFilterItem<TFilterType> {
  key: string;
  value: TFilterType;
  operator?: FilterOperator;
}

export interface FilterDefaultOptions<TFilterType> {
  defaultFilter?: ManagedDataFilterItem<TFilterType>[];
  filterableColumns?: string[];
}

export interface UseFilterProps<TFilterType> {
  filter: ManagedDataFilterItem<TFilterType>[];
  changeFilter: (key: string, value: TFilterType, operator?: FilterOperator) => void;
}

function useFilter<TFilterType>(defaultOptions?: FilterDefaultOptions<TFilterType>): UseFilterProps<TFilterType> {
  const [filter, setFilter] = useState<ManagedDataFilterItem<TFilterType>[]>(defaultOptions?.defaultFilter ?? []);

  const filterableColumns = defaultOptions?.filterableColumns ?? [];

  const changeFilter = useCallback(
    (key: string, value?: any, operator?: FilterOperator) => {
      if (filterableColumns.includes(key)) {
        if (!value) {
          setFilter((oldFilter) => oldFilter.filter((item) => item.key !== key));
        }

        setFilter((oldFilter) => [...oldFilter.filter((item) => item.key !== key), { key, value, operator }]);
      }
    },
    [filterableColumns]
  );

  return { filter, changeFilter };
}

export default useFilter;
