export function aboutMarketplaceUrl(userLocale: string): string {
  return userLocale === 'cs' ? `https://investown.cz/trziste` : `https://investown.cz/${userLocale}/marketplace`;
}

export function feedbackFormUrl(userLocale: string): string {
  switch (userLocale) {
    case 'en':
      return 'https://form.typeform.com/to/hi0yaGow';
    default:
      return 'https://form.typeform.com/to/zH3q00lU';
  }
}

interface ReferralUrlParams {
  code: string;
  bonus: number;
  expiration: string;
  inviterName: string;
  minimalInvestment: number;
  envUrl: string;
}
export function generateReferralUrl({
  code,
  bonus,
  expiration,
  inviterName,
  minimalInvestment,
  envUrl,
}: ReferralUrlParams): string {
  return `${envUrl}/referral/${code}?amount=${bonus}&expiration=${expiration}&inviterName=${encodeURI(
    inviterName
  )}&minimalInvestment=${minimalInvestment}`;
}
