import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { calculateUiLevelProgress, getLevelFromConfig } from '@investown/fe/common-utils';
import { refetchIntervals, useUserDetails, useUserLevels } from '@investown/fe/api-sdk';
import { TestIds } from '@investown/fe/test-utils/testIds';

import PremiumPill, { PremiumPillLoadingSkeleton } from '../PremiumPill/PremiumPill';

import { usePortfolioStatistics } from 'hooks/usePortfolioStatistics';
import { PATH_USER } from 'routes/routesPaths';

interface PremiumPillWrapperProps {
  active: boolean;
}

function PremiumPillWrapper({ active = false }: PremiumPillWrapperProps) {
  const {
    data: userDetailsData,
    isLoading: userDetailsIsLoading,
    isError: userDetailsIsError,
    isIdle: userDetailsIsIdle,
  } = useUserDetails({ refetchInterval: refetchIntervals.thirtySeconds });
  const {
    data: portfolioStatisticsData,
    isLoading: portfolioStatisticsIsLoading,
    isError: portfolioStatisticsIsError,
    isIdle: portfolioStatisticsIsIdle,
  } = usePortfolioStatistics({
    refetchInterval: refetchIntervals.thirtySeconds,
    refetchOnMount: 'always',
  });
  const {
    data: userLevelsData,
    isLoading: userLevelsIsLoading,
    isError: userLevelsIsError,
    isIdle: userLevelsIsIdle,
  } = useUserLevels({});

  if (userLevelsIsError || userDetailsIsError || portfolioStatisticsIsError) {
    return null;
  }

  if (
    portfolioStatisticsIsLoading ||
    userLevelsIsLoading ||
    userDetailsIsLoading ||
    portfolioStatisticsIsLoading ||
    userDetailsIsIdle ||
    userLevelsIsIdle ||
    portfolioStatisticsIsIdle
  ) {
    return <PremiumPillLoadingSkeleton />;
  }

  const levelProgress = calculateUiLevelProgress({
    currentLevel: userDetailsData.level.currentLevel,
    levels: userLevelsData.levels,
    portfolioSize: portfolioStatisticsData.portfolioStatistics.portfolioSize,
  });

  if (!levelProgress) {
    return null;
  }

  const mapUserLevel = getLevelFromConfig({
    levelId: userDetailsData.level.currentLevel,
    levels: userLevelsData.levels,
  });
  if (mapUserLevel === undefined) return null;
  const { isPremium } = mapUserLevel;

  if (isPremium) {
    return (
      <Link to={PATH_USER} data-testid={TestIds.Profile}>
        <StyledPremiumPill
          size="large"
          levels={userLevelsData.levels}
          userLevel={userDetailsData.level.currentLevel}
          userLevelProgress={levelProgress.isLastLevel ? undefined : levelProgress.percentDone}
          active={active}
          withUserIcon
        />
      </Link>
    );
  }

  return (
    <MenuButton to={PATH_USER} $active={active} $isPremium={isPremium} data-testid={TestIds.Profile}>
      <PremiumPill
        size="large"
        levels={userLevelsData.levels}
        userLevel={userDetailsData.level.currentLevel}
        userLevelProgress={levelProgress.isLastLevel ? undefined : levelProgress.percentDone}
        active={active}
        withUserIcon
      />
    </MenuButton>
  );
}

const MenuButton = styled(Link)<{ $active: boolean; $isPremium: boolean }>`
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  background: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.surface.background};
  border-color: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.stroke.medium};

  &:hover * {
    color: ${({ theme }) => theme.colorTokens.text.brand};

    path {
      stroke: ${({ theme }) => theme.colorTokens.text.brand};
    }
  }
`;

const StyledPremiumPill = styled(PremiumPill)`
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export default PremiumPillWrapper;
