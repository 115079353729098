import { PropertyFinancialAnalysisQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getPropertyFinancialAnalysis(
  slug: string,
  borrowerId?: string
): Promise<PropertyFinancialAnalysisQuery> {
  const coreClient = await getCoreClient();
  return coreClient.PropertyFinancialAnalysis({ slug, borrowerId });
}
